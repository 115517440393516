import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['all', 'checkbox']

  toggleAllCheckboxes() {
    this.checkboxTargets.forEach(checkbox => {
      if (!checkbox.disabled) {
        checkbox.checked = this.allTarget.checked
      }
    })
  }

  toggleCheckbox() {
    const allChecked = this.checkboxTargets.every(checkbox => checkbox.checked || checkbox.disabled)

    this.allTarget.checked = allChecked
  }
}
