import { Controller } from '@hotwired/stimulus'
import { put } from '@rails/request.js'
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ['citySelect']

  static values = {
    url: String,
    resetUrl: String,
    ownerId: String,
    orderIndex: String,
    requestValue: { type: Boolean, default: false }
  }
  connect() {
    // new TomSelect(this.citySelectTarget, {})
  }
  
  citySelectTargetConnected(element) {
    const resetReady = this.hasCitySelectTarget && this.citySelectTarget.dataset.resetReady
    if (resetReady === 'false') {
      new TomSelect(this.citySelectTarget, {})
    }
  }

  citySelectDisconnected(element) {
  }

  async getDepartments(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.requestValue = true
    const url = new URL(this.urlValue) // `/${currentLocale()}/orders/departaments` `createUrlWithlocale()/departaments`
    const searchParams = url.searchParams
    searchParams.append('departament', this.citySelectTarget.value)
    searchParams.append('owner_id', this.ownerIdValue)
    searchParams.append('order_index', this.orderIndexValue)
    const response = await put(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.requestValue = false
      // history.replaceState(history.state, "", url.href)
    }
  }

  async resetSelectedCity(event) {
    const resetReady = this.hasCitySelectTarget && this.citySelectTarget.dataset.resetReady
    if (resetReady === 'false') return
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return
    
    this.requestValue = true
    const url = new URL(this.resetUrlValue)
    const searchParams = url.searchParams
    searchParams.append('reset_source', true)
    searchParams.append('owner_id', this.ownerIdValue)
    searchParams.append('order_index', this.orderIndexValue)
    const response = await put(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.requestValue = false
      // history.replaceState(history.state, "", url.href)
    }
  }
}
