import { Controller } from '@hotwired/stimulus'
import { FILTER } from './_lib/constants.js'
import { createValidPath, set_full_path, hrefRafctoring, paramsMakerIdsAppend, paramsMakerSlugsAppend } from './_lib/parseUrl.js'

export default class extends Controller {
  static targets = ['makerLink', 'stateLink', 'genderLink']
  static values = {
    main: Object,
    filters: Object
  }
  
  controllerName = document.querySelector('body').dataset.currentController

  initialize() {
    // при першому завантаженні немає ініціалізації Stimulus targets, тому примусово їх ініціалізуємо!
    if (this.hasMakerLinkTargets) {
      this.makerLinkTargets.forEach(element => this.createMakerLink(element))
    }
    if (this.hasStateLinkTargets) {
      this.stateLinkTargets.forEach(element => this.createStateLink(element))
    }
    if (this.hasGenderLinkTargets) {
      this.genderLinkTargets.forEach(element => this.createGenderLink(element))
    }
  }
  // start render makerLinkTarget
  makerLinkTargetConnected(element) {
    this.createMakerLink(element)
  }
  // start render stateLinkTarget
  stateLinkTargetConnected(element) {
    this.createStateLink(element)
  }
  // start render genderLinkTarget
  genderLinkTargetConnected(element) {
    this.createGenderLink(element)
  }
  // TODO: фактично дубль paramsMakerAppend
  paramsStateAppend(stateChecked, seoParams) { 
    if (stateChecked.length == 1) seoParams.push(FILTER.state + stateChecked[0])
  }

  paramsGenderAppend(genderChecked, seoParams) { 
    if (genderChecked.length == 1) seoParams.push(FILTER.gender + genderChecked[0])
  }
  parseMainParams() {
    return this.mainValue
  }

  parseFiltersParams() {
    return this.filtersValue
  }

  addSearchParams(mainFilters, nestedFilters) {
    let seoParams = []
    let linkUrl = createValidPath()
    const searchQuery = mainFilters.search || document.querySelector('#search').value.trim()
    const hasMakersIds = mainFilters.makers_ids && mainFilters.makers_ids.length !== 0
    const hasMakersSlugs = mainFilters.makers_slugs && mainFilters.makers_slugs.length !== 0
    const hasStates = nestedFilters.state && nestedFilters.state.length !== 0
    const hasGenders = nestedFilters.gender && nestedFilters.gender.length !== 0
    if (mainFilters.type) seoParams.push(FILTER.type + mainFilters.type)
    if (searchQuery) seoParams.push(FILTER.search + searchQuery)
    if (mainFilters.category_id && this.controllerName != 'categories') seoParams.push(FILTER.category + mainFilters.category_id)
    if (mainFilters.category_slug && this.controllerName != 'categories') seoParams.push(FILTER.categorySlug + mainFilters.category_slug)
    if (hasMakersIds && this.controllerName != 'makers') paramsMakerIdsAppend(mainFilters.makers_ids, seoParams)
    if (hasMakersSlugs && this.controllerName != 'makers') paramsMakerSlugsAppend(mainFilters.makers_slugs, seoParams)
    if (hasStates) this.paramsStateAppend(nestedFilters.state, seoParams)
    if (hasGenders) this.paramsGenderAppend(nestedFilters.gender, seoParams)
    if (nestedFilters.minimum_price) seoParams.push(FILTER.minPrice + nestedFilters.minimum_price)
    if (nestedFilters.maximum_price) seoParams.push(FILTER.maxPrice + nestedFilters.maximum_price)
    if (mainFilters.sort_name) seoParams.push(FILTER.sort + mainFilters.sort_name)
    linkUrl = set_full_path(linkUrl, seoParams)
    return linkUrl
  }

  createMakerLink(element) {
    const makerId = element.dataset.makerId
    const makerSlug = element.dataset.makerSlug
    const mainFilters = this.parseMainParams()
    const nestedFilters = this.parseFiltersParams()
    let makersIds = mainFilters.makers_ids || {}
    let makersSlugs = mainFilters.makers_slugs || {}
    if (Object.keys(makersIds).length === 0) mainFilters['makers_ids'] = new Array (makerId)
    if (Object.keys(makersSlugs).length === 0) mainFilters['makers_slugs'] = new Array(makerSlug)
    if (mainFilters.makers_ids && !mainFilters.makers_ids.includes(makerId)) mainFilters.makers_ids.push(makerId)
    if (mainFilters.makers_slugs && !mainFilters.makers_slugs.includes(makerSlug)) mainFilters.makers_slugs.push(makerSlug)
    let linkUrl = this.addSearchParams(mainFilters, nestedFilters)
    linkUrl = hrefRafctoring(linkUrl)
    element.setAttribute('href', linkUrl)
  }

  createStateLink(element) {
    const stateValue = element.dataset.stateValue
    const mainFilters = this.parseMainParams()
    const nestedFilters = this.parseFiltersParams()
    let stateValues = nestedFilters.state || {}
    if (Object.keys(stateValues).length === 0) nestedFilters['state'] = new Array (stateValue)
    if (nestedFilters.state && !nestedFilters.state.includes(stateValue)) nestedFilters.state.push(stateValue)
    let linkUrl = this.addSearchParams(mainFilters, nestedFilters)
    linkUrl = hrefRafctoring(linkUrl)
    element.setAttribute('href', linkUrl)
  }

  createGenderLink(element) {
    const genderValue = element.dataset.genderValue
    const mainFilters = this.parseMainParams()
    const nestedFilters = this.parseFiltersParams()
    let genderValues = nestedFilters.gender || {}
    if (Object.keys(genderValues).length === 0) nestedFilters['gender'] = new Array (genderValue)
    if (nestedFilters.gender && !nestedFilters.gender.includes(genderValue)) nestedFilters.gender.push(genderValue)
    let linkUrl = this.addSearchParams(mainFilters, nestedFilters)
    linkUrl = hrefRafctoring(linkUrl)
    element.setAttribute('href', linkUrl)
  }
}