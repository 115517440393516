import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['tab']
  
  async getFavoritesTab(event) {
    event.preventDefault()
    event.stopPropagation()

    const goUrl = event.target.dataset.url
    await get(goUrl, {
      responseKind: 'turbo-stream'
    })
  }

  clearFromList(event) {
    const objectDomId = event.target.closest('a[data-method="delete"]').dataset.identObject
    const deleteElement = document.getElementById(objectDomId)
    if (deleteElement && this.is_favorites() || deleteElement && this.is_tag_posts()) deleteElement.remove()
  }

  is_favorites() {
    return document.body.dataset.currentController == "favorites"
  }

  is_tag_posts() {
    return document.body.dataset.currentController == "posts" && document.body.dataset.actionName == "tags"
  }
}