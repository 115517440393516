import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'title',
    'content',
  ]

  static values = {
    isNeedTitleTabSwitch: { type: String, default: 'false' },
    сheck: { type: String, default: 'false' }
  }

  initialValues = {};

  connect() {
    if (this.element.dataset.switchTabCheckValue === 'true') this.saveFormData()
  }

  saveFormData() {
    this.contentTargets.forEach((tabContent) => {
      const inputs = tabContent.querySelectorAll('input');

      inputs.forEach((input) => {
        const fieldName = input.id;
        const value = input.value;
        this.initialValues[fieldName] = value;
      });
    });
  }

  checkForChanges(currentTabId) {
    const tabContent = this.element.querySelector(`#${currentTabId}`);
    let inputs = tabContent.querySelectorAll('input');
    // видаляємо кнопки з trix діалогами, які постійно змінюються, а зберігати їх не потрібно
    inputs = Array.from(inputs).filter(input => !input.classList.contains('trix-button--dialog'));
    
    let hasChanged = false;

    inputs.forEach((input) => {
      const fieldName = input.id;
      const currentValue = input.value;
      const initialValue = this.initialValues[fieldName];

      if (currentValue !== initialValue) {
        hasChanged = true;
        // Поле змінилося
        console.log(`Field ${fieldName} has changed`);
        console.log(`Field input ${input} has changed`);
      }
    });

    if (hasChanged) {
      // Виводимо confirm, якщо є зміни
      const confirmationMessage = 'Є незбережені зміни. Ви бажаєте переключитися без їх збереження?';
      if (!confirm(confirmationMessage)) {
        // Користувач вирішив не перемикатися (заборонити перемикання табок)
        return false;
      }
    }
    // Переходимо до наступної табки
    return true;
  }


  performTabContent(targetId) {
    this.contentTargets.forEach(contentTab => {
      if (contentTab.id == targetId) {
        contentTab.classList.add("show", "active")
      } else {
        contentTab.classList.remove("show", "active")
      }
    })
  }

  performTabTitles(targetId) {
    this.titleTargets.forEach(titleTab => {
      if (titleTab.getAttribute('data-target') == targetId) {
        titleTab.classList.add("active")
      } else {
        titleTab.classList.remove("active")
      }
    })
  }

  switchTab(event) {
    event.preventDefault()
    const targetId = event.currentTarget.getAttribute('data-target')
    // при потребі перемикаємо на зміни у формі, за замовчуванням - не потрібно
    if (this.element.dataset.switchTabCheckValue === 'true') {
      const currentTabId = event.target.closest('ul').getAttribute('data-current-tab')
      if (!this.checkForChanges(currentTabId)) return;
    }
    // при потребі перемикаємо статус заголовків табок (кнопок), за замовчуванням - не потрібно
    if (this.isNeedTitleTabSwitchValue === 'true') this.switchTabTitles(targetId)
    // перемикаємо контент табок
    this.performTabContent(targetId)
  }
  
}