export const FILTER = {
  categorySlug: 'category-slug_',
  category: 'category_',
  makerSlug: 'maker-slug_',
  makerIds: 'maker_',
  minPrice: 'from_',
  maxPrice: 'to_',
  gender: 'gender_',
  state: 'state_',
  sort: "sort_",
  type: "type_",
  search: 'q_'
}

export const SEARCH = {
  types: ['all', 'post', 'product', 'service', 'user'],
  minPrice: 'minimum_price',
  maxPrice: 'maximum_price',
  state: 'state',
  gender: 'gender',
  type: "type",
  search: 'search',
  sort: "sort_name",
  category: 'category_id',
  makerIds: 'maker_ids[]',
  states: 'state[]'
}
