import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
  }

  startChat(event)  {
    event.preventDefault();
    const targetBtnId = event.currentTarget.dataset.targetid;
    const targetBtn = document.getElementById(targetBtnId);
    if (targetBtn) targetBtn.click();
  }
}