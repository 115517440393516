import { Controller } from '@hotwired/stimulus'
import Splide  from "@splidejs/splide/dist/js/splide.min"

export default class extends Controller {

  connect() {
    if (this.element.id === "") {
      this.element.id = this.uid
    }
    new Splide(`#${this.element.id}`, {
      type: 'loop',
      rewind: true,
      focus: 'center',
      autoWidth: true,
      'cover': false,
    }).mount();
  }

  get uid(){
    // return Date.now().toString(36) + Math.random().toString(36).substr(2); // deprecated?
    return Date.now().toString(36) + Math.random().toString(36).substring(2)
  }

}
