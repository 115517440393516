import { Controller } from '@hotwired/stimulus'
import Splide  from "@splidejs/splide/dist/js/splide.min"

export default class extends Controller {

  static targets = ['slider']
  
  connect() {
    // в принципі якщо на кожний слайдер накинути таргет то загальна ініціалізація колкції не потрібна
    // this.initSplideColections()  
  }
  
  createSplide(el) {
    new Splide(el, {
      type: 'loop',
      rewind: true,
      focus: 'center',
      'cover': false,
    }).mount()
  }

  initSplideColections() {
    const sliders = document.getElementsByClassName('splide-slider')  // на відміну від querySelector оновлює список 
    if (sliders.length === 0) return
    
    Array.from(sliders).forEach(this.createSplide)
  }

  // After render target 'slider' (need after update in TURBO stream)
  sliderTargetConnected(element) {
    this.createSplide(element)
  }
}