document.addEventListener('turbo:load', function() {
  initPublishedButton();
});

function initPublishedButton() {
  document.querySelectorAll(".publish-button").forEach((item) => {
    item.addEventListener("click", publishedEvents)
  });
}

function publishedEvents() {
  const t = I18n.profile.operation

  const object = event.target.closest(".publish-button");
  const is_published = object.dataset.isPublished == "true"
  const object_class = object.dataset.objectClass;
  const object_id = object.dataset.objectId;

  let link = event.currentTarget;
  let icon = link.querySelector("svg");

  const data = {
    is_published: !is_published
  }
  ajax({
    url: `/${currentLocale()}/${object_class}s/${object_id}/set_published`,
    type: 'put',
    data: data,
    success: function(data) {
      if (is_published) {
        link.title = t.unpublished
        icon.classList.add("disabled");
      } else {
        link.title = t.published
        icon.classList.remove("disabled");
      }
      object.dataset.isPublished = !is_published
      toaster('success', data.flash_status, data.flash_text)
    },
    error: function(data) {
      toaster('alert', data.flash_status, data.flash_text)
    }
  });
  event.preventDefault();
}
