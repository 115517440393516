import { Controller } from '@hotwired/stimulus'
import initQuickOrderForm from "./_lib/order/quickOrder.form"

export default class extends Controller {
  static values = {
    id: String
  }

  connect() {
  }
  
  newQuickOrderevent(event) {
    const productId =  this.idValue      // this.dataset.id
    const form = document.getElementById('newQuickOrderForm')
  
    form.addEventListener('hidden.bs.modal', (e) => {
      form.querySelector('form').reset();
    })
  
    const hiddenField = form.querySelector('#quick_order_product_id')
    hiddenField.value = productId
    const modal = bootstrap.Modal.getOrCreateInstance(form);
  
    initQuickOrderForm(modal);
  
    modal.show();
  }
}