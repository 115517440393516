import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['option', 'otherTextInput', 'input', 'submit']

  connect() {
    this.initFormOptions()
  }

  initFormOptions() {
    this.optionTargets.forEach(el => {
      el.classList.remove('active')
    })
    this.inputTarget.value = ''
    this.submitTarget.disabled = true
  }

  selectReasonOption(event) {
    const selectedOption = event.target.dataset.value
    
    this.optionTargets.forEach(el => {
      el.classList.toggle('active', event.target == el)
    })

    this.inputTarget.value = event.target.dataset.value
    this.submitTarget.disabled = false

    this.performOtherOptionLogic(selectedOption)
  }

  performOtherOptionLogic(selectedOption) {
    if (selectedOption === 'other') {
      this.otherTextInputTarget.classList.remove('d-none');
      this.otherTextInputTarget.addEventListener('input', () => {
        this.inputTarget.value = this.otherTextInputTarget.value;
      });
    } else {
      this.otherTextInputTarget.classList.add('d-none');
      this.otherTextInputTarget.value = '';
    }
  }
}