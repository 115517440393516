import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'
import currentUserId from "./_lib/user/currentUserId.js"
import { getRecentPages, clearRecentPage } from './_lib/recent/recentCartPages.js'
export default class extends Controller {
  static targets = [ 'spliderItems', 'listRecent' ]

  connect() {
    document.addEventListener("turbo:before-fetch-request", function (event) {
      if (event.target.id === 'recently_cart' && !currentUserId()) {
        const searchParams = event.detail.url.searchParams
        getRecentPages(searchParams)
      }
      event.detail.resume()   
    })
  }

  // оскільки можемо не мати сторінку перегляду товару чи послуги, то тільки очищаємо по одному item
  async clearOneRecent(event) {
    event.preventDefault()
    event.stopPropagation()
    const targetElement = event.target.closest('a')
    const deleteType    = targetElement.dataset.clearType
    const deletedId     = targetElement.dataset.clearId

    const url           = new URL (targetElement.href)
    
    spliderCartRecents.remove(`#${targetElement.closest('.splide__slide').id}`) // delete splide card item
    
    if (!this.hasSpliderItemsTarget) {
      this.listRecentTarget.remove() // if splide items == 0 -> delete blank list items 
      this.element.remove()
    }
    
    if(!currentUserId()) {
      clearRecentPage(deletedId, deleteType)
      return
    }

    const searchParams = url.searchParams
    searchParams.append("clear_visit_pages", "server")
    searchParams.append("deleted_id", deletedId)
    await patch(url, {
      responseKind: 'turbo-stream'
    })
  }
}
