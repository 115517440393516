import { Controller } from '@hotwired/stimulus'
import { post } from "@rails/request.js"

export default class extends Controller {
  
  static targets = [
    "phoneInput",
    "passwordInput",
    "passwordConfirmationInput",
    "phoneError",
    "passwordError",
    "passwordConfirmationError"
  ]

  static values = {
    registerUrl: { type: String, default: '/uk/users/registration' },
    passwordLength: { type: Number, default: 8 },
    phoneLength: { type: Number, default: 17 },
  }
  
  async submitForm(event) {    
    event.preventDefault();

    const t = I18n.users.registration.errors;

    const phone = this.phoneInputTarget.value
    const password = this.passwordInputTarget.value
    const passwordConfirmation = this.passwordConfirmationInputTarget.value
    
    const referrerСode = this.reffererCodeValue;
    
    const phoneError = this.phoneErrorTarget
    const passwordError = this.passwordErrorTarget
    const passwordConfirmationError = this.passwordConfirmationErrorTarget
    const submitButton = event.target

    this.clearErrors();

    if (!phone || !password || !passwordConfirmation) {
      alert(t.fill_form)
      return false;
    }

    if (phone.length < this.phoneLengthValue) {
      phoneError.innerText = t.phone.fill;
      this.phoneInputTarget.classList.add("is-invalid");

      return false;
    }

    if (password.length < this.passwordLengthValue) {
      passwordError.innerText = t.password.invalid;
      this.passwordInputTarget.classList.add("is-invalid");

      return false;
    }

    if (password !== passwordConfirmation) {
      passwordConfirmationError.innerText = t.password.not_match;
      this.passwordConfirmationInputTarget.classList.add("is-invalid");

      return false;
    }

    const loader = document.createElement('span');
    loader.className = 'spinner-border spinner-border-sm';
    loader.setAttribute('role', 'status');
    loader.setAttribute('aria-hidden', 'true');

    submitButton.setAttribute('disabled', 'true');
    submitButton.appendChild(loader);

    const data = {
      user: {
        phone,
        password
      }
    }

    if (referrerСode) data.user.referrer_code = referrerСode;

    try {
      const response = await post(this.registerUrlValue, {
        body: data,
        responseKind: "json"
      })

      if (response.ok) {
        const responseData = await response.json

        if (responseData.status === 'success') {
          this.clearErrors();
          window.location.href = responseData.path;
        } else {
          if (responseData.phone) {
            phoneError.innerText = responseData.message;
            this.phoneInputTarget.classList.add("is-invalid");
            toaster("Phone error", I18n.flash.error, "Check your phone number.");
          } else if (responseData.password) {
            passwordError.innerText = responseData.message;;
            this.passwordInputTarget.classList.add("is-invalid");
            toaster("Password error", I18n.flash.error, "Check your password.");
          } else {
            console.error("Unexpected error type:", responseData);
            toaster("Unknown error", I18n.flash.error, "An unexpected error occurred during registration.");
          } 
        } 
      } else {
        throw new Error(`${response.status} - ${response.statusText}`)
      }
    } catch (error) {
      console.error("Registration error:", error);
    } finally {
      submitButton.removeAttribute('disabled');
      submitButton.firstElementChild.remove();
    }
  }

  clearErrors() {
    const errorTargets = [this.phoneErrorTarget, this.passwordErrorTarget, this.passwordConfirmationErrorTarget];
    const inputTargets = [this.phoneInputTarget, this.passwordInputTarget, this.passwordConfirmationInputTarget];

    errorTargets.forEach(errorTarget => errorTarget.textContent = '');
    inputTargets.forEach(inputTarget => inputTarget.classList.remove("is-invalid"));
  }

  get reffererCodeValue() {
    return document.getElementById('hidden-referrer-code')?.value;
  }
}
