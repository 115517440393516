import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon']

  static values = {
    authorId: String
  }

  connect() {
    // const isCurrentUserRead = this.readedByCurrent
    const dataAttributes = this.getDataAttributes();
    const isOpponentUserRead = this.readedByOpponent(dataAttributes);
    if (this.currentUserId === this.authorId) {
      this.element.classList.add("my_block")
      if (this.hasIconTarget) this.addIconClass(this.iconTarget, isOpponentUserRead === 'true');
      // if (this.avatarBlock) this.avatarBlock.classList.replace("order-1", "order-3") // як у телеграм в один колонку
    } else {
      if (this.hasIconTarget) this.iconTarget.remove(); // видаляємо іконку, у власному message поточного користувача
    }
  }

  addIconClass(element, isRead) {
    const iconClass = isRead ? 'icon-read' : 'icon-unread';
    element.classList.add(iconClass);
  }

  get authorId() {
    return this.authorIdValue
  }

  get currentUserId() {
    return document.querySelector("[name='current-user-id']").content
  }

  get avatarBlock() {
    return this.element.querySelector(".chat-avatar")
  }

  getDataAttributes() {
    // дата атрибути динамічні, тому отримуємо їх і повертаємо в об'єкті типу user_id: true/false
    const dataAttributes = {};
    const attributes = this.element.attributes;

    Array.from(attributes).forEach((attribute) => {
      if (attribute.name.startsWith('data-readed-by-user')) {
        dataAttributes[attribute.name] = attribute.value;
      }
    });

    return dataAttributes;
  }    

  get readedByCurrent() {
    return this.element.getAttribute(`data-readed-by-user-${this.currentUserId}`)
  }

  readedByOpponent(dataAttributes) {
    // Фільтруємо поточного користувача і повертаємо значення іншого (оппонента). 
    // AuthorId - це id автора повідомлення, однак він може не бути оппонентом. Аналогічно і для currentUserId
    return Object.entries(dataAttributes)
                 .filter(([attribute]) => !attribute.startsWith(`data-readed-by-user-${this.currentUserId}`))
                 .map(([_, value]) => value)
                 .pop();
  }
}
