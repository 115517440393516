import { Controller } from '@hotwired/stimulus'
import Splide  from "@splidejs/splide/dist/js/splide.min"

export default class extends Controller {

  connect() {
    if (document.querySelector('#colections')) {
      new Splide('#colections', {
        type: 'loop',
        rewind: true,
        focus: 'center',
        autoWidth: true,
        'cover': false,
      }).mount();
    }
  }
}
