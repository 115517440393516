import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  
  static values = {
    activeId: String,
  }
  
  connect() {
    // шукаємо в DOM чи є обрана користувачем категорія (має класс "active_category")
    const activeDirectory = document.querySelector('.active_category')
    // якщо немає - виходимо
    if (!activeDirectory) return
    // шукаємо в DOM чи є кнопка "Ще..."
    const buttonMore = document.querySelector(`[data-rootid="${activeDirectory.dataset.id}"]`)
    // якщо кнопка є та вона має імʼя "Ще...", клікаєм кнопку
    if (buttonMore && buttonMore.innerText === buttonMore.dataset.moretext) buttonMore.click()
  }
}