import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static values = {
    clearCache: { type: Boolean, default: false },
    url: { type: String, default: "/" },
  }

  remove() {
    if (this.element) this.element.remove()
  }

  clearCache() {
    if (!this.clearCacheValue) return

    Turbo.clearCache() // window.location.reload(true)
  }

  redirect() {
    this.clearCache()
    Turbo.visit(this.urlValue, { turbo_frame: this.turboFrameValue }) // window.location
  }

  connect() {
    this.modal = new Modal(this.element)
    this.modal.show()

    this.element.addEventListener('hidden.bs.modal', () => {
      console.log('hidden.bs.modal')
      this.clearCache()
      this.remove()
      this.redirect()
    })
  }


  disconnect() {
    if (this.modal) this.modal.hide()
    this.remove()
  }
}
