import $ from 'jquery'
// TODO: rewrite without jquery

document.addEventListener('turbo:load', function() {
  const userTerms = document.getElementById('user_terms')
  if (userTerms) {
    $('#profile_verification_sumbit').prop('disabled', true)
    userTerms.addEventListener("change", userTermsChange)
  }
})

function userTermsChange() {
  if (this.checked) {
    $('#profile_verification_sumbit').prop('disabled', false)
  } else {
    $('#profile_verification_sumbit').prop('disabled', true)
  }
}
