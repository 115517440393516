document.addEventListener('turbo:load', function() {
  const userTab = document.getElementById('userTab')
  if (userTab) {
    console.log('User tab worked!')
    // initUserTab(userTab);
    // initTabChangeAchor();
  }
})

function initUserTab(userTab) {
  const selectTabId = window.location.hash.split("?")[0]
  let triggerTab = "a[href='#info']"
  if (selectTabId !== "") {
    triggerTab = `a[href='${selectTabId}']`
  }
  const achorTab = userTab.querySelector(triggerTab)

  new bootstrap.Tab(achorTab).show()
}

function initTabChangeAchor() {
  document.querySelectorAll('a[data-bs-toggle="tab"]').forEach((item) => {
    item.addEventListener("show.bs.tab", changeAnchor);
  })
}
