document.addEventListener("turbo:load", () => {
  if (!location.href.includes('import_url')) return

  const productsCount = parseInt(document.getElementById("products-count").innerText)
  const categoriesCount = parseInt(document.getElementById("categories-count").innerText)
  const perPage = document.getElementById("per_page").value

  let queryCount = 10;
  let importedCount = 0;
  let updatedCount = 0;
  let errorsCount = 0;

  let importedCategoriesCount = 0;
  let errorsCategoriesCount = 0;

  const importSourceId = document.getElementById("import_source").dataset.importSourceId

  const categoriesProgressBar = document.getElementById("categories-progress-bar")
  const progressBar = document.getElementById("progress-bar")

  const startButton = document.getElementById("start_import")
  const spinnerBorder = startButton.querySelector(".spinner-border")

  function startImport() {
    event.target.disabled = true
    event.target.querySelector(".spinner-border").style.display = "block"
    progressBar.style.display = "flex"
    categoriesProgressBar.style.display = "flex"
    for (let i = 0; i < queryCount; i++) {
      findAndSendTr()
    }
  }

  startButton.addEventListener("click", function () {
    startImport();
  })
  let block = false;
  document.querySelectorAll("input").forEach((input, i) => {
    input.addEventListener("change", function () {
      if (!block) {
        blockBedWay();
      }
    })
  });


  document.querySelectorAll("a.old-category-link").forEach(sourceCategory =>
    sourceCategory.addEventListener("click", goToSourceCategory, false)
  )

  document.querySelectorAll("button.page-link").forEach(sourceCategory =>
    sourceCategory.addEventListener("click", paginationJs, false)
  )

  document.getElementById("per_page").addEventListener("change", selectRowJs, false);

  // TODO: translate
  function blockBedWay() {
    block = true;
    const beforeUnloadListener = (event) => {
      if (block) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to exit?";
        return false;
      }
    };
    addEventListener("beforeunload", beforeUnloadListener, {
      capture: true
    });
  }

  function goToSourceCategory() {
    event.preventDefault();
    const object = event.target
    const id = object.closest("td.category").dataset.sourceCategoryId;
    const selectTr = document.getElementById(`category_rabase_${id}`);
    selectTr.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
    const activeTr = document.querySelector("table.categories tr.active");
    if (activeTr) {
      activeTr.classList.remove("active");
    }
    selectTr.classList.add("active");
  }

  function paginationJs() {
    const button = event.target;
    const page = parseInt(button.dataset.page);
    const pages = Math.ceil(productsCount / perPage);
    updateProductTableAndPagination(page, pages, perPage);
  }

  function selectRowJs() {
    const selector = event.target;
    const page = document.querySelector("ul.pagination li.page-item.active button").dataset.page
    const pages = Math.ceil(productsCount / perPage)
    updateProductTableAndPagination(page, pages, perPage);
  }

  function updateProductTableAndPagination(page, pages, perPage) {
    let trs = Array.from(document.querySelector("table.products tbody").querySelectorAll("tr"))
    let shows = document.querySelector("table.products tbody").querySelectorAll("tr:not([d-none])")
    shows.forEach((tr) => {
      tr.classList.add("d-none")
    })
    trs.slice(page * perPage, (page + 1) * perPage - 1).forEach((tr) => {
      tr.classList.remove("d-none")
    })
    let lis = document.querySelectorAll("li.page-item")
    let buttons = document.querySelectorAll("button.page")
    if (pages > 5) {
      if (page < 3) {
        buttons.forEach(function (button, currentIndex, listObj) {
          button.dataset.page = currentIndex
          button.innerHTML = currentIndex + 1
        });
      } else if (pages - page < 3) {
        buttons.forEach(function (button, currentIndex, listObj) {
          button.dataset.page = pages - 5 + currentIndex
          button.innerHTML = pages - 4 + currentIndex
        });
      } else {
        buttons.forEach(function (button, currentIndex, listObj) {
          button.dataset.page = (page - 2) + currentIndex
          button.innerHTML = (page - 1) + currentIndex
        });
      }
    }
    const activeElement = document.querySelector("li.page-item.active")
    const newActiveElement = document.querySelector(`button.page[data-page='${page}']`).closest("li.page-item")
    if (activeElement != newActiveElement) {
      activeElement.classList.remove("active")
      newActiveElement.classList.add("active")
    }
    document.querySelector("table.products").scrollIntoView({
      behavior: "smooth"
    });
  }

  function sendCategoryRequest(tr) {
    tr.classList.add("active")

    const data = {
      category: {
        original_id: parseInt(tr.querySelector("td.original_id").innerText),
        name: tr.querySelector(".name").innerText,
        id: tr.querySelector(".new_category_id select").value
      },
      import_source: {
        id: importSourceId
      }
    }

    ajax({
      url: `/${currentLocale()}/categories/import_by_url`,
      type: "POST",
      data: data,
      success: function (data) {
        if (data.status === "success") {
          tr.classList.add("import");
          importedCategoriesCount++
        }
        tr.classList.remove("active");
        updateCategoriesProgressBar();
        findAndSendTr();
      },
      error: function (data) {
        tr.classList.add("error");
        tr.classList.remove("active");
        errorsCategoriesCount++
        updateCategoriesProgressBar();
        findAndSendTr();
      }
    })
  }

  function sendRequest(tr) {
    const categoryId = tr.querySelector("td.category").dataset["sourceCategoryId"]
    const categoryTr = document.getElementById(`category_rabase_${categoryId}`)
    tr.classList.add("active")

    const markUpInput = document.querySelector('input#mark_up')
    let markUp = Number(markUpInput.value)

    if (typeof markUp !== "number" || isNaN(Number(markUp))) {
      markUp = 0
    }

    const data = {
      product: {
        original_id: parseInt(tr.querySelector("td.original_id").innerText),
        name: tr.querySelector("input#name").value,
        price: parseFloat(tr.querySelector("input#price").value) * (1 + (markUp / 100)),
        manufacturer: tr.querySelector("input#maker").value,
        from_age: tr.querySelector("input#from_age").dataset.value,
        to_age: tr.querySelector("input#to_age").dataset.value,
        gender: tr.querySelector("select#gender").value,
        category_id: categoryTr.querySelector(".new_category_id select").value,
        state: tr.querySelector("select#state").value,
        count_type: tr.querySelector("select#count_type").value,
        quantity: tr.querySelector(".count").innerText,
        present: tr.querySelector(".present").innerText,
        description: tr.querySelector("textarea#description").value,
        images: tr.querySelector("input#images").value,
        // parameter_value_ids: tr.querySelector("input#parameter_value_ids").value.split(', ')
      },
      import_source: {
        id: importSourceId,
        mark_up: markUp,
      }
    }

    ajax({
      type: "POST",
      url: `/${currentLocale()}/products/import_url_start`,
      data: data,
      success: function (data) {
        if (data.status === "success") {
          tr.classList.add("import");
          importedCount++
        } else if (data.status === "updated") {
          tr.classList.add("updated");
          updatedCount++
        }
        tr.classList.remove("active");
        updateProgressBar();
        findAndSendTr();
      },
      error: function (data) {
        tr.classList.add("error");
        tr.classList.remove("active");
        errorsCount++
        updateProgressBar();
        findAndSendTr();
      }
    })
  }

  function updateCategoriesProgressBar() {
    categoriesProgressBar.querySelector(".bg-success").style.width = `${(importedCategoriesCount / categoriesCount) * 100}%`
    categoriesProgressBar.querySelector(".bg-danger").style.width = `${(errorsCategoriesCount / categoriesCount) * 100}%`
  }

  function updateProgressBar() {
    progressBar.querySelector(".bg-success").style.width = `${(importedCount / productsCount) * 100}%`
    progressBar.querySelector(".bg-info").style.width = `${(updatedCount / productsCount) * 100}%`
    progressBar.querySelector(".bg-danger").style.width = `${(errorsCount / productsCount) * 100}%`
  }

  const imported_ids = []

  function findAndSendTr() {
    let tr = document.querySelector("table.categories tbody").querySelector("tr:not(.import,.active,.error)")
    if (!tr) {
      tr = document.querySelector("table.products tbody").querySelector("tr:not(.import,.updated,.active,.error)")
    } else {
      imported_ids.push(tr);
      sendCategoryRequest(tr);
      return;
    }
    if (tr) {
      sendRequest(tr);
    } else {
      startButton.disabled = false
      spinnerBorder.style.display = "none"
    }
  }

})
