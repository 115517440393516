import { Controller } from '@hotwired/stimulus'
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "phone", 
    "password", 
    "phoneError", 
    "passwordError"]
  
  static values = {
    profileUrl: { type: String, default: "/profile" },
    sessionUrl: { type: String, default: '/uk/users/session' },
    passwordLength: { type: Number, default: 8 },
    phoneLength: { type: Number, default: 17 },
  }

  async submitForm(event) {
    event.preventDefault()
    
    const phone = this.phoneTarget.value
    const password = this.passwordTarget.value
    const rememberMe = this.rememberMeValue()
    const wizard = 'order'

    this.clearErrors()

    if (!phone || !password) {
      alert(I18n.users.registration.errors.fill_form)
      return false
    }

    if (phone.length < this.phoneLengthValue) {
      this.phoneErrorTarget.innerText = I18n.users.registration.errors.phone.fill
      this.phoneTarget.classList.add("is-invalid")
      return false
    }

    if (password.length < this.passwordLengthValue) {
      this.passwordErrorTarget.innerText = I18n.users.registration.errors.password.invalid
      this.passwordTarget.classList.add("is-invalid")
      return false
    }

    const data = { phone, password, rememberMe, wizard }

    try {
      const response = await post(this.sessionUrlValue, {
        body: data,
        responseKind: "json"
      })

      if (response.ok) {
        const responseData = await response.json

        if (responseData.user) {
          this.clearErrors()

          if (this.formPlace === "wizard") {
            window.location.href = this.backUrl
          } else if (this.userLastUrl && this.userLastUrl.startsWith("/")) {
            window.location.href = this.userLastUrl
          } else {
            window.location.href = this.profileUrlValue
          }
          setCookie("user_last_url", "")
        } else {
          this.phoneTarget.value = phone
          this.passwordTarget.value = password

          if (responseData.password) {
            this.passwordErrorTarget.innerText = responseData.message
            this.passwordTarget.classList.add("is-invalid")
          } else {
            this.passwordErrorTarget.innerText = ""
            this.passwordTarget.classList.remove("is-invalid")
          }

          if (responseData.phone) {
            this.phoneErrorTarget.innerText = responseData.message
            this.phoneTarget.classList.add("is-invalid")
          } else {
            this.phoneErrorTarget.innerText = ""
            this.phoneTarget.classList.remove("is-invalid")
          }
        }
      } else {
        throw new Error(`${response.status} - ${response.statusText}`)
      }
    } catch (error) {
      toaster("error", I18n.flash.error, error.message)
    }
  }

  rememberMeValue() {
    const input = document.getElementById("remember_me")
    return input && input.checked ? "1" : "0"
  }

  get parentForm() {
    if (!this._memoizedParentForm) {
      this._memoizedParentForm = this.element.closest("form");
    }

    return this._memoizedParentForm;
  }

  get formPlace() {
    return this.parentForm.getAttribute("data-form-place")
  }

  get backUrl() {
    return this.parentForm.getAttribute("data-back-url")
  }
  
  get userLastUrl() {
    return getCookie("user_last_url")
  }

  clearErrors() {
    this.phoneErrorTarget.innerText = ""
    this.passwordErrorTarget.innerText = ""
    this.phoneTarget.classList.remove("is-invalid")
    this.passwordTarget.classList.remove("is-invalid")
  }
}