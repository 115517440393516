import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'counter']
  static values = { 
    countdown: Boolean,
    isRichText: { type: Boolean, default: false }
   }

  initialize () {
    this.update = this.update.bind(this)
  }

  connect () {
    if (!this.hasCounterTarget || !this.hasInputTarget) return

    this.update()
    this.inputTarget.addEventListener('input', this.update)
  }

  disconnect () {
    if(this.hasInputTarget) {
      this.inputTarget.removeEventListener('input', this.update)
    }
  }

  update () {
    this.counterTarget.innerHTML = this.count.toString()
  }

  get count () {
    let value = this.isRichTextValue ? this.inputTarget.textContent.length : this.inputTarget.value.length

    if (this.hasCountdownValue) {
      if (this.maxLength === 0) {
        console.error(
          `[stimulus-character-counter] You need to add a maxlength attribute on the input to use countdown mode. The current value is: ${this.maxLength}.`
        )
      }

      value = Math.min(0 + value, this.maxLength) // reverse counter: value = Math.min(this.maxLength - value, 0)
    }

    return value
  }

  get maxLength () {
    if (this.isRichTextValue) {
      return this.inputTarget.getAttribute('maxlength')
    } else {
      return this.inputTarget.maxLength
    }
  }
}