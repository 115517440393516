import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'fromAgeYearSelect',
    'fromAgeMonthSelect',
    'toAgeYearSelect',
    'toAgeMonthSelect',
    'fromAgeNoLimitCheckbox',
    'toAgeNoLimitCheckbox',
    'fromAgeInput',
    'toAgeInput'
  ]

  connect() {
    if (this.fromAgeNoLimitCheckboxTarget.dataset.fromBlank === 'true') {
      this.fromAgeNoLimitCheckboxTarget.checked = true;
    }
    if (this.toAgeNoLimitCheckboxTarget.dataset.toBlank === 'true') {
      this.toAgeNoLimitCheckboxTarget.checked = true;
    }
  }

  nolimitFromAgeChecked(event) {
    if (event.currentTarget.checked) {
      this.fromAgeYearSelectTarget.classList.add('d-none')
      this.fromAgeMonthSelectTarget.classList.add('d-none')
    } else {
      this.fromAgeYearSelectTarget.classList.remove('d-none')
      this.fromAgeMonthSelectTarget.classList.remove('d-none')
    }
  }

  nolimitToAgeChecked(event) {
    if (event.currentTarget.checked) {
      this.toAgeYearSelectTarget.classList.add('d-none')
      this.toAgeMonthSelectTarget.classList.add('d-none')
    } else {
      this.toAgeYearSelectTarget.classList.remove('d-none')
      this.toAgeMonthSelectTarget.classList.remove('d-none')
    }
    this.changeFormParams()
  }

  changeFormParams() {
    if (this.fromAgeNoLimitCheckboxTarget.checked) {
      this.fromAgeInputTarget.value = '0'
    } else {
      const yearSelectValue = +this.fromAgeYearSelectTarget.value
      const monthSelectValue = +this.fromAgeMonthSelectTarget.value
      this.fromAgeInputTarget.value = yearSelectValue !== 0 ? yearSelectValue * 12 + monthSelectValue : monthSelectValue
    }
    
    if (this.toAgeNoLimitCheckboxTarget.checked) {
      this.toAgeInputTarget.value = '216'
    } else {
      const yearSelectValue = +this.toAgeYearSelectTarget.value
      const monthSelectValue = +this.toAgeMonthSelectTarget.value
      this.toAgeInputTarget.value = yearSelectValue !== 0 ? yearSelectValue * 12 + monthSelectValue : monthSelectValue
    }  
  }
}