document.addEventListener("turbo:load", () => {
  initCatalogCategoriesButton()
  // initMouseOverNavbarAction()
});

function initCategoriesDropdownChevron() {
  // click
  const categoryPanes = document.querySelectorAll('.category-pane')
  const categoryItems = document.querySelectorAll(".categories li")
  categoryPanes.forEach((link) => {
    link.addEventListener('click', () => {
      document.querySelector("#turbo-loader-container").classList.remove('d-none')
      document.querySelector("#turbo-loader-backdrop").classList.remove('d-none')
    })
  }) // add loader for click on category item 
  categoryItems.forEach((item) => {
    item.addEventListener("click", renderChevron() )
  })
}

function renderChevron() {
  const object = event.target.closest("span")
  const svg = object.querySelector("svg")
  if (svg.classList.contains("fa-chevron-down")) {
    svg.classList.remove("fa-chevron-down");
    svg.classList.add("fa-chevron-right");
  } else {
    svg.classList.remove("fa-chevron-right");
    svg.classList.add("fa-chevron-down");
  }
}

function initMouseOverAction() {
  const items = document.querySelectorAll('.categoriesList_item')
  items.forEach(item => item.addEventListener('mouseover', function() {
    this.closest('.align-items-center')?.querySelector('.cheron_control')?.click()
  })) 
}

function initCatalogCategoriesButton() {
  document.querySelectorAll(".catalogCategoriesButton").forEach((item) => {
    if(item.dataset.btn == "products"){
      item.addEventListener("click", renderCategoriesProduct)
    } else if(item.dataset.btn == "services"){
      item.addEventListener("click", renderCategoriesService)
    } else
    item.addEventListener("click", renderCategories)
  });
}

function renderCategories() {
  const catalogCategoriesBlock = document.getElementById("catalogCategories")
  if (catalogCategoriesBlock)
    ajax({
      url: `/${currentLocale()}/catalog/categories`,
      type: 'GET',
      success: function(data) {
        catalogCategoriesBlock.innerHTML = data.categories
        initCategoriesDropdownChevron()
        initMouseOverAction()
      }
    });
}

function renderCategoriesProduct() {
  const catalogCategoriesBlockProducts = document.querySelectorAll(".catalogCategoriesProduct")
  if (catalogCategoriesBlockProducts)
    for (let catalogCategoriesBlockProduct of catalogCategoriesBlockProducts) {
      ajax({
        url: `/${currentLocale()}/catalog/categories?type=product`,
        type: 'GET',
        success: function(data) {
            catalogCategoriesBlockProduct.innerHTML = data.categories
            initCategoriesDropdownChevron()
            initMouseOverAction()
        }
      })
    }
}

function renderCategoriesService() {
  const catalogCategoriesBlockServices = document.querySelectorAll(".catalogCategoriesService")
  if (catalogCategoriesBlockServices)
    for (let catalogCategoriesBlockService of catalogCategoriesBlockServices) {
      ajax({
        url: `/${currentLocale()}/catalog/categories?type=service`,
        type: 'GET',
        success: function(data) {
            catalogCategoriesBlockService.innerHTML = data.categories
            initCategoriesDropdownChevron();
            initMouseOverAction();
        }
      });
    }
}
