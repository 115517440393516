import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [
    'firstName',
    'lastName',
    'canvas',
    'initials',
    'changeBG',
    'fileInput',
    'loadModal',
    'selectGender',
    'avatarTypes',
    'avatarPreview',
    'avatarImageData',
    'userPersistedAvatar'
  ]

  static values = {
    email: String,
    initials: String,
    radius: { type: String, default: '75' },
    textsize: { type: String, default: '112' },
    canvaswidth: { type: String, default: '150' },
    canvasheight: { type: String, default: '150' },
    pady: { type: String, default: '30' },
    centered: { type: String, default: 'true' }
  }

  centerX
  centerY
  placePriviewContainer
  placePriviewContent
  userEmail

  menColors = [
    "#7BA3CC", "#2D5066", "#1A3A4F", "#00467F", "#1B263B",
    "#85929E", "#2E4053", "#ff0000", "#0000ff", "#008000",
    "#ffa500", "#ffff00", "#800080", "#000080", "#008080",
    "#800000", "#808000", "#00ff00", "#40e0d0", "#ffd700"
  ]
  womenColors = [
    "#FFC0CB", "#EA8A8F", "#D76C6F", "#9370DB", "#40E0D0",
    "#FFFF00", "#FFA500", "#FF0000", "#DC2A2A", "#0000FF",
    "#A52A2A", "#ff80ed", "#FF00FF", "#FFD700", "#CD7F32",
    "#B87333", "#00AA55", "#009FD4", "#B381B3", "#800000"
  ];

  connect() {
    if (!this.hasAvatarImageDataTarget) return
    
    if (this.initialsValue === 'true') {
      this.createFromInitials()
      this.setRadioButtons('from_initials')
    } else {
      this.createFromFile()
      this.setRadioButtons('from_disk')
    }
    this.updateProfilePreview()
  }

  prepareToGenerate() {
    if (!this.userPersistedAvatarTarget.classList.contains('d-none')) {
      this.userPersistedAvatarTarget.classList.add('d-none');
    }
    if (this.canvasTarget.classList.contains('d-none')) {
      this.canvasTarget.classList.remove('d-none');
    }
  }

  prepareToLoadFile() {
    if (this.userPersistedAvatarTarget.classList.contains('d-none')) {
      this.userPersistedAvatarTarget.classList.remove('d-none');
    }
    if (!this.canvasTarget.classList.contains('d-none')) {
      this.canvasTarget.classList.add('d-none');
    }
  }

  parseInitials() {
    const firstName = this.hasFirstNameTarget && this.firstNameTarget.value?.trim()
    const lastName = this.hasLastNameTarget && this.lastNameTarget.value?.trim()
    if (firstName && lastName) {
      return firstName[0].toLowerCase() + lastName[0].toLowerCase()
    } else if (firstName) {
      return firstName.slice(0, 2).toLowerCase()
    } else if (lastName) {
      return lastName.slice(0, 2).toLowerCase()
    } else if (this.emailValue) {
      return this.emailValue.slice(0, 2).toLowerCase()
    } else {
      return 'N'
    }
  }

  parseColors(gender) {
    if (gender === 'women') {
      return this.womenColors;
    } else if (gender === 'man') {
      return this.menColors;
    } else {
      return [...this.menColors, ...this.womenColors];
    }
  }

  loadFileFromInput(input) {
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      const _this = this
      reader.onload = function (e) {
        _this.userPersistedAvatarTarget.classList.remove('d-none')
        _this.canvasTarget.classList.add('d-none')
        const img = document.createElement("img")
        img.setAttribute("id", "avatarPreviewField")
        img.setAttribute("class", "d-flex rounded-full")
        // if (_this.hasCenteredValue && _this.centeredValue === 'true') img.classList.add('offset-3')
        img.setAttribute("width", `${_this.canvaswidthValue}`)
        img.setAttribute("height", `${_this.canvasheightValue}`)
        img.style.cssText = 'object-fit: cover;';
        img.setAttribute("data-cropper-target", "avatarPreviewField");
        img.setAttribute("src", e.target.result)
        // Замінити поточне зображення новим
        const existingImage = _this.userPersistedAvatarTarget.querySelector('img#avatarPreviewField');
        if (existingImage) {
          existingImage.replaceWith(img)
          // _this.userPersistedAvatarTarget.replaceChild(img, existingImage);
        } else {
          _this.userPersistedAvatarTarget.appendChild(img);
        }
      }
      reader.readAsDataURL(input.files[0])
    }
  }

  inputFilePreview(event) {
    if (this.fileInputTarget.disabled === true) this.fileInputTarget.disabled = false
    const input = event.target

    this.loadFileFromInput(input)
  }

  changeAvatarType(event) {
    if (event.target.checked) {
      this.createFromFile()
    } else {
      this.createFromInitials()
    }
  }

  drawAvatar(initials, bgColor) {
    // Получаем контекст рисования
    const ctx = this.canvasTarget.getContext("2d");
    // Очищаем canvas
    ctx.clearRect(0, 0, +this.canvaswidthValue, +this.canvasheightValue);
    // Рисуем круг
    const centerX = +this.canvaswidthValue / 2;
    const centerY = +this.canvasheightValue / 2;
    ctx.beginPath();
    ctx.arc(centerX, centerY, +this.radiusValue, 0, 2 * Math.PI);
    ctx.fillStyle = `${bgColor}`;
    ctx.fill();
    // Наносим буквы initials внутри круга
    ctx.fillStyle = "white";
    ctx.font = `${+this.textsizeValue}px Arial`;
    var text = `${initials}`;
    var textWidth = ctx.measureText(text).width;
    var textX = centerX - textWidth / 2;
    var textY = +this.padyValue + centerY;
    ctx.fillText(text, textX, textY);
    const imageData = this.canvasTarget.toDataURL("image/png")
    this.avatarImageDataTarget.value = imageData;
  }

  handleGenerate() {
    this.prepareToGenerate()
    const initials = this.parseInitials()
    const gender = this.selectGenderTarget.value
    const сolors = this.parseColors(gender)
    const bgColor = сolors[Math.floor(Math.random() * сolors.length)]
    this.drawAvatar(initials, bgColor)
  }

  updatePreview() {
    const selectedType = this.avatarTypesTargets.find(radio => radio.checked).value
    if (selectedType === 'from_disk') return

    this.handleGenerate()
  }

  updateProfilePreview() {
    const selectedType = this.avatarTypesTargets.find(radio => radio.checked).value
    if (selectedType === 'from_initials') {
      this.setAvatarTypeInitials()
      this.handleGenerate()
    } else if (selectedType === 'from_disk') {
      this.setAvatarTypeFile()
    }
  }

  createFromFile() {
    this.avatarImageDataTarget.disabled = true;
    this.fileInputTarget.disabled = false;
    this.changeBGTarget.classList.add('d-none')
  }

  createFromInitials() {
    this.avatarImageDataTarget.disabled = false;
    this.fileInputTarget.disabled = true;
    this.changeBGTarget.classList.remove('d-none')
  }

  setAvatarTypeInitials() {
    this.prepareToGenerate()
    this.createFromInitials()
    this.updatePreview()
  }

  setAvatarTypeFile() {
    this.prepareToLoadFile()
    this.createFromFile()
    this.loadFileFromInput(this.fileInputTarget)
  }

  setRadioButtons(value) {
    this.avatarTypesTargets.forEach(radio => {
      if (radio.value === value) {
        radio.checked = true
      } else {
        radio.checked = false
      }
    })
  }

  loadModalTargetConnected(element) {
    document.getElementById('load-add-more-info-modal').click()
    this.element.remove()
  }
}
