import { Controller } from '@hotwired/stimulus'
import { destroy } from '@rails/request.js'
// 'post-delete'
export default class extends Controller {
  
  static values = { 
    url: String,
    postUrl: String } 

  async deletePost(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const response = await destroy(this.urlValue, {responseKind: 'turbo-stream'})
    if (response.ok) {
      Turbo.visit(this.postUrlValue)
    }
  }

  async deleteProfilePost(event) {
    event.preventDefault()
    event.stopPropagation()

    const response = await destroy(event.target.closest('a').href, {responseKind: 'turbo-stream'})
    if (response.ok) {
      // Turbo.visit(window.location.pathname.toString().concat('#posts'), { action: 'replace' })
      Turbo.visit(window.location.pathname.toString().concat('#posts'))
    }
  }
}
