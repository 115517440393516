import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { FILTER, SEARCH } from './_lib/constants.js' //  import * as constants from './_lib/constants.js'
import { createValidPath, set_full_path, hrefRafctoring } from './_lib/parseUrl.js'
export default class extends Controller {
  static targets = ['minimumPrice',
                    'maximumPrice',
                    'inputFilters', 
                    'applyButton',
                    'resetButton',
                    'sortSelect',
                    'absent',
                    'label'
                   ]

  static values = {
    absent: Object,
    complexParams: Object,
    requestValue: { type: Boolean, default: false }
  }

  controllerName = document.querySelector('body').dataset.currentController
  loader = document.querySelector("#turbo-loader-container")
  loaderBackdrop = document.querySelector("#turbo-loader-backdrop")
  resetElement = document.querySelector("#filters-badges > ul")
  inputCancel = document.querySelector('.input-cancel')

  connect() {
    document.querySelector('#filters')?.addEventListener('submit', event => event.preventDefault())
    document.querySelector('#maker-search-form')?.addEventListener('submit', event => event.preventDefault())
  }

  prepareBeforeResponce() {
    this.requestValue = true
    this.loader.classList.remove('d-none')
    this.loaderBackdrop.classList.remove('d-none')
  }

  clearAfterResponce() {
    this.requestValue = false
    this.loader.classList.add('d-none')
    this.loaderBackdrop.classList.add('d-none')
  }

  paramsMakersAppend(makers, seoParams) {
    let makerIdsArray = []
    let makerSlugsArray = []
    if (makers.length > 1) {
      makers.forEach((_, idx) => {
        makerIdsArray.push(makers[idx].dataset.id)
        makerSlugsArray.push(makers[idx].dataset.slug)
      })
    } else {
      makerIdsArray.push(makers[0].dataset.id)
      makerSlugsArray.push(makers[0].dataset.slug)
    }
    makerIdsArray = makerIdsArray.length > 1 ? makerIdsArray.join('_or_') : makerIdsArray
    makerSlugsArray = makerSlugsArray.length > 1 ? makerSlugsArray.join('_or_') : makerSlugsArray
    seoParams.push(FILTER.makerIds + makerIdsArray)
    seoParams.push(FILTER.makerSlug + makerSlugsArray)
  }

  paramsStateAppend(stateChecked, seoParams) {
    if (stateChecked.length == 1) {
      const stateParams = FILTER.state + stateChecked[0].value
      seoParams.push(stateParams)
    }
    return seoParams
  }

  paramsGenderAppend(genderChecked, seoParams) {
    if (genderChecked.length == 1) {
      const genderParams = FILTER.gender + genderChecked[0].value
      seoParams.push(genderParams)
    }
    return seoParams
  }
  getStateValue() {
    return new Array(...document.querySelectorAll('.checkbox-state-input:checked'))
  }

  getGenderValue() {
    return new Array(...document.querySelectorAll('.checkbox-gender-input:checked'))
  }
  
  getSortingsValue() {
    if (!this.hasSortSelectTarget) return ''

    return this.sortSelectTarget?.value || ''
  }

  hasPriceFilterApply(event) {
    const hasPriceFilter = event.target.closest('div .wrapper-price')
    return typeof(hasPriceFilter) != 'undefined' && hasPriceFilter != null
  }

  getMinInputPrice() {
    return this.minimumPriceTarget.value.trim().length === 0 ? this.minimumPriceTarget.min : this.minimumPriceTarget.value
  }

  getMaxInputPrice() {
    return this.maximumPriceTarget.value.trim().length === 0 ? this.maximumPriceTarget.max : this.maximumPriceTarget.value
  }

  getMinParamsPrice() {
    const minParamsValue = this.minimumPriceTarget.closest('#filters-price-section').dataset.minParams.trim()
    if (minParamsValue.length === 0) return
    
    return minParamsValue
  }

  getMaxParamsPrice() {
    const maxParamsValue = this.maximumPriceTarget.closest('#filters-price-section').dataset.maxParams.trim()
    if (maxParamsValue.length === 0) return
    
    return maxParamsValue
  }

  // parse type from selected tabs
  getTypeFromTabs() {
    const [link] = Array.from(document.querySelectorAll(".tabs-link")).filter(el => el.classList.contains('active'))

    return link.dataset.tabType
  }

  // parse filter type options
  setTypeFilter(seoParams) {
    if (this.hasAbsentValue && 'type' in this.absentValue) {
      const hasValue = this.absentValue.type.trim().length !== 0
      if (hasValue) seoParams.push(FILTER.type + this.absentValue.type)
      return seoParams
    }

    const searchType = this.getTypeFromTabs()
    if (searchType) seoParams.push(FILTER.type + searchType)
    return seoParams.sort()
  }

  setSearchFilter(seoParams) {
    // якщо у фільтрі не використовується пошукоивй запит
    if (this.hasAbsentValue && FILTER.search in this.absentValue) {
      const hasValue = this.absentValue.search.trim().length !== 0
      if (hasValue) seoParams.push(FILTER.search + this.absentValue.search)
      return seoParams
    }

    const searchQuery = this.hasApplyButtonTarget && this.applyButtonTarget?.dataset.querySearch || document.querySelector('#search').value.trim()
    if (searchQuery) seoParams.push(FILTER.search + searchQuery)
    return seoParams
  }

  setCategoryIdFilter(seoParams) {
    if (this.hasAbsentValue && 'categoryId' in this.absentValue) {
      const hasIdValue = this.absentValue.catgoryId.trim().length !== 0
      const hasSlugValue = this.absentValue.categorySlug.trim().length !== 0
      if (hasIdValue) seoParams.push(FILTER.category + this.absentValue.catgoryId)
      if (hasSlugValue) seoParams.push(FILTER.categorySlug + this.absentValue.categorySlug)
      return seoParams
    }
    
    const categoryId = this.hasApplyButtonTarget && this.applyButtonTarget?.dataset.categoryId
    const categorySlug = this.hasApplyButtonTarget && this.applyButtonTarget?.dataset.categorySlug
    if (categoryId) seoParams.push(FILTER.category + categoryId)
    if (categorySlug) seoParams.push(FILTER.categorySlug + JSON.parse(categorySlug))
    return seoParams
  }

  get checkboxes() {
    return new Array(...this.labelTargets)
  }

  get selectedCheckboxes() {
    return this.checkboxes.filter((c) => c.checked);
  }

  // Сортування списку
  async applySort(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const seoParams = []
    let goToUrl = createValidPath() // new URL(createRootUrlWithLocale(this.controllerName))
    this.setTypeFilter(seoParams)
    this.setSearchFilter(seoParams)
    if (this.controllerName != 'categories') this.setCategoryIdFilter(seoParams)
    const stateChecked = this.getStateValue()
    const genderChecked = this.getGenderValue()
    const sortSelect = this.getSortingsValue()
    const minPrice = this.hasApplyButtonTarget && this.applyButtonTarget?.dataset.paramsMinimumPrice
    const maxPrice = this.hasApplyButtonTarget && this.applyButtonTarget?.dataset.paramsMaximumPrice
    const makers = this.selectedCheckboxes
    if (minPrice) seoParams.push(FILTER.minPrice + minPrice)
    if (maxPrice) seoParams.push(FILTER.maxPrice + maxPrice)
    if (makers.length !== 0) this.paramsMakersAppend(makers, seoParams)
    if (stateChecked.length !== 0) this.paramsStateAppend(stateChecked, seoParams)
    if (genderChecked.length !== 0) this.paramsGenderAppend(genderChecked, seoParams)
    if (sortSelect) seoParams.push(FILTER.sort + sortSelect)
    if (this.hasShowMeParams) seoParams.push('showme' + '_true');
    goToUrl = set_full_path(goToUrl, seoParams)
    const response = await get(goToUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goToUrl = hrefRafctoring(goToUrl)
      history.replaceState(history.state, "", goToUrl)
    }
  }

  // Застосування обраних фільтрів
  async filtersApply(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    let seoParams = []
    let goToUrl = createValidPath()
    this.setTypeFilter(seoParams)
    this.setSearchFilter(seoParams)
    if (this.controllerName != 'categories') this.setCategoryIdFilter(seoParams)
    const stateChecked = this.getStateValue()
    const genderChecked = this.getGenderValue()
    const sortSelect = this.getSortingsValue()
    const minPrice = this.hasPriceFilterApply(event) ? this.getMinInputPrice() : this.getMinParamsPrice()
    const maxPrice = this.hasPriceFilterApply(event) ? this.getMaxInputPrice() : this.getMaxParamsPrice()
    const makers = this.selectedCheckboxes
    if (minPrice) seoParams.push(FILTER.minPrice + minPrice)
    if (maxPrice) seoParams.push(FILTER.maxPrice + maxPrice)
    if (makers.length !== 0) this.paramsMakersAppend(makers, seoParams)
    if (stateChecked.length !== 0) this.paramsStateAppend(stateChecked, seoParams)
    if (genderChecked.length !== 0) this.paramsGenderAppend(genderChecked, seoParams)
    if (sortSelect) seoParams.push(FILTER.sort + sortSelect)
    if (this.hasShowMeParams) seoParams.push('showme' + '_true');
    goToUrl = set_full_path(goToUrl, seoParams)
    const response = await get(goToUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goToUrl = hrefRafctoring(goToUrl)
      history.replaceState(history.state, "", goToUrl)
    }
  }
  
  // Reset All filters (used outer function from Stimulus 'clear_filters_list_controllers.js')
  resetAllFilters(event) {
    event.preventDefault()
    event.stopPropagation()
    Turbo.clearCache()
    const resetButton = this.resetElement || document.querySelector("#filters-badges > ul")
    resetButton.clearFiltersList.resetAllFilters(event)
  }
  // чекбокси виробників
  makerChecked(event) {
    const checkbox = event.target.closest('.form-check').querySelector('input')
    if (checkbox) { 
      checkbox.checked = !checkbox.checked
    }
    this.filtersApply(event)
  }
  // чекбокси статусів
  // TODO: double with makerChecked
  checkState(event) {
    const checkbox = event.target.closest('.form-check').querySelector('input')
    if (checkbox) { 
      checkbox.checked = !checkbox.checked
    }
    this.filtersApply(event)
  }

  checkGender(event) {
    const checkbox = event.target.closest('.form-check').querySelector('input')
    if (checkbox) { 
      checkbox.checked = !checkbox.checked
    }
    this.filtersApply(event)
  }

  // set price range
  priceSet(event) {
    event.preventDefault()
    event.stopPropagation()
    this.filtersApply(event)
  }

  loadFrame() {
    this.loader.classList.remove('d-none')
    this.loaderBackdrop.classList.remove('d-none')
  }

  // reviews sort
  async applyReviewSort(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const sortSelect = this.getSortingsValue()
    const sortLink = this.element.closest('div.review_sort_selector').dataset.sortLink
    const url = new URL(sortLink, window.location.origin)
    const searchParams = url.searchParams
    if (sortSelect) searchParams.append(SEARCH.sort, sortSelect)
    
    const response = await get(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      // url = hrefRafctoring(url)
      // history.replaceState(history.state, "", url)
    }
  }

  get hasShowMeParams() {
    const searchString = "showme_true";
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    
    return params.has('show_me') || url.pathname.includes(searchString) // check in standard or seo url
  }
}
