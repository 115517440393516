import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'countInput' ]
  
  static values = {
    requestValue: { type: Boolean, default: false }
  }

  connect() {
    document.querySelectorAll(".product_cart_count").forEach((item) => {
      item.addEventListener("change", (event) => {
        this.changeProductCount(event.target.closest("input.product_cart_count"))
      })
    })
  }

  minusProductCount(event) {
    event.stopPropagation()
    event.preventDefault()
    if (this.requestValue) return

    const input = this.countInputTarget
    let count = +input.value - 1
    count = count < 1 ? 1 : count
    input.value = count
    this.changeProductCount(input)
  }

  plusProductCount(event) {
    event.stopPropagation()
    event.preventDefault()
    if (this.requestValue) return

    const input = this.countInputTarget
    input.value = +input.value + 1
    this.changeProductCount(input)
  }
  
  async changeProductCount(input) {
    if (this.requestValue) return
    
    this.requestValue = true
    const url = new URL(event.target.closest('#update-count-section').dataset.updateCartUrl)
    const count = +input.value
    const dataCount = {count: count}
    if (count > 0) {
      const response = await patch(url, {
        body:  JSON.stringify(dataCount),
        responseKind: 'turbo-stream'
      })
      if (response.ok) {
        this.requestValue = false
      }
    }
  }
}
