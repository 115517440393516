import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 
                    'template', 
                    'select', 
                    'input',
                    'label']

  connect() {
    this.element.addEventListener('hidden.bs.modal', () => {
      const modal_frame = document.querySelector('#personal_requested_modal')
      if (modal_frame) modal_frame.src = '' // інакше фрейм фактично залишається активним! 
    })
  }

  add_child(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/new_child/g, new Date().getTime())
    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_child(event) {
    event.preventDefault()
    const wrapper = event.target.closest('.child-fields')
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.classList.add('d-none')
    }
  }

  renderForm(isPregnant) {
    if (isPregnant) {
      this.removeHiddenInput()
      this.selectTarget.removeAttribute('disabled')
      this.labelTarget.classList.remove('d-none')
      this.selectTarget.classList.remove('d-none')
    } else {
      this.createHiddenInput()
      this.selectTarget.value = '';
      this.selectTarget.setAttribute('disabled', true)
      this.labelTarget.classList.add('d-none')
      this.selectTarget.classList.add('d-none')
    }
  }

  togglePregnant(event) {
    this.renderForm(event.target.checked)
  }

  createHiddenInput() {  
    const form = this.labelTarget.closest('form')
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "user[metadata]";
    input.id = "hiddenInput"
    input.value = "";
    form.appendChild(input);
  }

  removeHiddenInput() {
    const hidenInput = document.getElementById("hiddenInput")
    if (hidenInput) hidenInput.remove()
  }

  selectTargetConnected(element) {
    // Слідкуємо за оновленням сторінки користувачем щоб не втрачати контекст
    this.renderForm(this.inputTarget.checked)
  }

}