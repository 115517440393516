import {
  Toaster,
  ToasterPosition,
  ToasterTimer,
  ToasterType,
} from "bs-toaster";

const defaultPosition = ToasterPosition.BOTTOM_START
const defaultTimer = ToasterTimer.ELAPSED
const defaultDelay = 5000

// TODO: rewrite without addEventListener
document.addEventListener("turbo:load", function() {

  const successToaster = new Toaster({
    position: defaultPosition,
    type: ToasterType.SUCCESS,
    delay: defaultDelay,
    timer: defaultDelay,
  });

  const warningToaster = new Toaster({
    position: defaultPosition,
    type: ToasterType.WARNING,
    delay: defaultDelay,
    timer: defaultDelay,
  })

  const errorToaster = new Toaster({
    position: defaultPosition,
    type: ToasterType.DANGER,
    delay: defaultDelay,
    timer: defaultDelay,
  });

  window.toaster = function(type, head, text) {
    if (type === "success") {
      successToaster.create(head, text)
    } else if (type === "warning") {
      warningToaster.create(head, text)
    } else if (type === "error") {
      errorToaster.create(head, text)
    } else {
      errorToaster.create(head, text)
    }
  }
})
