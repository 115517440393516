import { Controller } from "@hotwired/stimulus";
import currentUserId from 'controllers/_lib/user/currentUserId.js';

export default class extends Controller {

  static targets = [
    'message',
    'emojiPanel',
    'quoteSection',
    'rightMenuContent'
  ]

  static values = {
    url: String,
    breakpoint: { type: Number, default: 992 },
    thresholdDuration: { type: Number, default: 1500 }
  }

  connect() {
  }

  openContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    this.showContextMenu(event);
  }

  startLongTouch() {
    this.touchStartTimeValue = Date.now();
  }

  endLongTouch(event) {
    const touchDuration = Date.now() - this.touchStartTime;
    if (touchDuration >= this.thresholdDurationValue) {
      event.preventDefault();
      this.showContextMenu(event);
    }
  }

  closeContextMenu() {
    const emojiPanel = this.emojiPanelTarget;
    // видаляємо класс активного message, якщо він є
    this.cleanActiveMessage();
    // видаляємо контекстне меню
    if (!emojiPanel.classList.contains('d-none')) emojiPanel.classList.add('d-none');
    document.removeEventListener("click", this.clickOutsideHandler);
  }

  clickOutsideHandler(clickEvent) {
    if (!this.emojiPanelTarget.contains(clickEvent.target)) {
      this.closeContextMenu();
    }
  }

  replaceUrl(messageId) {
    this.rightMenuContentTarget.dataset.messageId = messageId;
    
    const withLinks = this.emojiPanelTarget.querySelectorAll(".reaction-link");
    
    withLinks.forEach((link) => {
      let href = link.dataset.url; // Отримати поточне посилання
      href = href.replace('/message_id', `/${messageId}`); // Замінити /message_id на /id
      link.setAttribute("href", href); // Оновити атрибут href у посиланні
    });
    
    const menuItems = this.emojiPanelTarget.querySelectorAll(".menu-link");
    menuItems.forEach((link) => link.dataset.messageId = messageId );
  }

  authorizeContextMenu(authorId, emojiPanel) {
    const authorLinks = emojiPanel.querySelectorAll('.menu-author-link')
    if (currentUserId() !== authorId) {
      authorLinks.forEach(link => link.classList.add('d-none'))
    } else {
      authorLinks.forEach(link => link.classList.remove('d-none'))
    }
  }

  cleanActiveMessage() {
    const activeMessages = this.element.querySelectorAll('.message')
    activeMessages.forEach((message) => {
      if (message.classList.contains('bg-yellow-gradient')) message.classList.remove('bg-yellow-gradient');
    })
  }
  
  // шукаємо таргет, беремо атрибути та робимо посилання конрктеними. Якщо не в мобльінй версії, то визначаємо позицію контекстного меню
  showContextMenu(showEvent) {
    this.cleanActiveMessage();
    const message = showEvent.target.closest('li.message'); // На якому message відбулась подія
    const messageId = message.dataset.messageId;
    const authorId = message.dataset.messageAuthorIdValue;
    
    if (!message || !messageId || !authorId) return;
    
    this.replaceUrl(messageId) // замінюємо url на потрібні та прописуємо messageId
    const emojiPanel = this.emojiPanelTarget;
    emojiPanel.dataset.authorId = authorId
    
    clearTimeout(this.longTouchTimer); // для мобільного варіанту очищаємо таймер touchstart(end)
    
    this.authorizeContextMenu(authorId, emojiPanel);
    document.addEventListener("click", (clickEvent) => this.clickOutsideHandler(clickEvent));
    
    emojiPanel.style.opacity = 0; // для мобільного потріьбно вирахувати позицію, тому спочатку робимо меню невидимим
    if (emojiPanel.classList.contains('d-none')) emojiPanel.classList.remove('d-none');
    
    const parentElement = document.querySelector('.chat-parent')
    const position = this.calculateContextMenuPosition(showEvent, parentElement, emojiPanel);
    emojiPanel.style.top = `${position.top}px`;
    emojiPanel.style.left = `${position.left}px`;
   
    message.classList.add('bg-yellow-gradient'); // додаємо класс активного message
    emojiPanel.style.opacity = 1; // повертаємо прозорість
  }

  // додаємо блок цитування, визначаємося з hiddenField та закриваємо контекстне меню
  perform_quote(targetItem, hiddenField) {
    const quoteSection = this.quoteSectionTarget;
    const parentItem = targetItem.closest('li.message')
    const chatId = parentItem.dataset.chatId
    const template = parentItem.querySelector('template')
    const id = parentItem.dataset.quoteId
    if (!parentItem || !template || !id || !quoteSection) {
      console.error('Parent item, template, id or quote section not found.');
      return
    }

    quoteSection.innerHTML = template.innerHTML
    
    if (hiddenField) {
      const form = quoteSection.closest('form')
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", id);
      hiddenField.name = 'message[quote_id]'
      form.insertAdjacentHTML('beforeend', hiddenField.outerHTML);
    

      this.closeContextMenu()
      this.scrollToBottom()

      const inputField = form.querySelector('#message_content');
      inputField.focus();
    } else {
      const btn = quoteSection.querySelector('button')
      btn.setAttribute('data-chat-id', chatId) 
      btn.dataset.action = 'click->right-menu#resetForm' // для цього кейсу нам потрібна інша дія при кліку на кнопку
    }
  }

  // для цитування message треба у блок цитування додати старий message (зі створенням у формі hiddenField) та закрити контекстне меню
  addQuote(event) {
    event.preventDefault()
    event.stopPropagation();

    const targetItem = document.querySelector(`#message_${event.currentTarget.dataset.messageId}`)
    this.perform_quote(targetItem, 'hiddenField')
  }

  async copyTextMessage(event) {
    event.preventDefault()
    event.stopPropagation();
    const message = document.querySelector(`#message_${event.currentTarget.dataset.messageId}`)
    const messageText = message.querySelector('.chat-text')
    if (messageText) {
      try {
        const originalContent = messageText.textContent; // Зберегти оригінальний контент
        const trimmedText = messageText.textContent.trim();
        await navigator.clipboard.writeText(trimmedText);
        console.log('Текст скопійовано');
        message.classList.add('bg-yellow-gradient');
        messageText.textContent = 'Скопійовано';
        setTimeout(() => {
          messageText.textContent = originalContent;
          message.classList.remove('bg-yellow-gradient');
        }, 1200); // Після 1.2 секунд повернути оригінальний вміст
      } catch (err) {
        console.error('Помилка під час копіювання тексту', err);
      }
    }
    this.closeContextMenu()
  }

  calculateContextMenuPosition(event, parentElement, contextMenuElement) {
    // Получаем координаты клика относительно родительского элемента
    const clickX = event.clientX - parentElement.getBoundingClientRect().left;
    const clickY = event.clientY + 10 - parentElement.getBoundingClientRect().top;

    // Получаем высоту родительского элемента
    const parentHeight = parentElement.clientHeight;

    // Вычисляем доступное место снизу и сверху от позиции клика
    const spaceBelow = parentHeight - clickY;
    const spaceAbove = clickY;

    // Получаем высоту и ширину контекстного меню
    const contextMenuHeight = contextMenuElement.clientHeight;
    const contextMenuWidth = contextMenuElement.clientWidth;

    let positionTop, positionLeft;

    // Определяем, достаточно ли места для контекстного меню снизу. Если нет, то располагаем сверху.
    if (spaceBelow >= contextMenuHeight) {
      positionTop = clickY;
    } else if (spaceAbove >= contextMenuHeight) {
      positionTop = clickY - contextMenuHeight;
    } else {
      // Недостаточно места сверху и снизу, выбираем позицию с большим доступным местом
      positionTop = spaceBelow > spaceAbove ? clickY : clickY - contextMenuHeight;
    }

    // Корректируем позицию относительно прокрутки родительского элемента
    // positionTop += parentElement.scrollTop;

    // Проверяем, чтобы меню не выходило за границы родительского элемента по высоте
    if (positionTop + contextMenuHeight > parentHeight) {
      positionTop = parentHeight - contextMenuHeight;
    }

    positionLeft = clickX;

    // Проверяем, чтобы меню не выходило за границы родительского элемента по ширине
    if (positionLeft + contextMenuWidth > parentElement.clientWidth) {
      positionLeft = parentElement.clientWidth - contextMenuWidth;
    }

    return { top: positionTop, left: positionLeft };
  }

  resetForm(event) {
    event.preventDefault()
    const chatId = event.currentTarget.dataset.chatId
    document.querySelector('#message_form').src = `/chats/${chatId}/messages`
  }

  scrollToBottom() {
    const chatContainerScroll = document.querySelector('.chatContainerScroll ')
    if (!chatContainerScroll) return;

    chatContainerScroll.scrollTop = chatContainerScroll.scrollHeight;
  }

  get isMobile() {
    return window.innerWidth < this.breakpointValue
  }

  quoteSectionTargetConnected(element) {
    if (element.closest('form').id === 'form_message') return;

    const action = element.closest('form').action;
    const match = action.match(/\/messages\/(\d+)/);
    if (!match) return;
    
    const id = match[1];
    const targetItem = document.querySelector(`#message_${id}`)
    
    if (!targetItem) return;
    
    this.perform_quote(targetItem)
  }
}
