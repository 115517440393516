import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'container',
    'template' ]
  
  addCityInput(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/new_/g, new Date().getTime())
    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }

  removeCityInput(event) {
    event.preventDefault()
    const inputId = event.currentTarget.dataset.inputTarget
    const wrapperInput = document.getElementById(inputId)
    const wrapper = wrapperInput?.closest('.city-fields')
    const inputLength = document.querySelectorAll('.service_city_ids').length
    if (inputLength < 2) {
      const t = I18n.services.form.city_input
      toaster("error", t.error, t.least_one_city);
      console.log('You need at least one city')
      return
    }

    if (wrapper) wrapper.remove()
    event.currentTarget.remove()
  }
}