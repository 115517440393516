import { Controller } from '@hotwired/stimulus'
import { Modal } from "bootstrap"

// 'deactivate-modal'
export default class extends Controller {
  static targets = ['modal', 'formAction', 'token', 'option', 'input', 'submit']
  static values = { 
    needReload: { type: Boolean, default: false }
  }
  
  connect() {
    if (!this.hasModalTarget) return

    this.modal = new Modal(this.modalTarget)
  }

  resetModalInputs() {
    this.optionTargets.forEach(el => {
      el.classList.remove('active')
    })
    this.inputTarget.value = ''
    this.submitTarget.disabled = true
  }

  openModal(event) {
    event.preventDefault()
    this.resetModalInputs()
    const url = event.currentTarget.dataset.deactivateUrl 
    this.formActionTarget.action = url
    this.tokenTarget.value = this.authToken
    this.modal.show()
  }

  submitEnd(event) {
    if (event.detail.success && event.detail.formSubmission.result.success) {
      this.formActionTarget.action = ''
      this.disconnect()
      if (this.needReloadValue) window.location.reload()
    }
  }

  disconnect() {
    this.modal.hide()
  }

  get authToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}