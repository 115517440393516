
function isMobile() {
  return window.getComputedStyle(document.body, ':before').content.replace(/"/g, '') == 'mobile' // sm
}

function isDesktop() {
  return window.getComputedStyle(document.body, ':before').content.replace(/"/g, '') == 'desktop' // md
}

function isDesktopLg() {
  return window.getComputedStyle(document.body, ':before').content.replace(/"/g, '') == 'desktop-lg' // lg
}

export { isMobile, isDesktop, isDesktopLg }
