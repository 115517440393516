import { Controller } from '@hotwired/stimulus'
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    'phoneInput',
    'resendBtn', 
    'submitBtn'
  ]

  static values = {
    url: String,
    remain: { type: Number, default: 60 } 
  }

  updateCouter() {
    var resendBtn = document.querySelector('#resend-btn')
    resendBtn.removeAttribute('data-controller'); // -> disconnect() controller
    setTimeout(() => {
      if (this.hasActivateButtonOutlet) this.activateButtonOutlet.startCountDown()
      resendBtn.setAttribute('data-controller', 'activate-button') // -> connect() controller
    }, 300); 
  }

  activateSubmitBtn() {
    const submitBtn = this.submitBtnTarget
    const disabledClass = submitBtn.dataset.activateButtonInactiveClass
    submitBtn.classList.remove(disabledClass);
    submitBtn.removeAttribute('data-activate-button-remain-time-value'); // clear timer
  }

  updateStatusResendBtn(remain) {
    const resendBtn = this.resendBtnTarget
    const remainTime = remain || this.remainValue
    resendBtn.setAttribute('data-activate-button-remain-time-value', remainTime); // set timer
  }

  async resendCode(event) {    
    event.preventDefault();
    event.stopPropagation();

    const phone = this.phoneInputTarget.value
    
    const data = {
      password_reset: {
        phone: phone,
        wizard: true // для wizard-у, але нормально працює і у звичайному режимі 
      }
    }
    
    try {
      const response = await post(this.urlValue, {
        body: data,
        responseKind: "json"
      })

      if (response.ok) {
        const responseData = await response.json

        if (responseData.status === 'success') {
          this.activateSubmitBtn()
          this.updateStatusResendBtn(responseData.remain)
          toaster("success", I18n.flash.title.notice, responseData.message);
        } else {
          if (responseData.reason === 'locked') {
            this.updateStatusResendBtn(responseData.remain)
            toaster("alert", I18n.flash.title.error, responseData.message);
          } else if (responseData.reason === 'already_send') {
            this.updateStatusResendBtn(responseData.remain)
            toaster("alert", I18n.flash.title.error, responseData.message);
          } else {
            console.error("Unexpected error type:", responseData);
            toaster('error', I18n.flash.title.error, "An unexpected error occurred during resend code.");
          } 
        } 
      } else {
        throw new Error(`${response.status} - ${response.statusText}`)
      }
    } catch (error) {
      console.error("Resend error:", error);
    } finally {
      console.log("Resend request finished.");
      this.updateCouter();
    }
  }
}
