import { Controller } from "@hotwired/stimulus";
import { put } from '@rails/request.js'
import Cropper from "cropperjs";

export default class extends Controller {

  static targets = [
    'previewImage',
    'avatarPreviewField',
    'fileInput',
    'avatarCollapse',
    'avatarImageData'
  ]

  static values = {
    url: String
  }

  connect() {
    this.initializeCropper();
  }

  initializeCropper() {
    if (this.cropper) this.cropper.destroy();
    this.previewImageTarget.src = this.avatarPreviewFieldTarget.src;
    this.previewImageTarget.crossOrigin = window.location.origin;
    this.cropper = new Cropper(this.previewImageTarget, {
      aspectRatio: 1,
      viewMode: 1,
      autoCropArea: 1,
      movable: true,
      zoomable: true,
      zoomOnTouch: false,
      cropBoxResizable: true,
    });
  }

  cropedAvatar() {
    this.initializeCropper();
  }

  applyCroppedImage(event) {
    event.preventDefault();
    event.stopPropagation();

    const croppedCanvas = this.cropper.getCroppedCanvas();
    const croppedImageData = croppedCanvas.toDataURL("image/jpeg");
    this.updateAvatar(croppedImageData);

    const collapse = new bootstrap.Collapse(this.avatarCollapseTarget);
    collapse.hide();
  }

  async updateAvatar(imageData) {
    const avatarObject = { user: { avatar: imageData } };

    const response = await put(this.urlValue, {
      body: JSON.stringify(avatarObject),
      responseKind: 'json'
    })
    if (response.ok) {
      const responseData = await response.json;
      if (responseData.status !== 'error') {
        this.avatarPreviewFieldTarget.src = responseData.avatarUrl;
        this.fileInputTarget.value = '';
        toaster("success", I18n.profile.show.change_avatar.success.title, [I18n.profile.show.change_avatar.success.message]); 
      } else {
        toaster("error", I18n.profile.show.change_avatar.errors.title, [I18n.profile.show.change_avatar.errors.message]);
        console.error('Error data:', responseData.errorData);
      }
    }
  }

  cancelCroppedImage() {
    this.cropper.reset();
  }

  disconnect() {
    this.cropper.destroy();
  }
}
