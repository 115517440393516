import { Controller } from '@hotwired/stimulus'

// auth-modal - контролер для модального вікна авторизації
export default class extends Controller {
  static values = {
    frameClose: String,
    frameOpen: String
  }
  
  performTwoModal(event) {
    event.preventDefault()
    var closeFrame = document.getElementById(this.frameCloseValue)    
    var modalEl = closeFrame.querySelector('.modal')
    var closedModal = bootstrap.Modal.getInstance(modalEl)
    if (!closedModal) return
    
    closedModal.hide()
    closeFrame.src = ''
    var link = document.createElement('a')
    link.href = event.currentTarget.dataset.src
    link.setAttribute('data-turbo-frame', this.frameOpenValue)
    this.element.appendChild(link)
    link.click()
  }
}