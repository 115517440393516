import { Controller } from '@hotwired/stimulus'

export default class extends Controller {    
  connect() {
    const toast = bootstrap.Toast.getOrCreateInstance(this.element, {
      autohide: true,
      animation: true,
      delay: 5000
    })
    toast.show()
  }
}