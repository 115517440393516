import { Controller } from '@hotwired/stimulus'
import TomSelect from "tom-select";

export default class extends Controller {

  config = {
    loadThrottle: 300,
    valueField: 'value',
    placeholder: I18n.services.index.age.placeholder,
    sortField: (a, b) =>  { +a.id - +b.id; }
  }

  connect() {
    this.destroyTomSelect()
    this.select = new TomSelect(this.element, this.config)
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  disconnect() {
    this.destroyTomSelect()
  }
}
