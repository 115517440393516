import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'minPrice', 
                     'maxPrice',
                     'minVal', 
                     'maxVal',
                     'priceRange'
                   ]
  static values = {
    priceGap: String
  }

  connect() {
    this.init()
  }
  
  isNeedToCorrectLeft(minVal, maxVal, currentMinPriceMin) {
    const oneСondition   = minVal === maxVal 
    const twoCondition   = minVal === 0 && maxVal === 50
    const threeCondition = minVal === currentMinPriceMin
    
    return oneСondition || twoCondition || threeCondition
  }

  isNeedToCorrectRight(minVal, maxVal) {
    return minVal === maxVal
  }

  init() {
    const priceRange = this.hasPriceRangeTarget && this.priceRangeTarget || document.querySelector(".slider .progress")
    const minVal     = parseInt(this.minValTarget.value)
    const maxVal     = parseInt(this.maxValTarget.value)
    const deltaMin   = parseInt(this.minPriceTarget.min)
    this.minPriceTarget.value = minVal;
    this.maxPriceTarget.value = maxVal;
    const currentMinPriceMin  = parseInt(this.minPriceTarget.min)
    priceRange.style.left  = this.isNeedToCorrectLeft(minVal, maxVal, currentMinPriceMin) ? 5 + "%" : (((minVal - deltaMin) / (this.minValTarget.max - deltaMin)) * 100) + "%"      // fixed o----o if minVal === maxVal
    priceRange.style.right = this.isNeedToCorrectRight(minVal, maxVal) ? 5 + "%" : 100 - ((maxVal - deltaMin) / (this.maxValTarget.max - deltaMin)) * 100 + "%" // fixed o----o if minVal === maxVal
  }
  
  resetPriceInput(event) {
    const priceRange = this.hasPriceRangeTarget && this.priceRangeTarget
    if (event.target.classList.contains('input-min')) {
      this.minPriceTarget.value = this.minPriceTarget.min
      this.minValTarget.value = this.minPriceTarget.min
      priceRange.style.left = 5 + "%";
    } else {
      this.maxPriceTarget.value = this.maxPriceTarget.max
      this.maxValTarget.value = this.maxPriceTarget.max
      priceRange.style.right = 5 + "%";
    }
  }
  
  setInputWithKey(event) {
    event.preventDefault()
    event.stopPropagation()
    if (event.key === 'Enter') {  
      this.changePriceInput(event)
    }
    if (event.key === 'Escape') { 
      this.resetPriceInput(event)
    }
  }

  changePriceInput(event){
    const priceRange  = this.hasPriceRangeTarget && this.priceRangeTarget || document.querySelector(".slider .progress")
    const minPrice    = parseInt(this.minPriceTarget.value)
    const minPriceMin = parseInt(this.minPriceTarget.min)
    const maxPrice   = parseInt(this.maxPriceTarget.value)
    const priceGap   = parseInt(this.priceGapValue)
    
    if ((maxPrice - minPrice >= priceGap) && maxPrice <= this.maxValTarget.max) {
      if (event.target.classList.contains("input-min")) {
        this.minValTarget.value = minPrice;
        priceRange.style.left = ((minPrice / this.minValTarget.max) * 100) + "%";
      } else {
        this.maxValTarget.value = maxPrice;
        priceRange.style.right = 100 - (maxPrice / this.maxValTarget.max) * 100 + "%";
      }
    }
  }

  changeRangeInput(event) {
    const priceRange = this.hasPriceRangeTarget && this.priceRangeTarget || document.querySelector(".slider .progress")
    const minVal     = parseInt(this.minValTarget.value) 
    const maxVal     = parseInt(this.maxValTarget.value)
    const deltaMin   = parseInt(this.minPriceTarget.min)
    const priceGap   = parseInt(this.priceGapValue)
    
    if ((maxVal - minVal) < priceGap) {
      if (event.target.classList.contains("range-min")) {
        this.minValTarget.value = maxVal - priceGap
      } else {
        this.maxValTarget.value = minVal + priceGap
      }
    } else {
      this.minPriceTarget.value = minVal;
      this.maxPriceTarget.value = maxVal;
      priceRange.style.left = (((minVal - deltaMin) / (this.minValTarget.max - deltaMin)) * 100) + "%";
      priceRange.style.right = 100 - ((maxVal - deltaMin) / (this.maxValTarget.max - deltaMin)) * 100 + "%";
    }
  }
}