import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  
  static targets = [
    'button',
    'arrow'
  ];
  
  static values = {
    hidden: { type: String, default: 'true' }, // кнопка прихована за замовчуванням
    useArrow: { type: String, default: 'true' } // arrow useed за замовчуванням
  }

  static classes = [
    'hidden', // клас, який мʼяко приховує кнопку (visibility: hidden)
    'none',   // клас, який приховує кнопку (display: none)
    'rotate' // клас, який повертає стрілку
  ];

  connect() {
    if (this.hiddenValue == 'true') {
      this.element.classList.add(this.hiddenClass)
    }
  }

  toggleHidden() {
    if (this.element.classList.contains(this.hiddenClass)) {
      this.element.classList.remove(this.hiddenClass)
    } else {
      this.element.classList.add(this.hiddenClass)
      if (this.useArrowValue == 'true') {
        this.arrowTarget.classList.add(this.rotate180Class)
      }
    }
  }
}