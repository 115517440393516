import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { FILTER } from './_lib/constants.js'
import { createValidPath, set_full_path, hrefRafctoring, paramsMakerIdsAppend, paramsMakerSlugsAppend } from './_lib/parseUrl.js'

export default class extends Controller {
  static values = {
    main: Object,
    filters: Object,
    requestValue: Boolean
  }

  loader         = document.querySelector("#turbo-loader-container")
  loaderBackdrop = document.querySelector("#turbo-loader-backdrop")
  controllerName = document.querySelector('body').dataset.currentController
  
  connect() {
    this.element[ (str => { return str
                              .split('--')
                              .slice(-1)[0]
                              .split(/[-_]/)
                              .map(w => w.replace(/./, m => m.toUpperCase()))
                              .join('')
                              .replace(/^\w/, c => c.toLowerCase())
                          }
                  )(this.identifier)
                ] = this // потрібно щоб виклиати функцї контроллера в інших Stimulus контроллерах
  }

  prepareBeforeResponce() {
    this.requestValue = true
    this.loader.classList.remove('d-none')
    this.loaderBackdrop.classList.remove('d-none')
  }

  clearAfterResponce() {
    this.requestValue = false
    this.loader.classList.add('d-none')
    this.loaderBackdrop.classList.add('d-none')
  }

  paramsStateAppend(stateChecked, seoParams) {
    if (stateChecked.length == 1) {
      const stateParams = FILTER.state + stateChecked[0]
      seoParams.push(stateParams)
    }
    return seoParams
  }
  
  paramsGenderAppend(genderChecked, seoParams) {
    if (genderChecked.length == 1) {
      const genderParams = FILTER.gender + genderChecked[0]
      seoParams.push(genderParams)
    }
    return seoParams
  }

  addSearchParams(mainFilters, nestedFilters) {
    let seoParams = []
    let goResetUrl = createValidPath() // new URL(createRootUrlWithLocale(controllerName))
    const searchQuery = mainFilters.search || document.querySelector('#search').value.trim()
    const hasMakersIds = mainFilters.makers_ids && mainFilters.makers_ids.length !== 0
    const hasMakersSlugs = mainFilters.makers_slugs && mainFilters.makers_slugs.length !== 0
    const hasStates = nestedFilters.state && nestedFilters.state.length !== 0
    const hasGenders = nestedFilters.state && nestedFilters.gender.length !== 0
    if (mainFilters.type) seoParams.push(FILTER.type + mainFilters.type)
    if (searchQuery) seoParams.push(FILTER.search + searchQuery)
    if (mainFilters.category_id && this.controllerName != 'categories') seoParams.push(FILTER.category + mainFilters.category_id)
    if (mainFilters.category_slug && this.controllerName != 'categories') seoParams.push(FILTER.categorySlug + mainFilters.category_slug)
    if (hasMakersIds && this.controllerName != 'makers') paramsMakerIdsAppend(mainFilters.makers_ids, seoParams)
    if (hasMakersSlugs && this.controllerName != 'makers') paramsMakerSlugsAppend(mainFilters.makers_slugs, seoParams)
    if (hasStates) this.paramsStateAppend(nestedFilters.state, seoParams)
    if (hasGenders) this.paramsGenderAppend(nestedFilters.gender, seoParams)
    if (nestedFilters.minimum_price) seoParams.push(FILTER.minPrice + nestedFilters.minimum_price)
    if (nestedFilters.maximum_price) seoParams.push(FILTER.maxPrice + nestedFilters.maximum_price)
    if (mainFilters.sort_name) seoParams.push(FILTER.sort + mainFilters.sort_name)
    goResetUrl = set_full_path(goResetUrl, seoParams)
    return goResetUrl
  }

  parseMainParams() {
    return JSON.parse(this.element.dataset.clearFiltersListMainValue)
  }

  parseFiltersParams() {
    return JSON.parse(this.element.dataset.clearFiltersListFiltersValue)
  }

  async resetAllFilters(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) { return }

    this.prepareBeforeResponce()
    const mainFilters = this.parseMainParams()
    const seoParams = []
    let goResetUrl = createValidPath() // new URL(createRootUrlWithLocale(this.controllerName))
    const searchQuery = mainFilters.search || document.querySelector('#search').value.trim()
    if (mainFilters.type) seoParams.push(FILTER.type + mainFilters.type)
    if (searchQuery) seoParams.push(FILTER.search + searchQuery) 
    seoParams.push(FILTER.sort + 'news')
    goResetUrl = goResetUrl + '/' + seoParams.join('/')
    const response = await get(goResetUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goResetUrl = hrefRafctoring(goResetUrl)
      history.replaceState(history.state, "", goResetUrl)
    }
  }

  async resetPriceFilters(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return
    
    this.prepareBeforeResponce()
    const mainFilters = this.parseMainParams()
    const nestedFilters = this.parseFiltersParams()
    delete(nestedFilters.minimum_price)
    delete(nestedFilters.maximum_price)
    let goResetUrl = this.addSearchParams(mainFilters, nestedFilters)
    const response = await get(goResetUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goResetUrl = hrefRafctoring(goResetUrl)
      history.replaceState(history.state, "", goResetUrl)
    }
  }

  async resetStateFilters(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const stateValue = event.target.closest('a').dataset.value
    const mainFilters = this.parseMainParams()
    const nestedFilters = this.parseFiltersParams()
    nestedFilters.state = Array.from(nestedFilters.state.filter(item => item !== stateValue))
    let goResetUrl = this.addSearchParams(mainFilters, nestedFilters)
    const response = await get(goResetUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goResetUrl = hrefRafctoring(goResetUrl)
      history.replaceState(history.state, "", goResetUrl)
    }
  }

  async resetGenderFilters(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const genderValue = event.target.closest('a').dataset.value
    const mainFilters = this.parseMainParams()
    const nestedFilters = this.parseFiltersParams()
    nestedFilters.gender = Array.from(nestedFilters.gender.filter(item => item !== genderValue))
    let goResetUrl = this.addSearchParams(mainFilters, nestedFilters)
    const response = await get(goResetUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goResetUrl = hrefRafctoring(goResetUrl)
      history.replaceState(history.state, "", goResetUrl)
    }
  }

  async resetCategoryFilters(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const mainFilters = this.parseMainParams()
    const nestedFilters = this.parseFiltersParams()
    delete(mainFilters.category_id)
    let goResetUrl = this.addSearchParams(mainFilters, nestedFilters)
    const response = await get(goResetUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goResetUrl = hrefRafctoring(goResetUrl)
      history.replaceState(history.state, "", goResetUrl)
    }
  }

  async resetMakerFilters(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const makerId = event.target.closest('a').dataset.makerId
    const mainFilters = this.parseMainParams()
    const nestedFilters = this.parseFiltersParams()
    mainFilters.makers_ids = mainFilters.makers_ids.filter(item => item !== makerId)
    let goResetUrl = this.addSearchParams(mainFilters, nestedFilters)
    const response = await get(goResetUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goResetUrl = hrefRafctoring(goResetUrl)
      history.replaceState(history.state, "", goResetUrl)
    }
  }
}
