const { create } = require("lodash");

function deletemBlobById(id, success, error) {
  ajax({
    type: 'DELETE',
    url: `/active_storage/${id}`,
    success: success,
    error: error
  })
}
window.deletemBlobById = deletemBlobById

function processThumbnailWrapper(wrapper, id, desiredFilename) {
  const filenameElement = wrapper.querySelector('.thumbnail-filename');
  if (filenameElement && filenameElement.textContent === desiredFilename) {
    wrapper.id = `direct-upload-${id}`;
    wrapper.classList.add("direct-upload", "direct-upload--pending");
    wrapper.appendChild(createProgressElement(id));
  }
}

function createProgressElement(id) {
  const progressElement = document.createElement('div');
  progressElement.id = `direct-upload-progress-${id}`;
  progressElement.classList.add("direct-upload__progress");
  progressElement.style.width = '0%';
  return progressElement;
}

function createPendingUploadElement(id, desiredFilename) {
  return `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      ${createProgressElement(id).outerHTML}
      <span class="direct-upload__filename">${desiredFilename}</span>
    </div>
  `;
}

function handleDirectUploadInitialize({ target, detail: { id, file } }) {
  const desiredFilename = file.name;
  const thumbnailWrappers = document.querySelectorAll('.preview-thumbnail-wrapper');

  if (thumbnailWrappers.length > 0) {
    thumbnailWrappers.forEach(wrapper => {
      processThumbnailWrapper(wrapper, id, desiredFilename);
    });
  } else {
    target.insertAdjacentHTML("beforebegin", createPendingUploadElement(id, desiredFilename));
  }
}

document.addEventListener("direct-upload:initialize", handleDirectUploadInitialize);

addEventListener("direct-upload:start", event => {
  const {id} = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove("direct-upload--pending")
});

addEventListener("direct-upload:progress", event => {
  const {id, progress} = event.detail;
  const progressElement = document.getElementById(`direct-upload-progress-${id}`);
  progressElement.style.width = `${progress}%`
});

addEventListener("direct-upload:error", event => {
  event.preventDefault();
  const {id, error} = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--error");
  element.setAttribute("title", error)
});

addEventListener("direct-upload:end", event => {
  const {id} = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--complete")
});
