import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ['signal']

  static values = { 
    turboFrame: { type: String, default: "_top" }, // target frame name
    selfUrl: { type: Boolean, default: true }, // reload self url
    otherUrl: { type: String, default: "/" }, // if reload outer url
  }

  removeSignal(element) {
    if (element) element.remove() // self destruct
  }

  signalTargetConnected(element) {
    const targetFrame = document.querySelector(`#${this.turboFrameValue}`)
    if (!targetFrame) {
      this.removeSignal(element)
      return
    }
    
    if (this.selfUrlValue) {
      const selfUrl = targetFrame.src
      targetFrame.src = ''
      targetFrame.src = selfUrl
    } else {
      targetFrame.src = ''
      targetFrame.src = this.otherUrlValue
    }

    this.removeSignal(element)
  }
}
