document.addEventListener('turbo:load', function() {
  const myTab = document.getElementById('myTab')
  if (myTab) {
    initMyTab(myTab);
    initTabChangeAchor();
  }
})

function initMyTab(myTab) {
  const selectTabId = window.location.hash.split("?")[0]
  let triggerTab = "a[href='#info']"
  if (selectTabId !== "") {
    triggerTab = `a[href='${selectTabId}']`
  }
  const achorTab = myTab.querySelector(triggerTab)

  new bootstrap.Tab(achorTab).show()
}

function initTabChangeAchor() {
  document.querySelectorAll('a[data-bs-toggle="tab"]').forEach((item) => {
    item.addEventListener("show.bs.tab", changeAnchor);
  })
}
