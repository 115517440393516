import { Controller } from '@hotwired/stimulus'
import { SEARCH } from './_lib/constants.js'
export default class extends Controller {
  static targets = [
    'searchModal', 
    'searchInput', 
    'closeButton',
    'closeLandingButton',
  ]


  connect() {
    const bodyContainer = document.querySelector('body')
    const backdrop = document.querySelector('.autosearch-backdrop')

    // remove backdrop & search list by click on Backdrop
    document.addEventListener('click', (event) => {
      if (event.target.classList.contains('autosearch-backdrop')) {
        this.closeSearchModal()
      }
    })

    if (this.hasSearchModalTarget) {
      bodyContainer.style.overflow = 'hidden';

      if ((!bodyContainer.classList.contains('modal-open'))) {
        bodyContainer.classList.add('modal-open')
      }

      if (this.element.dataset.targetName !== 'page_search' && backdrop && backdrop.classList.contains('d-none')) {
        backdrop.classList.remove('d-none')
      }
    }

    // disable form autosearch submit to preventDefault()
    document.querySelector('#search-form')?.addEventListener('submit', event => {
      event.preventDefault()
    })
  }

  disconnect() {
    this.closeSearchModal()
  }

  createValidType = (typesSearchValue) => {
    if (!typesSearchValue || !SEARCH.types.includes(typesSearchValue)) return 'all'
    
    return typesSearchValue
  }

  makeAndGoLink(searchesValue) {
    const goToUrl = new URL(createRootUrlWithLocale('search'))
    const searchParams = goToUrl.searchParams
    searchParams.delete("search")
    searchParams.delete("type")
    searchParams.append('search', searchesValue)
    const typesSearchValue = document.querySelector('#select-search-type-list').value.trim()
    searchParams.append('type', this.createValidType(typesSearchValue))
    return goToUrl
  }

  goToAdvancedSearch(event) {
    if (event.key !== 'Enter' || this.element.id !== 'search-form') return
    
    const inputSearch = this.hasSearchInputTarget ? this.searchInputTarget.value.trim() : ''
    if (inputSearch.length <= 0 || inputSearch.length > 50) return
    
    event.preventDefault()
    event.stopPropagation()
    
    Turbo.visit(this.makeAndGoLink(inputSearch))
  }

  addHideButtonClose() {
    if (this.hasSearchInputTarget && this.searchInputTarget.value.length == 0) {
      if (this.hasCloseButtonTarget && this.closeButtonTarget.classList.contains('active')) {
        this.closeButtonTarget.classList.remove('active')
      }
    } else if (this.hasSearchInputTarget && this.searchInputTarget.value.length > 0) {
      if (this.hasCloseButtonTarget && !this.closeButtonTarget.classList.contains('active')) {
        this.closeButtonTarget.classList.add('active')
      }
    }
  }

  // remove backdrop & search list by ESC
  closeWithKeyboard = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      event.stopPropagation()
      const closeBtn = document.querySelector('.input-cancel')
      if (closeBtn && closeBtn.classList.contains('active')) {
        closeBtn.classList.remove('active')
      }
      this.closeSearchModal()
    }
  }

  // clear input, remove backdrop & search list by click on X
  closeWithMouse = () => {
    const searchListModal = document.querySelector('.search-modal')
    const searchInputs = document.querySelectorAll('.se-search')
    searchInputs.forEach((input) => { input.value = '' })

    this.removeCloseButton()
    if (searchListModal) {
      searchListModal.remove()
    }
    this.clearBackDrop()
  }

  closeSearchModal = () => {
    if (this.hasSearchModalTarget && this.searchModalTarget) {
      this.searchModalTarget.remove()
    }

    this.clearBackDrop()
  }

  removeCloseButton = () => {
    if (this.hasCloseButtonTarget && this.closeButtonTarget.classList.contains('active')) {
      this.closeButtonTarget.classList.remove('active')
    }
  }

  clearBackDrop = () => {
    const bodyContainer = document.querySelector('body')
    const backdrop = document.querySelector('.autosearch-backdrop')

    if (bodyContainer.classList.contains('modal-open')) {
      bodyContainer.classList.remove('modal-open')
      bodyContainer.style.removeProperty("overflow")
    }

    if (backdrop && !backdrop.classList.contains('d-none')) {
      backdrop.classList.add('d-none')
    }
  }
}