import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'
import { Modal } from 'bootstrap'
import { showLoader, hideLoader } from 'controllers/_lib/loader/loaderLibrary';
import { isDesktopLg } from 'controllers/_lib/devices/devices';

export default class extends Controller {
  static targets = [
    'submitBtn',
    'fileInput',
    'chatContent',
    'lastMessage',
    'quoteSection',
    'chatVideoModal',
    'chatVideoModalBody',
    'chatVideoThumbnail',
    'chatContainerScroll']
  static values = {
    threshold: { type: Number, default: 0.5 } 
  }

  startX = null
  startY = null
  swipeThreshold = 100

  connect() {
    hideLoader()
    this.element.addEventListener('more-messages:loaded', () => {
      this.reinitVideoObserver()
    })
  }

  scrollToBottom() {
    const chatContainerScroll = document.querySelector('.chatContainerScroll ')
    if (!chatContainerScroll) return;

    chatContainerScroll.scrollTop = chatContainerScroll.scrollHeight;
  }

  reinitVideoObserver() {
    setTimeout(() => {
      this.initVideoObserver()
    }, 1000);
  }

  scrollWithReinit() {
    this.scrollToBottom()
    this.reinitVideoObserver()
  }

  chatContainerScrollTargetConnected() {
    this.scrollWithReinit()
  }

  showLoader() {
    showLoader()
  }

  hideLoader() {
    hideLoader()
  }

  initVideoObserver() {
    this.destroyVideoObserver();

    const videoThumbnails = this.chatVideoThumbnailTargets;
    const observerOptions = {
      threshold: this.thresholdValue || 0.5
    };

    this.videoObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.muted = true;
          entry.target.play();
        } else {
          entry.target.pause();
        }
      });
    }, observerOptions);

    videoThumbnails.forEach(thumbnail => {
      this.videoObserver.observe(thumbnail);
    });
  }

  destroyVideoObserver() {
    if (this.videoObserver) {
      this.videoObserver.disconnect();
      this.videoObserver = null;
    }
  }

  formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  playVideoInModal(event) {
    event.preventDefault()
    if (!this.hasChatVideoModalTarget) return;

    const videoUrl = event.currentTarget.getAttribute('src');
    const modalBody = this.chatVideoModalBodyTarget;
    modalBody.innerHTML = `<video controls autoplay class='w-100'>
                            <source src="${videoUrl}" type="video/mp4">
                          </video>`;
    this.modal = Modal.getOrCreateInstance(this.chatVideoModalTarget);
    this.modal.show();
  }

  startFileDownload(event) {
    const confirmMessage = event.currentTarget.getAttribute('data-message');

    if (!confirm(confirmMessage)) {
      event.preventDefault();
    }
  }

  closeModal(event) {
    event.preventDefault()
    if (!this.hasChatVideoModalTarget) return;
    
    this.chatVideoModalTarget.querySelector('video').pause();
    this.chatVideoModalBodyTarget.innerHTML = '';
    this.modal.hide();
  }

  addQuote(parentItem) {
    const template = parentItem.querySelector('template')
    const id = parentItem.dataset.quoteId
    if (!parentItem || !template || !id) {
      console.error('Parent item, template, or id not found.');
      return
    }

    const quoteSection = this.quoteSectionTarget
    if (this.hasQuoteSectionTarget) {
      quoteSection.innerHTML = template.innerHTML
      const form = quoteSection.closest('form')
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", id);
      hiddenField.name = 'message[quote_id]'
      form.insertAdjacentHTML('beforeend', hiddenField.outerHTML);
      this.scrollToBottom()
    }
  }

  quoted(event) {
    event.preventDefault()
    const parentItem = event.currentTarget.closest('li.message')
    if (!parentItem) return

    this.addQuote(parentItem)
  }

  cleanQuote(event) {
    event.preventDefault()
    const quoteSection = this.quoteSectionTarget
    if (this.hasQuoteSectionTarget) {
      quoteSection.innerHTML = ''
      const form = quoteSection.closest('form')
      const hiddenField = form.querySelector('input[name="message[quote_id]"]')
      if (hiddenField) hiddenField.remove()
    }
  }

  goToQuote(event) {
    event.preventDefault()
    const quoteMessage = document.getElementById(event.currentTarget.dataset.anchorId)
    if (!quoteMessage) return

    quoteMessage.scrollIntoView({ behavior: 'smooth' })
  }

  swipeQuoteStart(event) {
    this.startX = event.touches[0].clientX
    this.startY = event.touches[0].clientY
  }

  swipeQuoteEnd(event) {
    const endX = event.changedTouches[0].clientX
    const endY = event.changedTouches[0].clientY

    const diffX = this.startX - endX
    const diffY = this.startY - endY

    if (Math.abs(diffX) > Math.abs(diffY) && Math.abs(diffX) > this.swipeThreshold) {
      if (diffX !== 0) {
        const parentItem = event.currentTarget.closest('li.message')
        if (!parentItem) return

        this.addQuote(parentItem)
      }
    }
  }

  swipeChatStart(event) {
    this.startX = event.touches[0].clientX
    this.startY = event.touches[0].clientY
  }

  swipeChatEnd(event) {
    const endX = event.changedTouches[0].clientX
    const endY = event.changedTouches[0].clientY

    const diffX = this.startX - endX
    const diffY = this.startY - endY

    if (Math.abs(diffX) > Math.abs(diffY) && Math.abs(diffX) > this.swipeThreshold) {
      if (diffX !== 0) {
        const parentItem = event.target.closest('a.chat-list-item')
        if (!parentItem) return

        if (diffX > 0) {
          parentItem.style.transform = "translateX(-50%)";
          parentItem.closest('div').querySelector('div.btn-chat-action').style.transform = "translateX(0%)"
        } else if (diffX < 0) {
          parentItem.style.transform = "translateX(0%)";
          parentItem.closest('div').querySelector('div.btn-chat-action').style.transform = "translateX(100%)";
        }
      }
    }
  }

  clickOutsideHandler(event, parentItem) {
    if (!parentItem) return

    parentItem.style.transform = "translateX(0%)";
    parentItem.closest('div').querySelector('div.btn-chat-action').style.transform = "translateX(100%)";
  }

  openChatMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    const parentItem = event.currentTarget.closest('a.chat-list-item')
    if (!parentItem) return

    document.addEventListener("click", (event) => this.clickOutsideHandler(event, parentItem));
    
    parentItem.style.transform = "translateX(-50%)";
    parentItem.closest('div').querySelector('div.btn-chat-action').style.transform = "translateX(0%)"
  } 

  performAudioAction(player) {
    const parentItem = player.closest('.chat-audio-section')
    const iconAudio = parentItem.querySelector('.chat-audio-icon')
    const iconWave = parentItem.querySelector('.chat-wave-icon')

    player.addEventListener('ended', () => {
      iconAudio.classList.remove('opacity-75');
      iconWave.classList.add('d-none');
    });

    player.paused ? player.play() : player.pause();
    iconAudio.classList.toggle('opacity-75', !player.paused);
    iconWave.classList.toggle('d-none', player.paused);
  }

  toggleAudio(event) {
    event.preventDefault()
    const currentPlayer = event.currentTarget.closest('.chat-audio-section').querySelector('.chat-audio-player')

    if (!currentPlayer) return

    const allPlayers = document.querySelectorAll('.chat-audio-player');
    allPlayers.forEach(player => {
      if (player !== currentPlayer && !player.paused) this. performAudioAction(player)
    });
    this.performAudioAction(currentPlayer)
  }

  async showHiddenChats(event) {
    event.preventDefault();
  
    showLoader()
    const response = await get(this.urlIndexValue, {
      query: {
        status: 'trash',
        object_type: this.objectTypeValue,
        object_id: this.objectIdValue
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.hidePreloader();
    }
  }

  async performLink(event) {
    event.preventDefault();
    event.stopPropagation();
    const link = event.currentTarget.getAttribute('href');
    if (!link) return;
    
    showLoader()
    const query = {}
    const isTrashed = document.querySelector('.set-trash-btn').classList.contains('active')
    if (isTrashed) query['trash'] = 'true'

    const response = await patch(link, {
      query: query,
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      hideLoader();
    }
  }

  checkForSubmit(event) {
    const attachedFiles = this.fileInputTarget.files;
    if (event.currentTarget.value.trim() == '' && attachedFiles.length == 0) {
      this.submitBtnTarget.classList.add('d-none')
    } else {
      this.submitBtnTarget.classList.remove('d-none')
    }
  }

  performEnter(event) {
    if (!isDesktopLg()) return

    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();

      this.submitBtnTarget.click()
    }
  }

  submitBtnTargetConnected() {
    this.submitBtnTarget.classList.add('d-none')
  }

  lastMessageTargetConnected() {
    this.scrollWithReinit()
  }
}
