function delay(callback, ms) {
  let timer = 0;
  return function() {
    const context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      callback.apply(context, args);
    }, ms || 0);
  };
}
window.delay = delay;
