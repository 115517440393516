import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'
import { get } from '@rails/request.js'
import currentUserId from './_lib/user/currentUserId.js'
import { getHistory, addToHistory } from './_lib/history/addToHistory.js'
import { FILTER } from './_lib/constants.js'

export default class extends Controller {
  static targets = ['searchInput']
  static values = {
    url: String,
    moreUrl: String,
    history: String,
    searchParams: String,
    advancedSearchParams: String,
    keywordsParams: String,
    request: { type: Boolean, default: false }
  }

  loader         = document.querySelector("#turbo-loader-container")
  loaderBackdrop = document.querySelector("#turbo-loader-backdrop")

  connect() {
    this.searchWithDebounce = debounce(this.searchWithDebounce, 500)
    this.searchAdvanceWithDebounce = debounce(this.searchAdvanceWithDebounce, 500)

    document.querySelector('#search-form')?.addEventListener('submit', event => event.preventDefault())
    document.querySelector('#advanced-search-form')?.addEventListener('submit', event => event.preventDefault())
    document.querySelector('#filters')?.addEventListener('submit', event => event.preventDefault())
  }

  prepareBeforeResponce() {
    this.requestValue = true
    this.loader.classList.remove('d-none')
    this.loaderBackdrop.classList.remove('d-none')
  }

  clearAfterResponce() {
    this.requestValue = false
    this.loader.classList.add('d-none')
    this.loaderBackdrop.classList.add('d-none')
  }

  invalidate(query) {
    return !query || query.length === 0 || query.length > 50
  }

  addSearchParams(mainFilters, nestedFilters, pathname) {
    const autosearchUrl = createRootUrlWithLocale(pathname)
    const goAutosearchUrl = new URL(autosearchUrl)
    const searchParams = goAutosearchUrl.searchParams
    const searchQuery  = mainFilters.search || document.querySelector('#search').value.trim()
    if (searchQuery) searchParams.append(FILTER.search, searchQuery)
    if (mainFilters.type) searchParams.append(FILTER.type, mainFilters.type)
    if (mainFilters.sort_name) searchParams.append(FILTER.sort, mainFilters.sort_name)
    if (mainFilters.category_id) searchParams.append(FILTER.category, +mainFilters.category_id)
    if (nestedFilters.state) searchParams.append(FILTER.state, nestedFilters.state)
    if (nestedFilters.minimum_price) searchParams.append(FILTER.minPrice, nestedFilters.minimum_price)
    if (nestedFilters.maximum_price) searchParams.append(FILTER.maxPrice, nestedFilters.maximum_price)
    
    return goAutosearchUrl
  }

  // set disabled selected item menu in Filter Type
  setDisabled(el, filterValue) {
    Array.from(el.children).forEach(element => {
      if (element.value === filterValue) {
        element.setAttribute('disabled', true)
      } else {
        element.removeAttribute('disabled')
      }
    })
  }

  // search with debounce for autosearch
  async searchWithDebounce(event) {
    if (event.key === "Escape" || event.key === 'Enter') return // ESC or Enter мають інший функціонал

    const filterInput = document.querySelector('#select-search-type-list')
    const valueSearch = this.searchInputTarget?.value.trim()
    if (this.invalidate(valueSearch)) return

    if (!currentUserId()) addToHistory(valueSearch)
    await this.autoSearch(filterInput.value)
  }

  // debounce main search Fired!
  autoSearch = async (filterValue) => {
    if (this.requestValue) return
    
    this.requestValue = true
    const response = await get(this.urlValue.toString(), {
      query: {
        search: this.searchInputTarget.value,
        filter: filterValue
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) this.requestValue = false
  }
  
  // search with debounce for advancedsearch
  async searchAdvanceWithDebounce(event) {
    event.preventDefault()
    event.stopPropagation()
    if (event.key === "Escape" || event.key === 'Enter' || this.requestValue) return // prevent double search after keydown ESC or Enter

    const valueSearch = this.searchInputTarget.value.trim()
    if (!valueSearch || this.invalidate(valueSearch)) return
    
    this.prepareBeforeResponce()
    if (!currentUserId()) addToHistory(valueSearch)
    const mainFilters = JSON.parse(this.element.dataset.autosearchMainValue)
    const nestedFilters = {}
    delete(mainFilters.search)
    const goAutosearchUrl = this.addSearchParams(mainFilters, nestedFilters, 'autosearch')
    const response = await get(goAutosearchUrl, {
      query: {
        advanced_search: true,
        clear: 'server'
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) this.clearAfterResponce() 
  }

  // select filter type and immediatly search (autosearch only)
  selectTypeChange = async (event) => {
    const filterSearchValue = event.target.value
    const inputForm = document.querySelector('.se-search')
    const inputFormValue = inputForm.value.trim()
    const autosearchUrl = document.querySelector('#search-form').dataset['autosearchUrlValue']

    if (inputFormValue.length > 0) {
      await this.autoSearchWithFilter(inputFormValue, filterSearchValue, autosearchUrl)
    }

    this.setDisabled(event.target, filterSearchValue)
  }

  // select filter type and immediatly search (autosearch only) - Fired!
  autoSearchWithFilter = async (searchValue, filterValue, autosearchUrl) => {
    if (this.requestValue) return
    
    this.requestValue = true
    const response = await get(autosearchUrl.toString(), {
      query: {
        search: searchValue,
        filter: filterValue
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) this.requestValue = false
  }

  // user history search
  userHistory = async (event) => {
    const urlUserHistory = event.target.dataset.autosearchHistoryValue
    const inputValue = event.target.value.trim()
    if (inputValue) return

    if (currentUserId()) {
      await this.getUserHistory(urlUserHistory) // for authenticate user
    } else {
      const localHistory = getHistory()    // for guest
      await this.getUserLocalHistory(urlUserHistory, localHistory)
    }
  }

  // user history search fired! (if current_user present)
  getUserHistory = async (url) => {
    await get(url.toString(), {
      responseKind: 'turbo-stream'
    })
  }

  // user history search fired! (guest only)
  getUserLocalHistory = async (url, localHistory) => {
    await get(url.toString(), {
      query: {
        local_history: localHistory
      },
      responseKind: 'turbo-stream'
    })
  }

  // Autosearch with Keywords
  searchWithKeywordsParams = async (event) => {
    event.preventDefault()
    if (this.requestValue) return

    this.requestValue = true
    const filterInput = document.querySelector('#select-search-type-list')
    const response = await get(this.moreUrlValue.toString(), {
      query: {
        search: this.searchParamsValue,
        keywords: this.keywordsParamsValue,
        filter: filterInput.value
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.requestValue = false
    }
  }

  // Autosearch from User history (after click on recent item)
  searchWithHistoryParams = async (event) => {
    event.preventDefault()
    if (this.requestValue) return

    this.requestValue = true
    const filterInput = document.querySelector('#select-search-type-list')
    const inputSearchForm = document.querySelector("#search")
    const inputCancel = document.querySelector('.input-cancel')
    const response = await get(this.urlValue.toString(), {
      query: {
        search: this.searchParamsValue,
        filter: filterInput.value
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.requestValue = false
      inputSearchForm.value = this.searchParamsValue
      inputCancel.classList.add('active')
    }
  }
}