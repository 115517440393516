import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import fadeIn from "./_lib/animate/fadeIn.js"
import fadeOut from "./_lib/animate/fadeOut.js"
import { FILTER } from './_lib/constants.js'
import { createValidPath, set_full_path, hrefRafctoring } from './_lib/parseUrl.js'

export default class extends Controller {
  static targets = ['categoryLink']

  static values = {
    complexParams: Object,
    requestValue: { type: Boolean, default: false }
  }

  controllerName = document.querySelector('body').dataset.currentController

  connect() {
  }

  prepareBeforeResponce() {
    this.requestValue = true
  }

  clearAfterResponce() {
    this.requestValue = false
  }

  addSearchParams(complexFilters) {
    let seoParams = []
    let goUrl = createValidPath() // setLocale() || createRootUrlWithLocale() || createRootUrlWithLocale('search')
    const searchQuery = complexFilters.search || document.querySelector('#search').value.trim()
    if (complexFilters.type) seoParams.push(FILTER.type + complexFilters.type)
    if (searchQuery) seoParams.push(FILTER.search + searchQuery)
    if (complexFilters.category_id && this.controllerName != 'categories') seoParams.push(FILTER.category + complexFilters.category_id)
    if (complexFilters.category_slug && this.controllerName != 'categories') seoParams.push(FILTER.categorySlug + complexFilters.category_slug)
    if (complexFilters.makerIds && this.controllerName != 'makers') seoParams.push(FILTER.makerIds + complexFilters.makerIds)
    if (complexFilters.makerSlugs && this.controllerName != 'makers') seoParams.push(FILTER.makerSlug + complexFilters.makerSlugs)
    if (complexFilters.state) seoParams.push(FILTER.state + complexFilters.state)
    if (complexFilters.minimum_price) seoParams.push(FILTER.minPrice + complexFilters.minimum_price)
    if (complexFilters.maximum_price) seoParams.push(FILTER.maxPrice + complexFilters.maximum_price)
    if (complexFilters.sort_name) seoParams.push(FILTER.sort + complexFilters.sort_name)
    goUrl = set_full_path(goUrl, seoParams)
    return goUrl
  }

  // parse type from selected tabs
  getTypeFromTabs() {
    const [link] = Array.from(document.querySelectorAll(".tabs-link")).filter(el => el.classList.contains('active'))
    return JSON.parse(link.dataset.tabType)
  }

  // Перехід в обрану категорію (Categories list in advanced search)
  async jumpToCategory(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const complexFilters = JSON.parse(event.target.dataset.categorySectionComplexParamsValue)
    if (complexFilters) delete(complexFilters.page)
    let goUrl = this.addSearchParams(complexFilters)
    const response = await get(goUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goUrl = hrefRafctoring(goUrl)
      history.replaceState(history.state, "", goUrl)
    }
  }
  
  // Show more categories in categories list (hided if count > 4)
  showMoreCategories(event) {
    event.preventDefault()
    event.stopPropagation()
    const btnShowMore = event.target
    const fragment = document.createDocumentFragment()
    fragment.appendChild(btnShowMore.parentElement.parentElement)
    const listCategories = document.querySelectorAll(`.root_${btnShowMore.dataset.rootid}_hided`)
    const lastHidedListEl = listCategories[listCategories.length - 1]
    const firstHidedListEl = listCategories[0]
    const moreText = btnShowMore.dataset.moretext
    const lessText = btnShowMore.dataset.lesstext
    listCategories.forEach(el => {
      if (el.classList.contains('hided')) {
        fadeIn(el, 500, 'hided')
      } else {
        fadeOut(el, 100, 'hided')
      }
    })

    if (btnShowMore && btnShowMore.innerText === lessText) {
      btnShowMore.innerText = moreText
      firstHidedListEl.parentElement.insertBefore(fragment, firstHidedListEl)
    } else {
      btnShowMore.innerText = lessText
      if (+btnShowMore.dataset.deeptree > 2) {
        lastHidedListEl.appendChild(fragment)
      } else {
        lastHidedListEl.parentElement.appendChild(fragment)
      }
    }
  }
}
