import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'citySelect',
    'onlineTypeSelect',
    'publicHiddenInput',
    'nameHiddenInput',
    'budgetHiddenInput',
    'publicNameWrapper',
    'alreadyHiddenAction',
    'publicBudgetWrapper',
    'alreadyPublicWrapper',
    'alreadyPublicNameInput',
    'alreadyPublicBudgetInput',
    'publicBudgetInput',
    'publicNameInput'
  ]
  
  static values = {
    url: String,
    budgetPositive: Boolean,
    turboFrame: { type: String, default: 'modal' }
  }

  connect() {
    this.onlineTypeSelect()
    this.initializePublicCheckbox()
  }

  onlineTypeSelect() {
    if (!this.hasOnlineTypeSelectTarget) return

    const onlineSelected = this.onlineTypeSelectTarget.value === "false"
    const citySelectWrapper = this.citySelectTarget.closest('.form-group.service_request_city_id')
    citySelectWrapper.classList.toggle('d-none', onlineSelected)

    if (onlineSelected) {
      this.citySelectTarget.removeAttribute("required")
    } else {
      this.citySelectTarget.setAttribute("required", "")
    }
  }

  performNameAttributes(checked) {
    if (!this.hasPublicNameInputTarget) return

    const publicNameInput = this.publicNameInputTarget
    this.publicNameWrapperTarget.classList.toggle('d-none', !checked)
    publicNameInput.disabled = !checked
    publicNameInput.setAttribute('required', checked)
    this.nameHiddenInputTarget.disabled = checked
  }

  performBudgetAttributes(checked) {
    if (!this.hasPublicBudgetInputTarget) return

    const publicBudgetInput = this.publicBudgetInputTarget
    const budgetBlank = !this.budgetPositiveValue
    const fullCondition = checked || budgetBlank
    this.publicBudgetWrapperTarget.classList.toggle('d-none', !fullCondition)
    publicBudgetInput.disabled = !fullCondition
    publicBudgetInput.setAttribute('required', fullCondition)
    if (this.hasBudgetHiddenInputTarget) this.budgetHiddenInputTarget.disabled = fullCondition
  }

  performPublicElements(checked) {
    this.performNameAttributes(checked)
    this.performBudgetAttributes(checked) 
  }

  initializePublicCheckbox() {
    const checked = this.publicHiddenInputTarget.value === 'true'
    this.performPublicElements(checked)
  } 

  togglePublicCheckbox(event) {
    const checked = event.target.checked
    this.performPublicElements(checked)
  }

  toggleAlreadyRadio(event) {
    const checked = event.target.value === 'true' // -> 'set_public'
    this.alreadyPublicWrapperTarget.classList.toggle('d-none', !checked)
    this.alreadyPublicNameInputTarget.disabled = !checked
    this.alreadyPublicBudgetInputTarget.disabled = !checked
    this.alreadyPublicNameInputTarget.setAttribute('required', checked)
    this.alreadyPublicBudgetInputTarget.setAttribute('required', checked)
    this.alreadyHiddenActionTarget.value = checked ? 'set_public' : 'add_invitation'
  }
}