import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'
import currentUserId from "./_lib/user/currentUserId.js"
import { addToRecentPages, clearRecentPages, clearRecentPage } from './_lib/recent/recentProductsPages.js'
export default class extends Controller {
  static targets = [ 'spliderItems', 'listRecent' ]

  connect() {
    document.addEventListener("turbo:before-fetch-request", function (event) {
      if (event.target.id.startsWith('recently_products_product_') && !currentUserId()) {
        const currentShowPageId = event.target.dataset.productId
        const searchParams = event.detail.url.searchParams
        searchParams.append("current_show_page_id", currentShowPageId)
        addToRecentPages(currentShowPageId, searchParams) // якщо guest - додаємо recent у LocalStrorage
      }
      event.detail.resume()   
    })
  }

  async clearAllRecents(event) {
    event.preventDefault()
    event.stopPropagation()

    const url = new URL (event.target.href)
    const searchParams = url.searchParams 
    spliderRecentsProducts.destroy() // destroy mounted slider (disactivated)
    this.listRecentTarget.remove()
    this.element.remove() // remove blank list
    if (!currentUserId()) {
      clearRecentPages()
      return
    }
    searchParams.append("clear_visit_pages", "server")
    await patch(url, {
      responseKind: 'turbo-stream'
    })
  }

  async clearOneRecent(event) {
    event.preventDefault()
    event.stopPropagation()
    const currentShowPageId = event.target.closest('turbo-frame').dataset.productId
    const targetElement     = event.target.closest('a')
    const deletedId         = targetElement.dataset.clearId
    const url               = new URL (targetElement.href)
    
    spliderRecentsProducts.remove(`#${targetElement.closest('.splide__slide').id}`) // delete splide card item
    
    if (!this.hasSpliderItemsTarget) {
      this.listRecentTarget.remove() // if splide items == 0 -> delete blank list items 
      this.element.remove()
    }
    
    if(!currentUserId()) { 
      clearRecentPage(deletedId, targetElement)
      return
    }

    const searchParams = url.searchParams
    searchParams.append("current_show_page_id", currentShowPageId)
    searchParams.append("clear_visit_pages", "server")
    searchParams.append("deleted_id", deletedId)
    await patch(url, {
      responseKind: 'turbo-stream'
    })
  }
}
