import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { showLoader, hideLoader } from 'controllers/_lib/loader/loaderLibrary';

export default class extends Controller {
  static values = {
    url: String,
    breakpoint: Number,
    isMobile: { type: Boolean, default: false },
    request: { type: Boolean, default: false }
  }

  query = {}

  getTypeFromTabs(class_name) {
    const [link] = Array.from(document.querySelectorAll(class_name)).filter(el => el.classList.contains('active'))
    return link.dataset.tabType
  }

  handleMobile() {
    if (this.isMobile) {
      this.query['mobile'] = true
    } else {
      delete this.query['mobile'];
    }
  }

  handleFilter() {
    const newFilter = this.getTypeFromTabs('.btn-chat-type');
    if (newFilter && newFilter !== 'all') {
      this.query['filter'] = newFilter
    } else {
      delete this.query['filter']
    }
  }

  handleState() { 
    const statusBtn = document.querySelector('.set-trash-btn')
    if (statusBtn.classList.contains('active')) {
      this.query['status'] = 'trash'
    } else {
      delete this.query['status']
    }
  }

  handleChatItem(activeChatId) {
    const chatListItems = document.querySelectorAll('a.chat-list-item');
    
    chatListItems.forEach((item) => {
      const isActive = item.dataset.id === activeChatId;
      item.classList.toggle('bg-yellow-gradient', isActive);
      item.classList.toggle('bg-white', !isActive);
    })
  }

  async setGetParams(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue || this.urlValue == '') return

    this.requestValue = true
    this.handleMobile()
    this.handleFilter()
    this.handleState()
    showLoader();
    const response = await get(this.urlValue, {
      query: this.query, 
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      const activeChatId = this.element.getAttribute('data-id')
      this.handleChatItem(activeChatId)
      this.requestValue = false
      hideLoader();
    }
  }

  get isMobile() {
    return window.innerWidth < this.breakpointValue
  }
}
