import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['option', 'otherTextInput', 'inputReason', 'inputBody',  'submit']

  selectReasonOption(event) {
    const selectedOption = event.target.dataset.value
    
    this.optionTargets.forEach(el => {
      el.classList.toggle('active', event.target == el)
    })

    this.inputReasonTarget.value = event.target.dataset.value
    this.submitTarget.disabled = false

    this.performOtherOptionLogic(selectedOption)
  }

  performOtherOptionLogic(selectedOption) {
    if (selectedOption === 'other') {
      this.otherTextInputTarget.classList.remove('d-none');
      this.otherTextInputTarget.addEventListener('input', () => {
        this.inputBodyTarget.value = this.otherTextInputTarget.value;
      });
    } else {
      this.otherTextInputTarget.classList.add('d-none');
      this.otherTextInputTarget.value = '';
      this.inputBodyTarget.value = '';
    }
  }
}