import rater from "rater-js";

document.addEventListener("turbo:load", () => {
  initRater();
})

function initRater() {
  document.querySelectorAll(".rating-js").forEach((item) => {
    const input = item.querySelector("input")
    rater({
      element: item,
      rating:  parseInt(input.value),
      rateCallback: function rateCallback(rating, done) {
        // const input = this.querySelector("input")
        this.setRating(rating);
        input.value = parseInt(rating);
        done();
      }
    })
  });
};
