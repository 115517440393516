import Rails from "@rails/ujs"

async function ajax(request) {
  let url = request.url;
  const fetchRequest = {
    method: request.type,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    credentials: 'same-origin'
  };

  if (request.type.toLowerCase() === "get" && request.data) {
    url = `${url}?${encodeQueryData(request.data)}`
  }
  if (request.type.toLowerCase() !== "get") {
    fetchRequest.body = JSON.stringify(request.data)
  }

  return fetch(url, fetchRequest).then(response => response.json()).then(data => {
    return request.success(data)
  }).catch(error => {
    console.log(error);
    return request.error(error)
  });
}

window.ajax = ajax

function encodeQueryData(data) {
  const ret = [];
  for (let d in data) {
    if (typeof data[d] === 'object' || typeof data[d] === 'array') {
      for (let arrD in data[d]) {
        ret.push(`${encodeURIComponent(d)}[]=${encodeURIComponent(data[d][arrD])}`)
      }
    } else if (typeof data[d] === 'null' || typeof data[d] === 'undefined') {
      ret.push(encodeURIComponent(d))
    } else {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`)
    }

  }
  return ret.join('&');
}
