import { DirectUpload } from "@rails/activestorage"

function imagesPreview() {
  const input = typeof this !== 'undefined' || this !== null ? this.closest("input") : event.target
  const form = typeof this !== 'undefined' || this !== null ? input.closest("form") : event.target.closest("form")

  let placeToInsertImagePreview = form.querySelector("#images_preview")

  if (placeToInsertImagePreview == null) {
    placeToInsertImagePreview = document.createElement("div")
    placeToInsertImagePreview.setAttribute("id", "images_preview")

    input.parentElement.parentElement.after(placeToInsertImagePreview)
  }

  const uploadFile = (file) => {
    // your form needs the file_field direct_upload: true, which
    //  provides data-direct-upload-url
    const url = input.dataset.directUploadUrl

    const upload = new DirectUpload(file, url)

    upload.create((error, blob) => {
      if (error) {
        console.log("error?", error);
        // Handle the error
      } else {
        const reader = new FileReader();

        reader.onload = function(event) {
          const item = document.createElement("div")
          item.setAttribute("class", "col-12 col-md-6 item")

          const img = document.createElement("img")
          img.setAttribute("class", "img-thumbnail")
          img.setAttribute("data-id", blob.id)
          img.setAttribute("src", event.target.result)
          img.setAttribute("onclick", "selectBaseImage(this)")

          item.appendChild(img)

          const close = document.createElement("button")
          close.setAttribute("class", "close")
          close.setAttribute("data-id", blob.id)
          close.setAttribute("onclick", "deleteASImage(this)")
          close.innerHTML = '<span aria-hidden="true">&times;</span>'

          item.appendChild(close)

          const hiddenField = document.createElement('input')
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.name = input.name

          item.appendChild(hiddenField)

          // if need add first in previews but save in end
          placeToInsertImagePreview.appendChild(item)
        }

        reader.readAsDataURL(file);
      }
    })
  }

  if (input.files) {
    Array.from(input.files).forEach(file => uploadFile(file))

    input.value = null;
  }
};
window.imagesPreview = imagesPreview;


function selectBaseImage(object, field = 'base_image') {
  const images_preview = object.closest("#images_preview")

  Array.from(images_preview.querySelectorAll("input.base_image")).forEach((input) => {
    input.remove()
  })

  Array.from(images_preview.querySelectorAll(".item.select")).forEach((img) => {
    img.classList.remove("select")
  })

  const form = object.closest("form")
  const item = object.closest(".item")

  item.classList.add("select")

  const newInput = document.createElement('input')
  newInput.setAttribute("class", field)
  newInput.setAttribute("type", "hidden");
  newInput.setAttribute("value", object.getAttribute('data-id'));
  newInput.name = form.querySelector("input.images").name.replace("[images][]", `[${field}]`)

  item.appendChild(newInput)
}
window.selectBaseImage = selectBaseImage;

function deleteASImage(object) {
  event.preventDefault();

  const item = object.closest(".item")
  const id = object.getAttribute('data-id')

  let confirmDelete = confirm("Are you sure to delete this photo");

  if (confirmDelete) {
    if (!id) {
      return alert('Something went wrong')
    } else {
      deletemBlobById(id, function(data) {
        if (data.valid) {
          item.remove()
        }
      }, null)
    }
  }
}
window.deleteASImage = deleteASImage;

function imagePreview(input) {
  let placeToInsertImagePreview = document.getElementById("#image_preview")

  if (placeToInsertImagePreview == null) {
    placeToInsertImagePreview = document.createElement("div")
    placeToInsertImagePreview.setAttribute("id", "image_preview")
    placeToInsertImagePreview.setAttribute("class", "w-100 d-flex justify-content-center overflow-hidden align-items-center rounded-circle")

    input.parentElement.parentElement.after(placeToInsertImagePreview)
  }
  if (input.files) {
    placeToInsertImagePreview.innerHTML = null;
    const filesAmount = input.files.length;

    for (let i = 0; i < filesAmount; i++) {
      const reader = new FileReader();

      reader.onload = function(event) {
        const img = document.createElement("img")
        img.className = "img-fluid rounded-circle"
        img.style.objectFit = "cover"
        img.src = event.target.result

        const containerWidth = placeToInsertImagePreview.offsetWidth;
        placeToInsertImagePreview.style.height = `${containerWidth}px`;
        placeToInsertImagePreview.appendChild(img)
      }

      reader.readAsDataURL(input.files[i]);
    }
  }
};
window.imagePreview = imagePreview;
