import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {

  connect() {}

  searchTypeValue(datasetElement) {
    return datasetElement.startsWith('["') ? JSON.parse(datasetElement) : datasetElement
  }

  async getMorePage(event) {
    event.preventDefault()
    event.stopPropagation()
    const searchesTypeValue = this.searchTypeValue(this.element.dataset.type)
    const response = await get(this.element.href, {
      query: {
        endless: searchesTypeValue
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      history.replaceState(history.state, "", this.element.href)
    }
  }
}