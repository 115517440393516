import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ['signal']

  static values = { 
    clearCache: { type: Boolean, default: false },
    url: { type: String, default: "/" },
    showMessage: { type: Boolean, default: false },
    message: { type: String, default: '' },
    turboFrame: { type: String, default: "_top" }
  }

  clearCache() {
    if (!this.clearCacheValue) return

    Turbo.clearCache() // window.location.reload(true)
  }

  signalTargetConnected(element) {
    this.clearCache()
    Turbo.visit(this.urlValue, { turbo_frame: this.turboFrameValue }) // window.location.href = this.urlValue
    if (element) element.remove() // self destruct if element is present
  }

  showMessage() {
    const message = this.hasMessageValue ? this.messageValue : 'Redirecting...'
    toaster("success", I18n.profile.show.attention, [message])
  }

  redirect() {
    if (this.clearCacheValue) this.clearCache()

    if (this.showMessageValue) {
      this.showMessage()
      setTimeout(() => {
        Turbo.visit(this.urlValue, { turbo_frame: this.turboFrameValue })
      }, 3000)
    } else {
      if (this.showMessageValue) this.showMessage()
      Turbo.visit(this.urlValue, { turbo_frame: this.turboFrameValue })
    }
  }
}
