import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  
  static values = {
    requestValue: { type: Boolean, default: false }
  }
  
  loader = document.querySelector("#turbo-loader-container")
  loaderBackdrop = document.querySelector("#turbo-loader-backdrop")
  
  prepareBeforeResponce() {
    this.requestValue = true
    this.loader.classList.remove('d-none')
    this.loaderBackdrop.classList.remove('d-none')
  }

  clearAfterResponce() {
    this.requestValue = false
    this.loader.classList.add('d-none')
    this.loaderBackdrop.classList.add('d-none')
  }

  async renderTab(event) {
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const pathLink = event.target.dataset.url
    const url = new URL(pathLink, window.location.origin)

    const response = await get(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      // history.replaceState(history.state, "", url)
    }
  }

}