import { Controller } from '@hotwired/stimulus'
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = [ 'modal' ]


  connect() {
    this.clicked = false;
  }

  static values = {
    needSetZIndex: { type: Boolean, default: false },
    zIndexModal: { type: Number, default: 10 },
    zIndexBackDrop: { type: Number, default: 9 }
  }

  handleClick(event) {
    event.preventDefault()
    event.stopPropagation()
    if (!this.clicked) {
      this.clicked = true;
      setTimeout(() => {
        if (!this.clicked) return;
  
        this.openModal();
        this.clicked = false;
      }, 300);
    } else {
      this.clicked = false;
      this.showPost();
    }
  }

  openModal() {
    this.modal = new Modal(this.modalTarget);
    this.modal.show();
    if (this.needSetZIndexValue) this.setZIndexVisibility();
  }

  // bootstrap не може правильно визначити z-index для модального вікна, якщо воно вкладене в інше модальне вікно
  setZIndexVisibility() {
    const modal = this.modal._element;
    const backdrop = this.modal._backdrop._element;
    if (!modal || !backdrop) return;

    modal.style.zIndex = this.zIndexModalValue;
    backdrop.style.zIndex = this.zIndexBackDropValue;
 
  }

  showInModal(event) {
    event.preventDefault()
    event.stopPropagation()
    this.openModal();
  }

  showPost = () => {
    Turbo.visit(event.target.closest('a').href);
  }
}
