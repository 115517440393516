import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'body', 'more' ]
  
  static values = { 
    model: String,
    maxLength: String, 
    maxLines: String 
  }

  connect() {
    const { trimmedText, isTrimmed } = this.trimText(this.bodyTarget.innerHTML);
    this.originalText = this.replaceNewlinesWithBr(this.bodyTarget.innerHTML) // зберігаємо оригінальний текст дта міняємо символи нового рядка на тег <br>
    if (isTrimmed) {
      const showMoreName = I18n.social.posts.show_more
      this.bodyTarget.innerHTML = `
        <p class='mb-0 mt-2 mx-md-4 mx-3 body-${this.modelValue}-section'>
          ${trimmedText}
          <a class='more' data-action="click->show-more#showMore">
            <span class='more-span fs-55 text-secondary'>${showMoreName}</span>
          </a>
        </p>`
    } else {
      // необхідно переформатувати текст, навіть якщо він не був обрізаний
      this.showMore();
    } 
  }
  // перехід на сторінку з постами з вказаним хештегом
  visitTagList(event) {
    event.preventDefault();
    event.stopPropagation()
    const tag = event.target.innerText.replace(/#/g, '');
    Turbo.visit(`posts/tags/${tag}`);
  }

  showMore() {
    this.bodyTarget.innerText = ''
    this.bodyTarget.innerHTML = `
      <a class='text-reset' href="${this.postPath}" target='_top'>
        <p class='mb-0 mt-2 mx-md-4 mx-3 body-${this.modelValue}-section'>
          ${this.originalText}
        </p>
      </a>`
  }
  // оброблюємо хештеги
  wrapHashtags(text) {
    const regex = /#[\wа-яА-ЯёЁіІїЇґҐ]+/gi; // регулярка для пошуку хештегів
    return text.replace(regex, "<span class='kidium-hashtag' data-action='click->show-more#visitTagList'>$&</span>");
  }

  replaceNewlinesWithBr(text) {
    let bodyText = text.replace(/<div data-show-more-target="more"><\/div>|<p><\/p>/g, '').replace(/^(<br\s*\/?>)+/, '')
    bodyText = this.wrapHashtags(bodyText);
    const lines = bodyText.split(/\r?\n/);
    const processedLines = lines.map((line, index) => {
      if (index === 0) {
        return line ? line : "";
      } else {
        return line ? "<br>" + line : "<br>";
      }
    });
    // видаляємо паразитні <br> на початку і в кінці текста для оригінального body
    return processedLines.join("").replace(/^(<br\s*\/?>)+/, '').replace(/<br>\s*$/, '');
  }

  trimText(text) {
    let trimmedText = text.replace(/<\/?[^>]+(>|$)/g, ''); // для підрахунку необхідно видалити HTML-теги з body;
    trimmedText = trimmedText.replace(/^(<br\s*\/?>)+/, ''); // видаляємо паразитні <br> на початку текста
    const lines = trimmedText.split(/<br\s*\/?>/i); // розбити текст на рядки з урахуванням HTML-тегів <br>;
    const maxLength = +this.maxLengthValue || 300 // максимальна кількість символів - 300/140;
    const maxLines = +this.maxLinesValue || 4// максимальна кількість рядків (якщо небагато символів в них) - 4/3;
    if (lines[0] === '') lines.shift(); // видаляємо перший елемент якщо він порожній, інакше отримаємо зайвий <br>;
    let result = '';
    let isTrimmed = false; // чи було обрізання тексту;

    if (lines.length > maxLines && trimmedText.length > maxLength) {
      let lastLineIndex = 3;
      let lastLine = lines[lastLineIndex];
      let lineCount = lastLine.length > maxLength - 50 ? 4 : 3;
      let lineBreaks = lines.slice(0, lineCount).join("<br>");
      while (lineBreaks.length > maxLength) {
        lastLineIndex -= 1;
        lastLine = lines[lastLineIndex];
        lineCount -= 1;
        lineBreaks = lines.slice(0, lineCount).join("<br>");
      }
      if (lastLine && lastLine.length > maxLength - 50) {
        result = lineBreaks + "<br>" + lastLine.slice(0, maxLength - 50);
      } else {
        result = lineBreaks + "<br>" + lastLine;
      }
      isTrimmed = true;
    } else if (trimmedText.length > maxLength) {
      result = trimmedText.slice(0, maxLength - 3);
      isTrimmed = true;
    } else {
      result = trimmedText;
    }
    // символи нового рядка змінюємо на тег <br>
    result = result.replace(/\n/g, "<br>");
    result = result.replace(/^(<br\s*\/?>)+/, '').replace(/<br>\s*$/, ''); // видаляємо паразитні <br>
    
    return {
      trimmedText: result,
      isTrimmed: isTrimmed,
    };
  }

  get postPath() {
    return this.data.get("postPath");
  }
}
