import { Controller } from '@hotwired/stimulus'
import IMask from 'imask';

export default class extends Controller {
  static targets = ['inputPhone'];


  maskOptions = { mask: '+{38}(000)000-00-00' };

  connect()  { 
    this.initPhoneMask(); 
  }

  initPhoneMask() {

    this.inputPhoneTargets.forEach(input => {
      const phoneMask = IMask(input, this.maskOptions);

      input.addEventListener('input', () => {
        phoneMask.updateValue();
      });
    });
  }
}
