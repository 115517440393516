import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['textarea'];

  static values = {
    maxHeight: { type: Number, default: 5 }
  }

  connect() {
    this.textareaTarget.closest('form').reset()
    this.setDefaultTextAreaStyle(this.textareaTarget);
  }

  // встановлюємо стандартні стилі для textarea
  setDefaultTextAreaStyle(textarea) {
    textarea.style.height = 'auto';
    textarea.style.overflowY = 'hidden';
  }

  resize() {
    const textarea = this.textareaTarget;
    this.setDefaultTextAreaStyle(textarea);

    // поки textarea scrollHeight більша за textarea offsetHeight і textarea offsetHeight менша за this.maxHeightValue (5) рядків
    while (textarea.scrollHeight > textarea.offsetHeight && textarea.offsetHeight < this.maxHeightValue * parseFloat(getComputedStyle(textarea).lineHeight)) {
      // збільшуємо висоту textarea на висоту одного рядка
      textarea.style.height = `${textarea.offsetHeight + parseFloat(getComputedStyle(textarea).lineHeight)}px`;
    }
    // якщо textarea вже має 5 рядків (автоматично обраховані браузером за lineHeight * this.maxHeightValue(5))
    if (textarea.offsetHeight >= this.maxHeightValue * parseFloat(getComputedStyle(textarea).lineHeight)) {
      // включаємо скролл
      textarea.style.overflowY = 'auto'; 
    }
  }
}