import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    provider: String,
    callbackUrl : { type: String, default: '/profile' }
  }

  connect() {
    this.performCallback()
  }

  async performCallback() {
    const provider = this.providerValue;
    const callbackUrl = this.callbackUrlValue;

    // Get the fragment from the URL
    const fragment = window.location.hash.substring(1);

    // Parse the fragment as URL parameters
    const params = new URLSearchParams(fragment);

    // Access the parameters
    const accessToken = params.get('access_token');
    const dataAccessExpirationTime = params.get('data_access_expiration_time');
    const expiresIn = params.get('expires_in');

    // Do something with the parameters, e.g., send them to the server
    // using an AJAX request or update the page content
    console.log("Access Token:", accessToken);
    console.log("Data Access Expiration Time:", dataAccessExpirationTime);
    console.log("Expires In:", expiresIn);

    // Rewrite using @rails/request.js with async/await
    try {
      const t = I18n.users.sessions.new.facebook.login;

      if (accessToken) {
        const response = await post(`/auth/${provider}/login`, {
          responseKind: 'json',
          body: JSON.stringify({ accessToken }),
        });

        if (response.ok) {
          toaster("success", t.success, t.refresh_page);
          window.location.href = callbackUrl;
        } else {
          toaster("error", t.error, t.try_again);
        }
      } else {
        toaster("error", t.error, t.try_again);
      }
    } catch (error) {
      console.error(`${provider} login error: `, error);
      toaster("error", t.error, t.try_again);
    }
  }
}
