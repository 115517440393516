import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  
  connect() {
    if (+this.element.dataset.page > 1) {
      const metaHeadRobots = document.querySelector('meta[name="robots"]')
      if (metaHeadRobots) {
        metaHeadRobots.setAttribute("content", "noindex, follow")
      } else {
        const metaRobots = document.createElement('meta')
        metaRobots.name = 'robots'
        metaRobots.content = 'noindex, follow'
        document.head.appendChild(metaRobots)
      }
    } else {
      document.querySelector('meta[name="robots"]')?.remove()
    }
  }
}