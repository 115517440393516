import { PRODUCTS_VISIT_PAGES_NAME, MAX_VISIT_PAGES_ELEMENT } from './constants.js' // import * as constants from './constants'

// get and parse local visit pages (recent for guest)
function getRecentPages() {
  try {
    return JSON.parse(localStorage.getItem(PRODUCTS_VISIT_PAGES_NAME)) || []
  } catch (e) {
    console.err(e)
    return []
  }
}

// add to local visit pages (recent for guest)
function addToRecentPages(pagesId, searchParams) {
  const recentPages = getRecentPages()

  if (recentPages?.includes(pagesId)) {
    recentPages.splice(0, 0, recentPages.splice(recentPages.indexOf(pagesId), 1)[0])
    localStorage.setItem(PRODUCTS_VISIT_PAGES_NAME, JSON.stringify(recentPages))
  } else {
    recentPages.unshift(pagesId)
    localStorage.setItem(PRODUCTS_VISIT_PAGES_NAME, JSON.stringify(recentPages.slice(0, MAX_VISIT_PAGES_ELEMENT)))
  }
  return paramsAppend(getRecentPages(), searchParams)
}

function paramsAppend(localRecentPages, searchParams) {
  if (localRecentPages.length > 1) {
    localRecentPages.forEach((_, idx) => {
      searchParams.append('guest_visit_pages[]', localRecentPages[idx])
    })
  } else {
    searchParams.append('guest_visit_pages[]', localRecentPages)
  }
  return searchParams
}

function clearRecentPages() {
  localStorage.removeItem(PRODUCTS_VISIT_PAGES_NAME)
}

function clearRecentPage(value) {
  const recentData = getRecentPages()
  const indexValue = recentData.indexOf(value)
  if (indexValue == -1) return

  recentData.splice(indexValue, 1)
  localStorage.setItem(PRODUCTS_VISIT_PAGES_NAME, JSON.stringify(recentData))
}
  
export { getRecentPages, addToRecentPages, clearRecentPages, clearRecentPage }