import { Controller } from '@hotwired/stimulus'
import fadeIn from "controllers/_lib/animate/fadeIn.js"
import fadeOut from "controllers/_lib/animate/fadeOut.js"

export default class extends Controller {
  static targets = [
    'title',
    'content',
  ]

  static values = {
    isNeedTitleTabSwitch: { type: String, default: 'false' }, // чи потрібно перемикати заголовки табок (кнопок)
  }

  static classes = [
    'active', // класи для активного заголовку табки
    'inactive' // класи для неактивного заголовку табки
  ]

  performTabContent(targetId) {
    this.contentTargets.forEach(contentTab => {
      if (contentTab.id == targetId) {
        fadeIn(contentTab, 700, 'd-none')
      } else {
        fadeOut(contentTab, 300, 'd-none')
      }
    })
  }

  performTabTitles(targetId) {
    const controller = this
    this.titleTargets.forEach(titleTab => {
      if (titleTab.getAttribute('data-action-target') == targetId) {
        titleTab.classList.remove(...controller.inactiveClasses)
        titleTab.classList.add(...controller.activeClasses)
      } else {
        titleTab.classList.remove(...controller.activeClasses)
        titleTab.classList.add(...controller.inactiveClasses)
      }
    })
  }

  activate(event) {
    event.preventDefault()
    const targetId = event.currentTarget.getAttribute('data-action-target')
    if (this.isNeedTitleTabSwitchValue === 'true') this.performTabTitles(targetId) // перемикаємо заголовки табок
    this.performTabContent(targetId) // перемикаємо контент табок
  }
  
}