import Rails from "@rails/ujs"
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage"
import "controllers"

import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";

import 'trix/dist/trix.css';
import 'trix/dist/trix.js';

import "./utilites"

import "./components"

import "./cart"
import "./catalog"
import "./catalog_objects"
import "./messages"
import "./orders"
import "./products"
import "./services"
import "./profile"
import "./users"

import "./main"
import "./other"

// css
import "../stylesheets/application"
import 'cropperjs/dist/cropper.css'

Rails.start()
ActiveStorage.start()
require("trix")
require("@rails/actiontext")