import { Controller } from '@hotwired/stimulus'
import TomSelect from "tom-select";
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { 
    url: String,
    type: { type: String, default: 'all' },
    creationEnable: { type: String, default: 'true' },
    litePlaceholder: { type: String, default: 'false' }
  }

  config = {
    plugins: ['clear_button'],
    loadThrottle: 300,
    valueField: 'value',
    createOnBlur: true,
    persist: false,
    create: this.isCreationEnable,
    highlight: true,
    onDropdownClose: this.selectHandler(),
    placeholder: this.placeholder,
    sortField: {
      field: "name",
      direction: "asc"
    },
    load: (q, callback) => this.search(q, callback),
  }

  connect() {
    this.destroyTomSelect()
    this.select = new TomSelect(this.element, this.config)
  }

  async search(q, callback) {
    const response = await get(this.urlValue, {
      query: { 
        q: q,
        type: this.typeValue
      },
      responseKind: 'json'
    })

    if (response.ok) {
      const list = await response.json
      callback(list) 
    } else {
      console.log(response)
      callback()
    }
  }

  selectHandler() {
    return () => {
      if (this.select) this.select.blur()
    }
  }

  get placeholder() {
    if (this.creationEnableValue == 'true') {
      return I18n.services.index.category.placeholder.with_creation
    } else {
      return this.perform_placeholder
    }
  }

  get perform_placeholder() {
    if (this.litePlaceholderValue == 'false') {
      return I18n.services.index.category.placeholder.without_creation
    } else {
      return I18n.services.index.category.placeholder.without_creation_lite
    }
  }

  get isCreationEnable() {
    return this.creationEnableValue == 'true'
  }

  destroyTomSelect() {
    if (this.select) this.select.destroy()
  }

  disconnect() {
    this.destroyTomSelect()
  }
}
