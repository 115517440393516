function currentLocale() {
  const pathname = window.location.pathname;
  const locale = pathname.split("/")[1]

  if (locale && AVAILABLE_LOCALES.includes(locale)) {
    return locale
  }

  return CURRENT_LOCALE
}

function setLocale() {
  if (window.location.pathname.startsWith(`/${currentLocale()}`)) {
    return `${window.location.origin.concat(window.location.pathname)}`
  } else {
    return `${window.location.origin.concat(window.location.pathname)}${currentLocale()}`
  }
}

function setRootOnlyWithLocale() {
  if (window.location.pathname.startsWith(`/${currentLocale()}`)) {
    return window.location.origin.concat('/' + window.location.pathname.split('/')[1])
  } else {
    return window.location.origin.concat('/' + currentLocale())
  }
}

function createUrlWithlocale(params) {
  if (!params) {
    return setLocale()
  } else {
    return `${setLocale()}/${params}`
  }
}

function createRootUrlWithLocale(params) {
  if (!params) {
    return setRootOnlyWithLocale()
  } else {
    return `${setRootOnlyWithLocale()}/${params}`
  }
}

window.setLocale = setLocale
window.currentLocale = currentLocale
window.createUrlWithlocale = createUrlWithlocale
window.createRootUrlWithLocale = createRootUrlWithLocale