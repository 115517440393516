import { Controller } from '@hotwired/stimulus'

export default class extends Controller {  
  static targets = ['btnCollapse']
  
  initialize() {
    if (!this.hasBtnCollapseTarget) return
    // Перше завантаження сторінки з відкритими фільтрами
    this.btnCollapseTargets.forEach(element => {
      bootstrap.Collapse.getOrCreateInstance(element, { toggle: true })  
    })
  }
}