function initMap() {
  const options = {
    center: {
      lat: 50.401699,
      lng: 30.2538852
    },
    zoom: 6
  };

  const map = new google.maps.Map(document.getElementById('map'), options);

  const input = document.getElementById("address")
  const searchOptions = {
    componentRestrictions: {country: 'UA'}
  };

  const searchBox = new google.maps.places.SearchBox(input, searchOptions);

  map.addListener('bounds_changed', function () {
    searchBox.setBounds(map.getBounds());
  });

  let markers = [];

  searchBox.addListener('places_changed', function () {
    const places = searchBox.getPlaces();

    if (places.length == 0)
      return;

    markers.forEach(function (marker) {
      marker.setMap(null);
    });

    markers = [];

    const bounds = new google.maps.LatLngBounds();
    places.forEach(function (place) {
      if (!place.geometry)
        return;

      markers.push(new google.maps.Marker({
        map: map,
        title: place.name,
        position: place.geometry.location
      }));

      const latitude = document.querySelector("input[name*='latitude']");
      if (latitude) {
        const lat = place.geometry.location.lat();
        latitude.value = lat;
      }

      const longitude = document.querySelector("input[name*='longitude']");
      if (longitude) {
        const lng = place.geometry.location.lng();
        longitude.value = lng;
      }

      if (place.geometry.viewport)
        bounds.union(place.geometry.viewport);
      else
        bounds.extend(place.geometry.location);
    });

    map.fitBounds(bounds);
  });
}
window.initMap = initMap;
