document.addEventListener("turbo:load", function() {
  initNavbarРideWhileScrolling();
})

function initNavbarРideWhileScrolling() {
  const nav = document.querySelector('.navbarBlock')
  if (!nav) return;

  let scrollPrev = 0;
  window.addEventListener("scroll", function() {
    const scrolled = window.pageYOffset;

    const scrollDown = (scrolled > 100 && scrolled > scrollPrev)

    if (scrollDown) {
      nav.classList.add('out');
    }

    if (!scrollDown && nav.classList.contains('out')) {
      nav.classList.remove('out');
    }

    scrollPrev = scrolled;
  });
}
