import { Controller } from '@hotwired/stimulus'
import { put } from '@rails/request.js'
import Cropper from "cropperjs";

export default class extends Controller {
  
  static targets = [
    'fileInput',
    'closeModal',   
    'imagePreviewSection'
  ]
  
  static values = { 
    url: String 
  }

  loader = document.querySelector("#turbo-loader-container")
  loaderBackdrop = document.querySelector("#turbo-loader-backdrop")

  prepareBeforeResponce() {
    this.loader.classList.remove('d-none')
    this.loaderBackdrop.classList.remove('d-none')
  }

  clearAfterResponce() {
    this.loader.classList.add('d-none')
    this.loaderBackdrop.classList.add('d-none')
  }

  loadFile(input) {
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      const _this = this
      reader.onload = function (e) {
        const img = document.createElement("img")
        img.setAttribute("id", 'renderedImage')
        img.setAttribute("class", "d-flex h-full w-100")
        img.style.cssText = 'object-fit: cover;';
        img.setAttribute("src", e.target.result)

        const renderedImage = _this.imagePreviewSectionTarget.querySelector('img#renderedImage');
        if (renderedImage) {
          renderedImage.replaceWith(img)
        } else {
          _this.imagePreviewSectionTarget.appendChild(img);
        }
        
        // Croppers
        _this.cropper = new Cropper(img, {
          aspectRatio: 23 / 4, // 23 / 3
          viewMode: 1,
          autoCropArea: 1,
          movable: true,
          zoomable: true,
          zoomOnTouch: false,
          roundCrop: true,
          ready() {
            // remove rounded corners from cropper
            document.querySelector('.cropper-view-box').style.cssText = 'border-radius: 0 !important;'
            document.querySelector('.cropper-face').style.cssText = 'border-radius: 0 !important;'
          },
        })
      }
      reader.readAsDataURL(input.files[0])
    }
  }

  addImageFile(event) {
    const input = event.target
    if (input.disabled === true) input.disabled = false
    if (this.cropper) this.cropper.destroy();

    this.loadFile(input)
  }

  applyCroppedImage(event) {
    event.preventDefault();
    event.stopPropagation();

    const croppedCanvas = this.cropper.getCroppedCanvas();
    const croppedImageData = croppedCanvas.toDataURL("image/jpeg");
    this.updateImage(croppedImageData);
    this.fileInputTarget.value = '';
    this.closeModalTarget.click();
    this.prepareBeforeResponce()
  }

  async updateImage(imageData) {
    const imageObject = { user: { background_image: imageData } };

    const response = await put(this.urlValue, {
      body: JSON.stringify(imageObject),
      responseKind: 'json'
    })
    if (response.ok) {
      const responseData = await response.json;
      this.clearAfterResponce()
      if (responseData.status !== 'error') {
        toaster("success", I18n.profile.show.change_image.success.title, [I18n.profile.show.change_image.success.message]); 
      } else {
        toaster("error", I18n.profile.show.change_image.errors.title, [I18n.profile.show.change_image.errors.message]); 
        console.error('Error data:', responseData.errorData);
      }
    }
  }

  disconnect() {
    if (this.cropper) this.cropper.destroy();
  }
}