function copyToClipboard(elementId, text, btnId) {
  const input = btnId ? getElement(elementId) : createElement(elementId);
  input.select();

  try {
    const successful = document.execCommand('Copy');
    if (successful) {
      if (btnId) {
        document.querySelector("#" + btnId).textContent = "Скопійовано!";
      } else {
        document.body.removeChild(input);
      }
      alert(text);
    } else {
      console.error('Copy to clipboard failed.');
    }
  } catch (err) {
    console.error('Copy to clipboard is not supported:', err);
  }
}

function createElement(elementId) {
  const input = document.createElement("input");
  input.setAttribute("value", document.getElementById(elementId).innerHTML);
  document.body.appendChild(input);
  return input;
}

function getElement(elementId) {
  return document.getElementById(elementId);
}

window.copyToClipboard = copyToClipboard;