import { Controller } from '@hotwired/stimulus'
import { put } from "@rails/request.js"

export default class extends Controller {
  static targets = [ 
    'postChannelTab',
    'childrenTab',
    'loadModal',
    'button', 
    'input'
  ]

  static values = {
    url: String
  }

  connect() {
    this.element.addEventListener('hidden.bs.modal', (event) => {
      const modal_frame = document.querySelector('#personal_requested_modal')
      if (modal_frame) modal_frame.src = '' // інакше фрейм фактично залишається активним! 
    })
  }


  select(event) {
    event.preventDefault()
    const button = event.currentTarget
    button.classList.toggle("active")
    const selectedTopics = this.buttonTargets
                                .filter(button => button.classList.contains("active"))
                                .map(button => button.dataset.channelid)
    this.inputTarget.value = selectedTopics.join(",")
  }

  async stepChildren(event) {
    event.preventDefault()
    if (this.hasChildrenTabTarget) {
      this.childrenTabTarget.classList.add('show', 'active')
    }
    if (this.hasPostChannelTabTarget) {
      this.postChannelTabTarget.classList.remove('show', 'active')
    }

    const selectedChannels = this.inputTarget.value.split(",")
    const subscribeUrl = this.urlValue
    if (selectedChannels.length > 0) {
      const response = await put(subscribeUrl, {
        responseKind: 'turbo-stream',
        body: JSON.stringify({ selected_channels: selectedChannels })
      })

      if (response.ok) {
        console.log('Server response:', response.data)
      } else {
        console.error('Server error:', response.data)
      }
    }
  }

  stepPostChannel(event) {
    event.preventDefault()
    if (this.hasChildrenTabTarget)    this.childrenTabTarget.classList.remove('show', 'active')
    if (this.hasPostChannelTabTarget) this.postChannelTabTarget.classList.add('show', 'active')
  }

  loadModalTargetConnected(element) {
    // autoload modal
    console.log('element ', element)
    document.getElementById('load-author-modal').click()
    this.element.remove()
  }
}
