import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import { destroy } from '@rails/request.js' 

export default class extends Controller {
  static targets = ['form', 'input', 'submitBtn']
  static values = {
    countFiles: { type: Number, default: 0 }
  }

  directUploadWillStoreFileWithXHR(request) {
    const fileName = event.currentTarget.response.filename
    const signedId = event.currentTarget.response.signed_id
    const id = event.currentTarget.response.id
    request.upload.addEventListener("progress", event => {
      this.directUploadDidProgress(event, fileName, signedId, id)
      }
    )

    request.upload.addEventListener("loadend",
      event => this.submitReady(event)
    )
  }

  styledPreview() {
    const wrappers = document.querySelectorAll('.preview-thumbnail-wrapper')
    const infoUploads = document.querySelectorAll('.info-upload')
    
    infoUploads.forEach(info => info.remove())
    
    wrappers.forEach(parent => {
      const item = parent.querySelector('.preview-thumbnail')
      item.style.zIndex = '2';
      item.style.marginLeft = '-.7rem';
      item.style.transform = 'scale(1.5)';
      item.style.borderRadius = '5px';
      parent.prepend(item)
    })
  }

  directUploadDidProgress(event, fileName, signedId, id) {
    const currentTime = performance.now();
    const progress = (event.loaded / event.total) * 100;
    const elapsedTime = (currentTime - this.uploadStartTime) / 1000; // в секундах
    const downloadRate = (event.loaded / 1024 / elapsedTime).toFixed(2); // скорость в KB/сек
    const humanDowloadRate = downloadRate > 1024 ? `${(downloadRate / 1024).toFixed(2)} мб/с` : `${Math.round(downloadRate)} кб/с`
    const remainingTime = ((event.total - event.loaded) / 1024 / downloadRate).toFixed(2); // оставшееся время в секундах
    
    const progressBarWrapper = document.querySelector(`.preview-thumbnail-wrapper[data-name="${fileName}"]`);
    if (progressBarWrapper) {
      progressBarWrapper.setAttribute('data-signed-id', signedId)
      this.infoUpload(id, progressBarWrapper).textContent = `⏳ ${remainingTime}сек (${humanDowloadRate})`
    }
    const progressBar = progressBarWrapper.querySelector('.chat-progress-bar')
    if (progressBar) progressBar.style.width = `${progress}%`
  }


  infoUpload(id, progressBarWrapper) { // memoize
    if (!this._infoUpload[id]) {
      const infoDiv = document.createElement('div');
      infoDiv.classList.add('position-absolute', 'info-upload', 'mt-5', 'text-kidium', 'text-center', 'fs-[10px]');
      progressBarWrapper.appendChild(infoDiv);

      this._infoUpload[id] = infoDiv;
    }
    return this._infoUpload[id];
  }

  submitReady() {
    this.countFilesValue--
    
    if (this.countFilesValue === 0) {
      this.styledPreview()
      this.submitBtnTarget.disabled = false
    }
  }

  startUploadProcess() {
    if (this.inputTarget.files) {
      const files = this.inputTarget.files
      this.submitBtnTarget.disabled = true
      const hiddenFields = this.formTarget.querySelectorAll('input[type="hidden"][name="message[attachments][]"]')
      hiddenFields.forEach(field => field.remove())
      this.countFilesValue = files.length
      this._infoUpload = {};
      this.uploadFiles(files)
      
      this.inputTarget.value = null
    }
  }

  createCloseButton(signedId, blobId) {
    const wrapper = document.querySelector(`.preview-thumbnail-wrapper[data-signed-id="${signedId}"]`)
    const button = document.createElement('button')
    button.setAttribute('type', 'button')
    button.setAttribute('data-id', blobId)
    button.classList.add('btn-close', 'btn-close-white', 'position-absolute', 'end-0', 'px-1', 'me-1')
    button.style.zIndex = '3'
    button.addEventListener('click', this.deleteASImage.bind(this))
    wrapper.appendChild(button)
  }

  uploadFiles(files) {
    Array.from(files).forEach(file => this.uploadFile(file));
  }

  uploadFile(file) {
    const _this = this
    if (!this.uploadStartTime) this.uploadStartTime = performance.now();
    this.file = file
    this.url = this.inputTarget.dataset.directUploadUrl
    const upload = new DirectUpload(
      this.file, 
      this.url,
      this
    )

    upload.create((error, blob) => {
      if (error) {
        console.log("error?", error);
      } else {
        // Create image form action
        const reader = new FileReader();
        reader.onload = function(event) {
          const hiddenField = document.createElement('input')
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.setAttribute("data-blob-id", blob.id);
          hiddenField.name = _this.inputTarget.name
          _this.createCloseButton(blob.signed_id, blob.id)
          _this.formTarget.appendChild(hiddenField)
        }

        reader.readAsDataURL(file);
      }
    })
  }

  performSubmitBtn() {
    const contentInput = this.formTarget.querySelector('textarea[name="message[content]"]')
    const attachedFiles = this.formTarget.querySelectorAll('input[type="hidden"][name="message[attachments][]"]')
    const shouldHideSubmitButton = contentInput.value.trim() === '' && attachedFiles.length === 0;

    this.submitBtnTarget.classList.toggle('d-none', shouldHideSubmitButton);
  }

  reCalcLoadedFiles() {
    const loadedFilesLength = this.formTarget.querySelectorAll('input[type="hidden"][name="message[attachments][]"]').length
    this.countFilesValue = loadedFilesLength
    const fileCountInfoBtn = document.querySelector('#fileCountInfo')
    if (!fileCountInfoBtn) return

    const fileCountInfoContent = fileCountInfoBtn.querySelector('.select-files-count')
    
    if (loadedFilesLength === 0) {
      fileCountInfoContent.innerText = '';
      fileCountInfo.classList.add('d-none');
    } else {
      fileCountInfo.classList.remove('d-none');
      fileCountInfoContent.textContent = 'Обрано файлів:  ' + loadedFilesLength;  
    }
  }

  async deleteASImage(event) {
    event.preventDefault();
    event.stopPropagation()

    const button = event.target.type === 'button' ? event.target : event.target.closest('button')
    const id = button.getAttribute('data-id')
    if (!id) return alert('Something went wrong')

    let confirmDelete = confirm("Are you sure to delete this photo");

    if (confirmDelete) this.deleteAttachById(id, button);
  }

  async deleteAttachById(id, button) {
    const url = `/active_storage/${id}`
    const response = await destroy(url, { responseKind: 'json' })
    
    if (response.ok) {
      button.closest('.preview-thumbnail-wrapper').remove();
      const hiddenField = this.formTarget.querySelector(`[data-blob-id="${id}"]`)
      if (hiddenField) hiddenField.remove()
      this.performSubmitBtn()
      this.reCalcLoadedFiles()
    } else {
      console.log(response)
    }
  }
}