import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { FILTER } from './_lib/constants.js'

export default class extends Controller {
  static targets = ['minimumPrice',
                    'maximumPrice',
                    'stateSelect',
                    'sortingsSelect',
                    'productType',
                    'serviceType',
                    'inputFilters',
                    'applyButton',
                    'resetButton'
                   ]
  static values = {
    url: String,
    searchQuery: String,
    requestValue: Boolean
  }

  connect() {
    document.querySelector('#filters')?.addEventListener('submit', event => {
      event.preventDefault();
    })
    this.resetBtnActivate()
  }

  filetrsValuesPresent() {
    const stateNotDefault = this.hasStateSelectTarget && this.stateSelectTarget.value !== ''
    const productTypeNotDefault = this.hasProductTypeTarget && !this.productTypeTarget.checked
    const serviceTypeNotDefault = this.hasServiceTypeTarget && !this.serviceTypeTarget.checked
    const priceValuesPresent = this.inputFiltersTargets.some(element => element.value)

    return priceValuesPresent || productTypeNotDefault || serviceTypeNotDefault || stateNotDefault
  }

  sortCatalog(event) {
    event.preventDefault()
    event.stopPropagation()
    this.filterCatalog()
  }

  getStateValue() {
    if (this.hasStateSelectTarget) {
      return this.stateSelectTarget.value
    } else {
      return ''
    }
  }

  getSortingsValue() {
    if (this.hasSortingsSelectTarget) {
      return this.sortingsSelectTarget.value
    } else {
      return ''
    }
  }

  filterCatalog = async (event) => {
    if (this.requestValue) {
      return
    }
    this.requestValue = true
    const loader = document.querySelector("#turbo-loader-container")
    const loaderBackdrop = document.querySelector("#turbo-loader-backdrop")
    loader.classList.remove('d-none')
    loaderBackdrop.classList.remove('d-none')
    const localeUrl = setLocale()
    const inputAutoSearch = document.querySelector('#search').value.trim()
    const productType =  this.hasProductTypeTarget && this.productTypeTarget.checked ? 'product' : ''
    const serviceType = this.hasServiceTypeTarget && this.serviceTypeTarget.checked ? 'service' : ''
    const searchQuery = this.searchQueryValue || inputAutoSearch
    const stateSelect = this.getStateValue()
    const sortSelect = this.getSortingsValue()
    const minimumPrice = this.hasInputFiltersTargets && this.inputFiltersTargets[0].value // this.minimumPriceTarget.value
    const maximumPrice = this.hasInputFiltersTargets && this.inputFiltersTargets[1].value // this.maximumPriceTarget.value
    const goToUrl = new URL(localeUrl)
    const searchParams = goToUrl.searchParams
    if (searchQuery)  searchParams.append(FILTER.search, searchQuery)
    if (productType)  searchParams.append("types[]", 'product')
    if (serviceType)  searchParams.append("types[]", 'service')
    if (minimumPrice) searchParams.append(FILTER.minPrice, minimumPrice)
    if (maximumPrice) searchParams.append(FILTER.maxPrice, maximumPrice)
    if (stateSelect)  searchParams.append(FILTER.state, stateSelect)
    if (sortSelect)   searchParams.append(FILTER.sort, sortSelect)
    const response = await get(goToUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.requestValue = false
      loader.classList.add('d-none')
      loaderBackdrop.classList.add('d-none')
      if (this.hasApplyButtonTarget) this.applyButtonTarget.disabled = false
      this.resetBtnActivate()
      history.replaceState(history.state, "", goToUrl.href)
    }
  }

  sendResetRequest = async () => {
    this.resetButtonTarget.setAttribute('disabled', '')
    const resetUrl = setLocale()
    const goResetUrl = new URL(resetUrl)
    const searchParams = goResetUrl.searchParams
    searchParams.append("types[]", 'product')
    searchParams.append("types[]", 'service')
    searchParams.append(FILTER.sort, 'news')
    const response = await get(goResetUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.applyButtonTarget.disabled = false
      history.replaceState(history.state, "", resetUrl.href)
    }
  }

  resetBtnActivate() {
    if (this.filetrsValuesPresent() && this.hasResetButtonTarget) {
      this.resetButtonTarget.removeAttribute('disabled')
    } else {
      if (this.hasResetButtonTarget) this.resetButtonTarget.setAttribute('disabled', '')
    }
  }
}
