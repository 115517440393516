import {LOCAL_STORAGE_RECENT_NAME, MAX_SEARCH_ELEMENT} from './constants.js' // import * as constants from './constants'

// get and parse local search history (for guest)
function getHistory() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_RECENT_NAME)) || [];
}

// add to local search history (for guest)
function addToHistory(query) {
  const history = getHistory()

  if (history.includes(query)) {
    history.splice(0, 0, history.splice(history.indexOf(query), 1)[0])
    localStorage.setItem(LOCAL_STORAGE_RECENT_NAME, JSON.stringify(history))
  } else {
    history.unshift(query)
    localStorage.setItem(LOCAL_STORAGE_RECENT_NAME, JSON.stringify(history.slice(0, MAX_SEARCH_ELEMENT)))
  }
}
  
export {getHistory, addToHistory}