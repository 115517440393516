import { Controller } from '@hotwired/stimulus'
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ['inputFile', 
                    'form',
                    'imagesPreview']
  static values = {
    categoryName: String,
  }

  keyInputMap = {
    category: 'category',
    childAge: 'child_age',
    status: 'status',
    city: 'city',
  };

  connect() {
    this.modal = new Modal(this.element)
    this.modal.show()
    
    this.element.addEventListener('hidden.bs.modal', (event) => {
      const modal_frame = document.querySelector('#modal')
      if (modal_frame) modal_frame.src = '' // інакше фрейм фактично залишається активним!
    })
  }

  disconnect() {
    this.modal.hide()
  }

  previewImages(event) {
    const images =  event.target.files
    this.imagesPreviewTarget.innerHTML = ""
    Array.from(images).forEach((image) => {
      const imageElement = document.createElement("img")
      imageElement.classList.add("img-fluid")
      imageElement.src = URL.createObjectURL(image)
      imageElement.width = 100
      imageElement.height = 100
      this.imagesPreviewTarget.appendChild(imageElement)
    }) 
  }

  getCityValue() {
    return document.getElementById('question-metadata-city').textContent.trim();
  }
  
  getChildAgeValue() {
    return document.getElementById('question-metadata-child-age').value;
  }
  
  getCategoryInputValue() {
    // return this.categoryNameValue || document.getElementById('question-metadata-category').value;
    if (this.categoryNameValue && this.categoryNameValue.trim() !== '') {
      return this.categoryNameValue;
    }
    return document.getElementById('question-metadata-category').value;
  }

  getStatusValue() {
    const selectedRadio = document.querySelector('input[name="question[metadata][status]"]:checked');
    return selectedRadio ? selectedRadio.value : '';
  }
  

  getServicesInputValues() {
    const category = this.getCategoryInputValue()
    const childAge = this.getChildAgeValue()
    const status = this.getStatusValue()
    const city = this.getCityValue()
    return { category, childAge, status, city }
  }

  createHiddenFields(metaData) {
    const form = this.formTarget;

    Object.entries(metaData).forEach(([key, value]) => {
      if (value) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = `question[metadata][${this.keyInputMap[key]}]`;
        console.log('input name', input.name)
        input.value = value;
        console.log('input value', input.value)
        form.appendChild(input);
      }
    });
  }

  addMetadata(event) {
    const metaData = this.getServicesInputValues()
    this.createHiddenFields(metaData)
  }

  formTargetConnected(element) {
    if (element.dataset.type !== 'services') return
    
    this.addMetadata()
  }
}