import { Controller } from '@hotwired/stimulus'
import currentUserId from "../_lib/user/currentUserId.js"
import { LOCAL_STORAGE_RECENT_NAME } from '../_lib/history/constants.js'
import { get } from '@rails/request.js'

export default class extends Controller {
  
  connect() {
    this.targetNames = ['page_search', 'advanced_search'];
    this.serverSearchParams = { 'clear': 'server' }
    this.localSearchParams = { 'local_history': '' }
  }

   // Clear recent user history
   async clearUserHistory(event) {
    const urlClearUserHistory = createRootUrlWithLocale('search_history')
     const value = this.value
      
    if (value !== null) {
      this.serverSearchParams[value] = true
      this.localSearchParams[value] = true
    }

    if (currentUserId()) {
      await this.clearServerHistory(urlClearUserHistory, this.serverSearchParams)
    } else {
      localStorage.removeItem(LOCAL_STORAGE_RECENT_NAME)
      await this.clearLocalUserHistory(urlClearUserHistory, this.localSearchParams)
    }
  }

  // Clear user recent search fired! (if current_user present)
  async clearServerHistory(url, searchParams) {
    await get(url.toString(), {
      query: searchParams,
      responseKind: 'turbo-stream'
    })
  }

  // Clear user recent search fired! (if current_user not present)
  async clearLocalUserHistory(url, searchParams) {
    await get(url.toString(), {
      query: searchParams,
      responseKind: 'turbo-stream'
    })
  }

  get value() {
    const value = this.element.dataset.targetName;
    if (!value || !this.targetNames.includes(value)) return null

    return value
  }
}
