import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "tabs",
    "tabPane",
    "tabsContent",
    "tabsFaq", 
    'tabsContentPane', 
    'tabsFaqPane',
    "swipeContentArea",
    "swipeFaqArea" 
  ]
  
  swipeThreshold = 100 // Порогове значення для визначення свайпа (щоб дрібні рухи не враховувалися)
  startX = null
  startY = null

  tabPanes = {
    "posts-tab-pane": "posts-faq-tab",
    "products-tab-pane": "products-faq-tab",
    "services-tab-pane": "services-faq-tab",
    "posts-faq-tab-pane": "posts-tab",
    "products-faq-tab-pane": "products-tab",
    "services-faq-tab-pane": "services-tab",
  };

  swipeLeft(nameTarget) {
    const currentTab = this.getCurrentTab(nameTarget)
    const nextTab = currentTab.closest('li').nextElementSibling // Отримуємо наступну вкладку (стандарт -> currentTab.nextElementSibling)
    if (nextTab) {
      const tab = nextTab.querySelector('button')
      this.activateTab(tab, nameTarget) // Активуємо наступну вкладку (стандарт -> nextTab)
      this.selectSecondTab(tab.getAttribute('aria-controls'));
    }
  }

  swipeRight(nameTarget) {
    const currentTab = this.getCurrentTab(nameTarget)
    const prevTab = currentTab.closest('li').previousElementSibling // Отримуємо попередню вкладку (стандарт -> currentTab.nextElementSibling)
    if (prevTab) {
      const tab = prevTab.querySelector('button')
      this.activateTab(tab, nameTarget) // Активуємо попередню вкладку (стандарт -> nextTab)
      this.selectSecondTab(tab.getAttribute('aria-controls'));
    }
  }

  touchstartHandler(element) {
    element.addEventListener("touchstart", (event) => {
      this.startX = event.touches[0].clientX
      this.startY = event.touches[0].clientY
    })
  }

  touchendHandler(element) {
    const nameTarget = element.dataset.nameValue

    element.addEventListener("touchend", (event) => {
      const endX = event.changedTouches[0].clientX
      const endY = event.changedTouches[0].clientY

      const diffX = this.startX - endX
      const diffY = this.startY - endY

      if (Math.abs(diffX) > Math.abs(diffY) && Math.abs(diffX) > this.swipeThreshold) { // Якщо рух по X більший за рух по Y і більший за порогове значення
        if (diffX > 0) {
          this.swipeLeft(nameTarget) // попередя вкладка
        } else {
          this.swipeRight(nameTarget) // наступна вкладка

        }
      }
    })
  }

  connect() {
    // Додаємо обробник події touchstart
    this.touchstartHandler(this.swipeContentAreaTarget)
    this.touchstartHandler(this.swipeFaqAreaTarget)

    // Додаємо обробник події touchend
    this.touchendHandler(this.swipeContentAreaTarget)
    this.touchendHandler(this.swipeFaqAreaTarget)
  }

  getCurrentTab(nameTarget) {
    const elements = this[nameTarget + "Targets"]
    return elements.find((tab) => tab.classList.contains("active")) // Знаходимо активну вкладку
  }

  clickActivate(event) {
    const activatedTabPane = event.target
    const activatedTabPaneId = activatedTabPane.getAttribute("aria-controls");

    this.selectSecondTab(activatedTabPaneId);
  }

  selectSecondTab(tabPaneId) {
    if (this.tabPanes.hasOwnProperty(tabPaneId)) this.activateTabPane(this.tabPanes[tabPaneId])
  }

  activateTab(tab, nameTarget) {
    const tabsElements = this[nameTarget + "Targets"];
    tabsElements.forEach((t) => t.classList.remove("active")) // Видаляємо клас active з усіх вкладок
    tab.classList.add("active") // Додаємо клас active до поточної вкладки

    const panesElements =  this[nameTarget + "PaneTargets"]
    panesElements.forEach((pane) => pane.classList.remove("show", "active")) // Видаляємо клас active з усіх елементів, які відповідають вкладкам
    const target = document.querySelector(tab.dataset.bsTarget) // Отримуємо елемент, який відповідає вкладці
    if (target) target.classList.add("show", "active") // Додаємо клас 'show active' до елемента, який відповідає вкладці
  }

  activateTabPane(tabName) {
    const tab = this.element.querySelector(`#${tabName}`);
    const nameTarget = tab.dataset.nameValue
    
    this.activateTab(tab, nameTarget);
  }
}
