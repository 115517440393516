import IMask from "imask";

document.addEventListener('turbo:load', () => {
  initPhoneMask();
})

const maskOptions = {
  mask: '+{38}(000)000-00-00'
};

function initPhoneMask() {
  document.querySelectorAll("input.imask").forEach((item) => {
    item.addEventListener('input', () => {
      const phoneMask = IMask(event.target, maskOptions);
    });
  });
}