export default function initQuickOrderForm(modal) {
  const id = "createQuickOrder";
  const form = document.getElementById(id);

  form.addEventListener('click', () => {
    event.preventDefault();
    const t = I18n.users.registration.errors
    const phoneError = document.getElementById('quick_order_phone_error')
    const phoneInput = document.getElementById('quick_order_phone');
    const productIdInput = document.getElementById('quick_order_product_id');

    const phone = phoneInput.value
    const productId = productIdInput.value

    if (phone.length < 17) {
      phoneError.innerText = t.phone.fill;
      return false;
    }

    const data = {
      quick_order: {
        phone,
        product_id: productId
      }
    }

    ajax({
      url: `/${currentLocale()}/quick_orders`,
      type: "POST",
      data: data,
      success: function(data) {
        if (data.error) {
          phoneError.innerText = data.message;
        } else {
          modal.hide();
          toaster("success", "", data.message);
        }
      },
      error: function(error) {
        form.removeAttribute('disabled')
        toaster("error", I18n.flash.error, error);
      }
    })

    form.removeAttribute('disabled')
  })
}
