import { Controller } from "@hotwired/stimulus"

// User 'clipboard' controller in your view:
export default class extends Controller {
  static targets = ['source', 'button']
  static values = {
    resultText: { type: String, default: 'Copied!'},
    alertText: { type: String, default: 'Copied!'},
    needBtn: { type: Boolean, default: false },
    needAlert: { type: Boolean, default: false },
  }
  
  copy(event) {
    event.preventDefault()
    if (!this.hasSourceTarget) return

    navigator.clipboard.writeText(this.sourceTarget.value)
    this.showAlert()
    this.writeTextToBtn()
  }

  showAlert() {
    if (!this.needAlertValue) return

    alert(this.alertTextValue)
  }

  writeTextToBtn() {
    if (!this.needBtnValue || !this.buttonTarget) return

    const originalHTML = this.buttonTarget.innerHTML
    this.buttonTarget.innerText = this.resultTextValue
    
    setTimeout(() => {
      this.buttonTarget.innerHTML = originalHTML
    }, 1000)
  }
}
