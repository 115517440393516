import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input", "progress", "progressBar"]

  form;
  input;
  submitBtn;
  countFiles = 0;
  placeToInsertImagePreview;
  dropArea = document.getElementById('drop-area');


  connect() {
    if (this.dropArea) { 
      ['dragenter', 'dragover'].forEach(eventName => {
        this.dropArea.addEventListener(eventName, this.highlight, false)
      });
      
      ['dragleave', 'drop'].forEach(eventName => {
        this.dropArea.addEventListener(eventName, this.unhighlight, false)
      });
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event)
    )

    request.upload.addEventListener("loadend",
      event => this.submitReady(event)
    )
  }

  directUploadDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    this.progressBarTarget.style.width = `${progress}%`
  }

  submitBusy() {
    this.progressTarget.classList.remove("d-none")
    this.submitBtn.disabled = true
    this.submitBtn.querySelector('#submit-ready').classList.add('d-none')
    this.submitBtn.querySelector('#submit-busy-loader').classList.remove('d-none')
  }

  submitReady(event) {
    this.countFiles--
    if (this.countFiles === 0) {
      this.progressTarget.classList.add("d-none")
      this.submitBtn.disabled = false
      this.submitBtn.querySelector('#submit-ready').classList.remove('d-none')
      this.submitBtn.querySelector('#submit-busy-loader').classList.add('d-none')
    }
  }

  highlight = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.dropArea.classList.add('highlight-drop')
  }

  unhighlight = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.dropArea.classList.remove('highlight-drop')
  }

  prepareElement(event) {
    this.input = event.target
    this.form = this.element
    this.submitBtn = this.form.querySelector('button[type=submit]')

    this.placeToInsertImagePreview = this.form.querySelector("#images_preview")
    
    if (!this.placeToInsertImagePreview) {
      this.placeToInsertImagePreview = document.createElement("div")
      this.placeToInsertImagePreview.setAttribute("id", "images_preview")

      this.input.parentElement.parentElement.after(this.placeToInsertImagePreview)
    }
  }

  initializeImagePreview(event) {
    this.prepareElement(event)

    if (this.input.files) {
      this.submitBusy()
      this.countFiles = this.input.files.length
      this.uploadFiles(this.input.files)
      
      this.input.value = null
    }
  }
  
  dropUpload(event) {
    event.preventDefault()
    event.stopPropagation()
    console.log('Uploading files ...')
    this.prepareElement(event)
    this.uploadFiles(event.dataTransfer.files);
  }

  pasteUpload(event) {
    if (!event.clipboardData.files.length) return;

    event.preventDefault();
    // this.uploadFiles(event.clipboardData.files);
  }

  uploadFiles(files) {
    Array.from(files).forEach(file => this.uploadFile(file));
  }

  uploadFile(file) {
    const _this = this
    const url = _this.inputTarget.dataset.directUploadUrl
    const upload = new DirectUpload(
      file, 
      url,
      this // callback directUploadWillStoreFileWithXHR(request)
    )

    upload.create((error, blob) => {
      if (error) {
        console.log("error?", error);
      } else {
        // Create image form action
        const reader = new FileReader();
        reader.onload = function(event) {
          const item = document.createElement("div")
          item.setAttribute("class", "col-12 col-md-6 item")

          const img = document.createElement("img")
          img.setAttribute("class", "img-thumbnail")
          img.setAttribute("data-id", blob.id)
          img.setAttribute("src", event.target.result)
          img.setAttribute("data-action", "click->upload#selectBaseImage")
          item.appendChild(img)

          const close = document.createElement("button")
          close.setAttribute("class", "close")
          close.setAttribute("data-id", blob.id)
          close.setAttribute("data-action", "click->upload#deleteASImage")
          close.innerHTML = '<span aria-hidden="true">&times;</span>'

          item.appendChild(close)

          const hiddenField = document.createElement('input')
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.name = _this.inputTarget.name

          item.appendChild(hiddenField)

          // if need add first in previews but save in end
          _this.placeToInsertImagePreview.appendChild(item)
        }

        reader.readAsDataURL(file);
      }
    })
  }

  selectBaseImage(event, field = 'base_image') {
    event.preventDefault()
    event.stopPropagation()

    const images_preview = event.target.closest("#images_preview")

    Array.from(images_preview.querySelectorAll("input.base_image")).forEach((input) => {
      input.remove()
    })

    Array.from(images_preview.querySelectorAll(".item.select")).forEach((img) => {
      img.classList.remove("select")
    })

    const form = event.target.closest("form")
    const item = event.target.closest(".item")

    item.classList.add("select")

    const newInput = document.createElement('input')
    newInput.setAttribute("class", field)
    newInput.setAttribute("type", "hidden");
    newInput.setAttribute("value", event.target.getAttribute('data-id'));
    newInput.name = form.querySelector("input.images").name.replace("[images][]", `[${field}]`)

    item.appendChild(newInput)
  }

  deleteASImage(event) {
    event.preventDefault();
    event.stopPropagation()

    const item = event.target.closest(".item")
    const button = event.target.type === 'button' ? event.target : event.target.closest('button')
    const id = button.getAttribute('data-id') 

    let confirmDelete = confirm("Are you sure to delete this photo");

    if (confirmDelete) {
      if (!id) {
        return alert('Something went wrong')
      } else {
        this.deleteImageById(id, function(data) {
          if (data.valid) {
            item.remove()
          }
        }, null)
      }
    }
  }

  deleteImageById(id, success, error) {
    ajax({
      type: 'DELETE',
      url: `/active_storage/${id}`,
      success: success,
      error: error
    })
  }
}