import { Controller } from '@hotwired/stimulus'
import TomSelect from "tom-select";
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { 
    url: String,
    litePlaceholder: { type: String, default: 'false' }
  }

  config = {
    plugins: ['clear_button'],
    loadThrottle: 300,
    valueField: 'value',
    highlight: true,
    onDropdownClose: this.selectHandler(),
    placeholder: this.placeholder,
    sortField: {
      field: "name",
      direction: "asc"
    },
    load: (q, callback) => this.search(q, callback)
  }

  connect() {
    this.destroyTomSelect()
    this.select = new TomSelect(this.element, this.config)
  }

  async search(q, callback) {
    const response = await get(this.urlValue, {
      query: { q: q },
      responseKind: 'json'
    })

    if (response.ok) {
      const list = await response.json
      callback(list)
    } else {
      console.log(response)
      callback()
    }
  }

  selectHandler() {
    return () => {
      if (this.select) this.select.blur()
    }
  }

  get placeholder() {
    if (this.litePlaceholderValue == 'true') {
      return I18n.service_requests.form.placeholder.city_lite
    } else {
      return I18n.service_requests.form.placeholder.city
    }
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  disconnect() {
    this.destroyTomSelect()
  }
}
