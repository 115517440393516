import { Controller } from '@hotwired/stimulus'
import { EmojiButton } from '@joeattardi/emoji-button';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = ["button", "input"]
  
  connect() {
    this.picker = new EmojiButton();
    this.picker.on('emoji', emoji => this.addEmoji(emoji));
  }

  addEmoji(emoji) {
    if (this.element.id.startsWith('form_message')) {
      this.inputTarget.value += emoji.emoji
      this.performSubmitButton()
    } else {
      const messageId = this.element.dataset.messageId
      let href = this.element.dataset.url
      href = href.replace('/message_id', `/${messageId}`); 
      const data = { emoji: emoji.emoji, name: emoji.name, message_id: messageId }
      post(href, { 
        responseKind: 'turbo-stream', 
        body: data 
      })
    }
  }

  performSubmitButton() {
    const form = this.inputTarget.closest('form')
    const submitBtn = form.querySelector('[data-chat-service-target="submitBtn"]')
    const fileInput = form.querySelector('input[type="file"]');
    const attachedFiles = fileInput.files;
    
    const shouldHideSubmitButton = this.inputTarget.value.trim() === '' && attachedFiles.length === 0;

    submitBtn.classList.toggle('d-none', shouldHideSubmitButton);
  }
    

  toggle(event) {
    event.preventDefault()
    this.picker.togglePicker(event.target)
  }
}