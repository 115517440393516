import TomSelect from "tom-select"

document.addEventListener('turbo:load', () => {
  initTomSelect();
});

function initTomSelect() {
  initBaseTomSelect();
  initVirtualScrollTomSelect();
  initTagsTomSelect();
}

function initBaseTomSelect() {
  document.querySelectorAll("select.tomSelect").forEach((item) => {
    new TomSelect(`#${item.id}`, {
      sortField: {
        field: "text",
        direction: "asc"
      }
    });
  });
}

function initVirtualScrollTomSelect() {
  document.querySelectorAll("select.tomSelectVirtualScroll").forEach((item) => {
    const options = JSON.parse(item.dataset.select || "{}")
    const baseUrl = options.url
    const valueField = options.valueField || "id"
    const labelField = options.labelField || "name"
    const maxOptions = options.maxOptions || 200
    let parameters = [];
    for (let name in options.params) {
      parameters.push(`${name}=${options.params[name]}`)
    }
    const params = `&${parameters.join("&")}`

    new TomSelect(`#${item.id}`, {
      valueField,
      labelField,
      searchField: [labelField],
      plugins: ['virtual_scroll'],
      maxOptions,
      firstUrl: function(query) {
        return `/${currentLocale()}${baseUrl}.json?search=${encodeURIComponent(query)}${params}`;
      },
      load: function(query, callback) {
        const url = this.getUrl(query);
        fetch(url)
          .then(response =>
            response.json()
          )
          .then(json => {
            const next_url = `${currentLocale()}${baseUrl}?search=${encodeURIComponent(query)}&page=${++json.page}${params}`;
            this.setNextUrl(query, next_url);

            let data = json.data
            callback(data);
          }).catch((e) => {
            callback();
          });
      },
      render: {
        loading_more: function(data, escape) {
          return `<div class="loading-more-results py-2 d-flex align-items-center"><div class="spinner"></div></div>`;
        },
        no_more_results: function(data, escape) {
          return `<div class="no-more-results">No more results</div>`;
        }
      }
    });
  });
}

function initTagsTomSelect() {
  document.querySelectorAll("input.tomSelectTags").forEach((item) => {
    const options = JSON.parse(item.dataset.select || "{}")
    const baseUrl = options.url
    const valueField = options.valueField || "id"
    const labelField = options.labelField || "name"
    const length = options.length || 3
    let parameters = [];
    for (let name in options.params) {
      parameters.push(`${name}=${options.params[name]}`)
    }
    const params = `&${parameters.join("&")}`
    new TomSelect(`#${item.id}`, {
      create: true,
      maxItems: 4,
      valueField,
      labelField,
      searchField: [labelField],
      sortField: {
        field: "text",
        direction: "asc"
      },
      createFilter: function(input) {
        input = input.toLowerCase();
        if (input.length >= length) {
          return !(input in this.options);
        } else {
          return false;
        }
        // return input.length >= length; ->  "unreachable code after return statement" error
        // input = input.toLowerCase();
        // return !(input in this.options);
      },
      load: function(query, callback) {
        const url = `/${currentLocale()}${baseUrl}.json?search=${encodeURIComponent(query)}${params}`;
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json.items);
          }).catch(() => {
            callback();
          })
      }
    })
  });
}
