function getLoaderElements() {
  const loader = document.querySelector("#turbo-loader-container");
  const loaderBackdrop = document.querySelector("#turbo-loader-backdrop");
  return { loader, loaderBackdrop };
}

function showLoader() {
  const { loader, loaderBackdrop } = getLoaderElements();
  if (!loader || !loaderBackdrop) return;

  if (loader.classList.contains("d-none")) loader.classList.remove("d-none");
  if (loaderBackdrop.classList.contains("d-none")) loaderBackdrop.classList.remove("d-none");
}

function hideLoader() {
  const { loader, loaderBackdrop } = getLoaderElements();
  if (!loader || !loaderBackdrop) return;

  if (!loader.classList.contains("d-none")) loader.classList.add("d-none");
  if (!loaderBackdrop.classList.contains("d-none")) loaderBackdrop.classList.add("d-none");
}

export { showLoader, hideLoader }
