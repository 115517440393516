document.addEventListener('turbo:load', function() {
  window.onload = function() {
    // TODO: fix UX
    // getLocation()
  };
});

function getLocation() {
  const suggestedLocation = getCookie('suggestedLocation')
  if (suggestedLocation) return;

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else {
    setCookie('suggestedLocation', 'false')
  }
}

async function showPosition(position) {
  const location = {
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  };
  const city = await getSuggestedCity(location);
  const name = city[`name${currentLocale() === 'ru' ? '_ru' : ''}`];
  const value = JSON.stringify({
    id: city['id'],
    name
  });
  const t = I18n.geolocation;
  const yes = `<button class="btn" data-city=${value} onclick=\"saveCurrentLocation(true)\">${t.answers.true}</span>`;
  const maybe = `<span data-city=${value} onclick=\"saveCurrentLocation(true)\"></span>`;
  const not = `<button class="btn" onclick=\"saveCurrentLocation(false)\" >${t.answers.false}</span>`;
  toaster("success", `${t.questions.are_from}${name} ?`, `${yes}&nbsp;&nbsp;|&nbsp;&nbsp;${not}`)
}

async function getSuggestedCity(location) {
  let city = null;

  await ajax({
    url: `/${currentLocale()}/cities/suggest`,
    type: 'get',
    data: location,
    success: (data) => {
      city = data;
    }
  })
  return city;
}

function saveCurrentLocation(correctCity = false) {
  // TODO: add some response after save
  const object = event.target.closest(".toast")
  const toaster = bootstrap.Toast.getInstance(object);
  toaster.hide();
  if (correctCity) {
    setCookie('currentCity', event.target.dataset.city)
    setCookie('suggestedLocation', 'true')
  } else {
    setCookie('suggestedLocation', 'false')
  }
}

window.saveCurrentLocation = saveCurrentLocation
