import { Controller } from '@hotwired/stimulus'
import { put } from '@rails/request.js'
export default class extends Controller {

  static targets = [
    'cityNewPoshtaSelect',
    'cityOtherSelect'
  ]
  
  static values = {
    requestValue: { type: Boolean, default: false }
  }
  
  async changeDelivery(event) {
    event.stopPropagation()
    event.preventDefault()
    if (this.requestValue) return
  
    this.requestValue = true
    const url = new URL(event.target.dataset.url)
    const searchParams = url.searchParams
    searchParams.append('delivery_type', event.target.value)
    searchParams.append('owner_id', event.target.dataset.ownerId)
    searchParams.append('order_index', event.target.dataset.index)
    const response = await put(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.requestValue = false
    }
  }   
}