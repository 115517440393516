import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    type: String,
    id: String
  }

  connect() {
  }

  async addObjectToCart(event) {
    event.preventDefault()
    if (!this.hasCartIdCookie() && setLocale().endsWith('basket')) {
      Turbo.visit(createRootUrlWithLocale()) // якщо, знаходячись у кошику, почистили cookies cart_id треба перезавнтажити сторінку щоб всі скрипти запрацювали
      return
    }
    
    const object = event.target
    const goToUrl = `${createRootUrlWithLocale()}/${this.typeValue}s/${this.idValue}/cart`
    const response = await post(goToUrl)
    if (response.ok) {
      const text = await response.text
      this.successAddObjectToCart(JSON.parse(text))
      this.animateAddToCartClick(event)
      this.showTooltipe(object)
    }
  }

  successAddObjectToCart(data) {
    const {
      count
    } = data;
    const basketCounter = document.getElementById('basket_count')
    if (basketCounter) {
      this.updateBasketCounter(basketCounter, count)
    }
    const basketCounterMobile = document.getElementById('basket_count_mobile')
    if (basketCounterMobile) {
      this.updateBasketCounter(basketCounterMobile, count)
    }
  }

  showTooltipe(object) {
    const tooltip = new bootstrap.Tooltip(object, {
      placement: 'bottom',
      trigger: 'manual',
      title: I18n.product.operation.add_to_cart
    })
    setTimeout(function() {
      tooltip.hide()
    }, 2000)
  }

  updateBasketCounter(basketCounter, count) {
    if (count > 0) {
      basketCounter.innerHTML = count
      basketCounter.style.display = '';
      const basketCounterParent = basketCounter.parentNode
      if (basketCounterParent) {
        basketCounterParent.classList.remove("disabled")
      }
      return
    }
    basketCounter.style.display = 'none';
  }

  getCurrentRect() {
    if (window.getComputedStyle(document.body, ':before').content.replace(/"/g, '') == 'mobile') {
      return document.querySelector(".cart_item_mobile").getBoundingClientRect()
    } else {
      return document.querySelector(".cart_item_desktop").getBoundingClientRect()
    }
  }
  
  animateAddToCartClick(event) {
    const body = document.querySelector('body')
    const animCart = document.createElement("div")
    animCart.classList.add("animtocart")
    body.appendChild(animCart)
    animCart.style.left = `${event.pageX - 25}px`
    animCart.style.top = `${event.pageY - 25}px`

    const rect = this.getCurrentRect()
    const cart = {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX
    }

    const animate = animCart.animate({
      top: `${cart.top + 30}px`,
      left: `${cart.left + 40}px`,
      width: "0px",
      height: "0px"
    }, 800);
    animate.onfinish = function() {
      let object = this.effect.target;
      object.remove();
    }
  }

  hasCartIdCookie() {
    if (!'caches' in window) return
    
    return ('; '+document.cookie).split(`; cart_id=`).pop().split(';').shift().length !== 0
  }
}
