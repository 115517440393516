import { Controller } from '@hotwired/stimulus';
import Splide from "@splidejs/splide/dist/js/splide.min"
import { Modal } from "bootstrap"
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['reviewAuthor', 'reviewDate', 'reviewText', 'splideList', 'modal'];
  
  static values = { 
    url: String
  };

  disconnect() {
    if (this.splide) { this.splide.destroy(); }
    if (this.modal) { this.modal.hide(); }
  }

  openReviwsModal() {
    if (!this.hasUrlValue) return;
  
    this.loadImagesAndReviews();
  }

  async loadImagesAndReviews() {
    try {
      const response = await get(this.urlValue, {
        responseKind: "json"
      });
      if (response.ok) {
        const { reviews } = await response.json;
        this.renderImages(reviews);
        this.initializeSplide();
        this.showModal();
      } else {
        throw new Error(`Failed to load data: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error loading images and reviews:", error);
    }
  }

  // Отрисовка изображений в слайдере
  renderImages(reviews) {
    const slidesHtml = reviews.map(review =>
      `<li class="splide__slide" 
           data-review-id="${review.review_id}" 
           data-review-body="${review.review_body}"
           data-review-author="${review.review_author}"
           data-review-date="${review.review_date}"
           data-review-rating="${review.review_rating}">
        <img src="${review.url}" alt="Review image">
      </li>`
    ).join('');
    this.splideListTarget.innerHTML = slidesHtml;
    this.initializeSplide();
  }

  // Инициализация слайдера Splide
  initializeSplide() {
    if (this.splide) {
      this.splide.destroy(); // Уничтожаем предыдущий слайдер, если он существовал
    }
    this.splide = new Splide(this.splideListTarget.closest('.splide'), {
      type: 'fade',
      perPage: 1,
      pagination: false,
      arrows: this.splideListTarget.children.length > 1
    });

    this.splide.on('moved', () => {
      this.updateReviewText(this.splide.index);
    });

    this.splide.mount();
    this.updateReviewText(0);
  }

  // Обновление текста отзыва на основе активного слайда
  updateReviewText(newIndex) {
    const activeSlide = this.splide.Components.Elements.slides[newIndex];
    const reviewBody = activeSlide.dataset.reviewBody;
    const reviewAuthor = activeSlide.dataset.reviewAuthor;
    const reviewDate = activeSlide.dataset.reviewDate;
    // const reviewRating = activeSlide.dataset.reviewRating;
    this.reviewTextTarget.textContent = this.reviewBody || "Review text not available.";
    const reviewRating = parseInt(activeSlide.dataset.reviewRating, 10);
    
    this.reviewTextTarget.textContent = reviewBody || "Review text not available.";
    this.reviewAuthorTarget.textContent = `${reviewAuthor}  ${this.renderRating(reviewRating)}`;
    // this.reviewAuthorTarget.textContent = `${reviewAuthor}  ⭐${reviewRating}`;

    this.reviewDateTarget.textContent = `${reviewDate}`;
  }

  renderRating(rating) {
    return '⭐'.repeat(rating) + '☆'.repeat(5 - rating);
  }

  showModal() {
    const modalElement = this.modalTarget;
    this.modal = new Modal(modalElement);
    this.modal.show();
  } 
}
