import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { showLoader, hideLoader } from 'controllers/_lib/loader/loaderLibrary';

export default class extends Controller {
  static targets = ['moreMessages']

  static values = {
    url: String,
    page: Number,
    isLoading: { type: Boolean, default: false },
    loadMoreThreshold: { type: Number, default: 50 }
  }

  initialize() {
    this.createLoadingIndicator();
    this.loadMore = this.loadMore.bind(this);
    this.startTopObserver = this.startTopObserver.bind(this);
    setTimeout(() => {
      this.startTopObserver();
    }, 1000);
  }

  startTopObserver() {
    const _this = this;
    const firstMessage = document.querySelector('#messages li.message');
    if (!firstMessage) return;

    this.intersectionObserver = new IntersectionObserver((entries) => {
      const entry = entries[0];

      if (entry.isIntersecting && !_this.isLoadingValue && _this.pageValue != '') {
        _this.showLoadingIndicator();
        _this.loadMore();
      } else {
        _this.hideLoadingIndicator();
      }
    });

    this.intersectionObserver.observe(firstMessage);
  }

  disconnect() {
    if (this.intersectionObserver) this.intersectionObserver.disconnect();
  }

  createLoadingIndicator() {
    const loadingIndicator = document.createElement("div");
    loadingIndicator.className = "loading-indicator";
    loadingIndicator.textContent = "Загружаются старые сообщения...";
    this.loadingIndicator = loadingIndicator;
  }

  showLoadingIndicator() {
    const parent = document.querySelector('#messages li.message').parentNode;
    parent.insertBefore(this.loadingIndicator, parent.firstChild);
  }

  hideLoadingIndicator() {
    if (this.loadingIndicator && this.loadingIndicator.parentNode) {
      this.loadingIndicator.parentNode.removeChild(this.loadingIndicator);
    }
  }

  async loadMore() {
    if (this.isLoadingValue || this.urlValue == '') return

    this.isLoadingValue = true;
    showLoader();
    const page = this.pageValue
    const url = page ? `${this.urlValue}&page=${this.pageValue}` : this.urlValue
    const response = await get(url, { responseKind: 'turbo-stream' })
    if (response.ok) {
      this.hideLoadingIndicator();
      this.isLoadingValue = false;
      hideLoader();
    }
  }

  animateAddedElement(element) {
    element.classList.add("animate", "bg-yellow-gradient");

    setTimeout(function () {
      element.classList.remove("animate", "bg-yellow-gradient");

      setTimeout(function () {
        element.classList.add("animate", "bg-yellow-gradient");
        setTimeout(function () {
          element.classList.remove("animate", "bg-yellow-gradient");
        }, 1000);
      }, 500);
    }, 1000);
  }

  smartScroll(element) {
    const lastId = element.dataset.lastId;
    const lastElement = document.querySelector(`li#message_${lastId}`);
    const parentElement = lastElement.closest('.chat-box.chatContainerScroll');
    if (!parentElement || !lastElement) return;
    
    parentElement.scrollTop = lastElement.offsetHeight * 2 + lastElement.offsetTop - parentElement.offsetHeight;
    
    this.animateAddedElement(element);
  }

  moreMessagesTargetConnected(element) {
    this.disconnect()
    const chatWrapper = document.querySelector('#chat-wrapper');
    const customEvent = new CustomEvent('more-messages:loaded', { detail: { element: element } });
    if (element.dataset.pagyNext === "true") {  
      const page = element.getAttribute('data-pagy-page')
      this.element.setAttribute('data-more-messages-page-value', page)
    } else {
      this.element.setAttribute('data-more-messages-page-value', '')
    }
    
    this.smartScroll(element);

    setTimeout(() => {
      chatWrapper.dispatchEvent(customEvent);
      this.startTopObserver();
    }, 1000);
  }
}
