document.addEventListener('turbo:load', function() {
  document.querySelectorAll(".liqpay_pay").forEach((item) => {
    item.addEventListener("click", liqpay_pay)
  });
  document.querySelectorAll(".liqpay_hold").forEach((item) => {
    item.addEventListener("click", liqpay_hold)
  });
  document.querySelectorAll(".liqpay_new_account").forEach((item) => {
    item.addEventListener("click", liqpay_new_account)
  });
})

function liqpay_pay() {
  ajax({
    url: `/${currentLocale()}/liqpay/pay`,
    type: "GET",
    data: {
      order_id: document.querySelector('.category option:selected').value
    },
    success: function(data) {
      console.log(data);
    }
  });
}

function liqpay_hold() {
  ajax({
    url: `/${currentLocale()}/liqpay/hold`,
    type: "GET",
    data: {
      order_id: document.querySelector('.category option:selected').value
    },
    success: function(data) {
      console.log(data);
    }
  });
}

function liqpay_new_account() {
  ajax({
    url: `/${currentLocale()}/liqpay/new_account`,
    type: "GET",
    data: {
      order_id: document.querySelector('.category option:selected').value
    },
    success: function(data) {
      console.log(data);
    }
  });
}
