const getHeaders = () => {
  let headers = new window.Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  })
  const csrfToken = document.head.querySelector("[name='csrf-token']").content
  if (csrfToken) { headers.append('X-CSRF-Token', csrfToken) }

  return headers
}

async function deleteBlobByIdWithFetch(id) {
  try {
    const response = await fetch(`/active_storage/${id}`, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, data };
    } else {
      return { success: false, error: data };
    }
  } catch (error) {
    return { success: false, error };
  }
}

export { deleteBlobByIdWithFetch };