import { Controller } from '@hotwired/stimulus'
import { Modal } from "bootstrap"
import { post } from '@rails/request.js'
import { interpolate } from 'controllers/_lib/helpers/i18n.helper.js';

export default class extends Controller {
  static targets = ['otpInput', 'otpVerifyButton', 'btnPasteFromClipboard'];
  
  static values = { 
    url: { type: String, default: '/uk/email_activate' },
    otpLength: { type: Number, default: 6 },
    redirectSuccessUrl: { type: String, default: '/profile' },
  };

  connect() {
    this.showModal();
    this.handleBtnPasteFromClipboard();
    this.handleOtpInputValue()
  }

  disconnect() {
    this.modal.hide();
  }

  showModal() {
    const modalElement = document.querySelector('#emailOTPModal');
    this.modal = new Modal(modalElement);
    this.modal.show();
  }

  handleBtnPasteFromClipboard() {
    // Firefox/Opera doesn't support any clipboard paste API
    if (!this.pasteFromClipboardSupported) {
      this.btnPasteFromClipboardTarget.classList.add('d-none')
    }
  }

  clearOtpInputs() {
    this.otpInputTargets.forEach((input) => {
      input.value = ""
      input.setAttribute("disabled", true);
    })
  }

  handleOtpInputValue() {
    this.clearOtpInputs();
    const firstInput = this.otpInputTargets[0];
    firstInput.removeAttribute("disabled")
    firstInput.focus();
  }

  handleBackspace(event, inputs, currentInput, prevInput) {
    if (event.key === "Backspace") {
      const index = Array.from(inputs).indexOf(currentInput);

      if (index > 0 && prevInput) {
        prevInput.focus();
      }

      inputs.forEach((input, index2) => {
        if (index2 > index) {
          input.setAttribute("disabled", true);
          input.value = "";
        }
      });
    }
  }

  moveFocus(input, direction) {
    const targetInput = direction === 'left' ? input.previousElementSibling : input.nextElementSibling;
    if (targetInput && !targetInput.hasAttribute("disabled")) {
      targetInput.focus();
    }
  }

  keyUpOtpInput(event) {
    event.preventDefault();
    const inputs = this.otpInputTargets;
    const currentInput = event.currentTarget;
    const nextInput = currentInput.nextElementSibling;
    const prevInput = currentInput.previousElementSibling;

    this.handleBackspace(event, inputs, currentInput, prevInput)

    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      this.moveFocus(currentInput, event.key === "ArrowLeft" ? 'left' : 'right');
    } else {
      if (currentInput.value.length > 1 || this.hasNonDigitCharacters(currentInput.value)) {
        currentInput.value = "";
        return; // prevent input value with more than one character or non-digit characters
      }
    }

    if (nextInput && nextInput.hasAttribute("disabled") && currentInput.value !== "") {
      nextInput.removeAttribute("disabled");
      nextInput.focus();
    }

    if (!inputs[inputs.length - 1].disabled && inputs[inputs.length - 1].value !== "") {
      this.verifyOtpCode();
    }
  }

  setOtpInputs(pastedValue, inputs) {
    pastedValue = this.removeSpaces(pastedValue);

    if (this.isInvalidLength(pastedValue)) {
      this.showLengthError();
      this.handleOtpInputValue();
      return;
    }

    if (this.hasNonDigitCharacters(pastedValue)) {
      this.showNonDigitError();
      this.handleOtpInputValue();
      return;
    }

    this.fillOtpInputs(pastedValue, inputs);
  }

  removeSpaces(value) {
    return value.replace(/\s/g, '');
  }

  isInvalidLength(value) {
    return value.length > this.otpLengthValue;
  }
  
  hasNonDigitCharacters(value) {
    return !(/^\d+$/.test(value));
  }

  showLengthError() {
    const message = interpolate(I18n.users.session.email_activate.validates.errors.length, { length: this.otpLengthValue });
    alert(message);
  }

  showNonDigitError() {
    alert(I18n.users.session.email_activate.validates.errors.only_digit);
  }

  fillOtpInputs(pastedValue, inputs) {
    for (let i = 0; i < this.otpLengthValue; i++) {
      if (i < pastedValue.length) {
        inputs[i].value = pastedValue[i];
        inputs[i].removeAttribute("disabled");
        inputs[i].focus();
      } else {
        inputs[i].value = "";
        inputs[i].focus();
      }
    }
  }

  pasteFromClipboard(event) {
    event.preventDefault();

    this.setOtpInputs(this.clipboardContent, this.otpInputTargets)
  }

  pasteFromClipboardByBtn(event) {
    event.preventDefault();

    if (navigator && navigator.clipboard && navigator.clipboard.readText) {
      navigator.clipboard.readText()
        .then((pastedValue) => {
          this.setOtpInputs(pastedValue, this.otpInputTargets);
        })
        .catch((error) => {
          console.error('Failed to read from clipboard using modern API', error);
          this.pasteFromClipboardFallback();
        });
    } else {
      this.pasteFromClipboardFallback();
    }
  }

  pasteFromClipboardFallback() {
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);

    textarea.focus();
    const sucessfull = document.execCommand('paste');

    if (!sucessfull) {
      console.error('Copy form clipboard is not supported in this browser!');
      return
    } 

    const pastedValue = textarea.value;
    document.body.removeChild(textarea);
    this.setOtpInputs(pastedValue, this.otpInputTargets);
  }

  async verifyOtpCode() {
    const otpValue = this.otpValue;
    console.log('otpValue: ', otpValue);

    try {
      const response = await post(this.urlValue, {
        body: JSON.stringify({ otp: otpValue }),
        responseKind: "json"
      })

      if (response.ok) {
        const responseData = await response.json

        if (responseData.status == 'success') {
          this.modal.hide();
          toaster("Success", "Your email is verified!", responseData.message);
          window.location.href = this.redirectSuccessUrlValue;
        } else if (responseData.status == 'error') {
          toaster("Validate error", "Check your code!", responseData.message);
          console.error('responseData: ', responseData);
          this.handleOtpInputValue()
        }
      }
    } catch (error) {
      console.error('Error verifying OTP: ', error);
    }
  }

  get otpValue() {
    return this.otpInputTargets.map((input) => input.value).join("");
  }

  get clipboardContent() {
    return (event.clipboardData || window.clipboardData).getData('text');
  }

  get pasteFromClipboardSupported() {
    const navigatorSupported = navigator && navigator.clipboard && navigator.clipboard.readText;
    const documentSupported = document.queryCommandSupported && document.queryCommandSupported('paste');
    return navigatorSupported && documentSupported;
  }
}
