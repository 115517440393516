import { Controller } from '@hotwired/stimulus'
import { post } from "@rails/request.js"
// import currentUserId from "../_lib/user/currentUserId"
export default class extends Controller {

  static targets = [
    "email", 
    "phone", 
    "emailMessage", 
    "phoneMessage", 
    "submit", 
    "errorMessage",
    'password',
    'passwordConfirmation',
    'passwordError',
    'passwordConfirmationError'
  ]

  static values = { url: String }

  letterRegex = /[a-zA-Z]/;

  async validate(event) {
    // Не перевряємо додавання нових атрибутів для зарєстрованого користувача? (у мене є 2 аккаунти: один з поштою, інший з телефоном)
    // if (currentUserId()) return

    // Get the input field name and value
    const name = event.target.name.split("[")[1].split("]")[0];
    const value = event.target.value
    const url = this.urlValue

    try {
      const response = await post(url, { 
        body: JSON.stringify({
          user: {
            [name]: value
          },
          locale: currentLocale()
        }),
        responseKind: "json" 
      })

      if (response.ok) {
        const responseData = await response.json
        // If the value is valid, mark the input field as valid and enable the submit button
        if (responseData.valid) {
          event.target.classList.remove("is-invalid")
          event.target.classList.add("is-valid")
          this[`${name}MessageTarget`].textContent = ""
          this.errorMessageTarget.innerHTML = ''
          this.errorMessageTarget.classList.remove("alert", "alert-danger", "p-3")
          this.errorMessageTarget.classList.add("d-none")
          this.submitTarget.disabled = false
        } else {
          // If the value is invalid, mark the input field as invalid, display the error message, and disable the submit button
          event.target.classList.remove("is-valid")
          event.target.classList.add("is-invalid")
          this[`${name}MessageTarget`].textContent = responseData.message
          this.submitTarget.disabled = true

          // Display the error message block with a red background
          this.errorMessageTarget.classList.remove("d-none")
          this.errorMessageTarget.classList.add("alert", "alert-danger", "p-3")
          const prompt = I18n.users.registration.errors.already_exists.promt_sign_up
          const alertMessage = I18n.users.registration.errors.already_exists.alert
          const alertLinkMessage = `${alertMessage}<a href="${responseData.redirect_path}">${prompt}</a>`
          this.errorMessageTarget.innerHTML = alertLinkMessage
        }
      } else {
        throw new Error(`${response.status} - ${response.statusText}`)
      }
    } catch (error) {
      toaster("error", I18n.flash.error, error.message)
    }
  }

  submiAction(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const password = this.hasPasswordTarget && this.passwordTarget.value
    const passwordConfirmation = this.hasPasswordConfirmationTarget && this.passwordConfirmationTarget.value
    
    this.clearErrors()

    if (!password || !passwordConfirmation) {
      alert(I18n.users.registration.errors.fill_form)
      return false
    }

    if (password.length < 8 || !this.letterRegex.test(password)) {
      this.passwordErrorTarget.innerText = I18n.users.registration.errors.password.invalid
      this.passwordTarget.classList.add("is-invalid")
      return false
    }

    if (passwordConfirmation.length < 8 || !this.letterRegex.test(passwordConfirmation)) {
      this.passwordConfirmationErrorTarget.innerText = I18n.users.registration.errors.password.invalid
      this.passwordConfirmationTarget.classList.add("is-invalid")
      return false
    }

    if (password !== passwordConfirmation) {
      this.passwordConfirmationErrorTarget.innerText = I18n.users.registration.errors.password.not_match
      this.passwordConfirmationTarget.classList.add("is-invalid")
      return false
    }

    const form = event.target.closest('form')
    const turboFrame = event.target.closest('turbo-frame#new_order_form')
    turboFrame.setAttribute('target', '_top')

    form.submit() // Turbo.visit(form.action)
  }

  

  clearErrors() {
    this.passwordErrorTarget.innerText = ""
    this.passwordConfirmationErrorTarget.innerText = ""
    this.passwordTarget.classList.remove("is-invalid")
    this.passwordConfirmationTarget.classList.remove("is-invalid")
  }
}