import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class extends Controller {
  
  static targets = [ 'searchMakerInput', 'label']
  
  connect() {
    this.debounceInput = debounce(this.debounceInput, 300)
  }

  reset() {
    this.searchMakerInputTarget.value = ''
    Array.from(this.labelTargets).forEach((element) => {
      if (element.closest('div').style.display === "none") element.closest('div').style.display = "block"
    })
  }

  debounceInput(event) {
    event.preventDefault()
    event.stopPropagation()
    if (event.key === "Escape") { 
      this.reset()
      return
    }

    const search = this.searchMakerInputTarget //document.querySelector('#searchMakerInput')
    const labels = this.labelTargets           //document.querySelectorAll(".checkbox-makers")
    Array.from(labels).forEach((element) => {
      if (!element.innerText.toLowerCase().includes(search.value))
        element.closest('div').style.display = "none"
      else
        element.closest('div').style.display = "block"
    })
  }
}
