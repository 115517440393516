import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 
    'moreChannelInput',
    'moreUserChannelInput',
    'moreUserChannelList', 
    'moreChannelList' ]
  
  connect() {  
    this.element.addEventListener('hidden.bs.modal', (event) => {
      const modalInput = event.target.id === 'moreChannel' ? this.moreChannelInputTarget : this.moreUserChannelInputTarget
      const channels = event.target.id === 'moreChannel' ? this.moreChannelListTarget.children : this.moreUserChannelListTarget.children
      if (modalInput) {
        modalInput.value = ''
        Array.from(channels).forEach(channel => channel.classList.remove('d-none'))
      }
    })
  }

  showHdeChannels(channels, query) {
    channels.forEach(channel => {
      const name = channel.querySelector('.channel-name').textContent.trim().toLowerCase()
      if (name.includes(query)) {
        channel.classList.remove('d-none')
      } else {
        channel.classList.add('d-none')
      }
    })
  }
   
  moreChannelFilter(event) {
    const query = event.target.value.toLowerCase()
    const channels = Array.from(this.moreChannelListTarget.children)
    
    this.showHdeChannels(channels, query)
  }

  moreUserChannelFilter(event) {
    const query = event.target.value.toLowerCase()
    const channels = Array.from(this.moreUserChannelListTarget.children)

    this.showHdeChannels(channels, query)
  }
}