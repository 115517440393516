document.addEventListener("turbo:load", () => {
  // TODO: Коли переходиш по посиланню, то воно зливається із коннетном (на мобільній версії)
  initPushSubscription()
  // Після завантаження сторінки перевіряємо Bootstrap breakpoint і видаляємо одну із 2 форм швидкого пошуку,
  // яка не відповідає версії сайта (мобільна/десктопна). Визначення версій у search.scss
  // TODO eventListener on width change and move Dom element
  if (window.getComputedStyle(document.body, ':before').content.replace(/"/g, '') == 'mobile') {
    if (!!document.querySelector('.desktop-partial')) {
      document.querySelector('.desktop-partial').remove()
    }
  } else if (!!document.querySelector('.mobile-partial')) {
    document.querySelector('.mobile-partial').remove()
  }
});

async function initPushSubscription() {
  if (!('Notification' in window)) return Promise.reject(false) // or Promise.resolve(false) or return

  if (Notification.permission !== 'denied') {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') return
  }

  const rowVapidPublicKey = document.querySelector("[name='vapid-public-key']").content
  if (!rowVapidPublicKey) {
    console.warn('[Error] Vapid public key not present!')
    return
  }

  const vapidPublicKey = new Uint8Array(JSON.parse(rowVapidPublicKey))

  if (navigator.serviceWorker) {
    try {
      await navigator.serviceWorker.register('/service-worker.js')

      // console.log('Service Worker for web push notifications successfully registered!');

      const serviceWorkerRegistration = await navigator.serviceWorker.ready;
      if (serviceWorkerRegistration.pushManager) {
        const sub = await serviceWorkerRegistration.pushManager.subscribe(
          {
            userVisibleOnly: true,
            applicationServerKey: vapidPublicKey
          }
        )

        const data = await fetch('/webpush_subscriptions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(sub),
        })

        console.log(await data.json())

      } else {
        console.log("[Safari] pushManager not available via web standard")
      }
    } catch (e) {
      console.error(e.toString())
    }
  }
}

window.addEventListener('load', () => {
  if (!navigator.serviceWorker) return false

  navigator.serviceWorker.register('/service-worker.js').then(registration => {

    let serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
    } else if (registration.active) {
      serviceWorker = registration.active;
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});


document.addEventListener("turbo:load", () => {
  let searchMobile = document.querySelector('#search'); // Пошук по мобільному версії
  let btnSearchMobile = document.querySelector('#btnSearchMobile'); // Кнопка пошуку по мобільному версії
  let searchBlockMobile = document.querySelector('#searchBlockMobile'); // Блок пошуку по мобільному версії
  
  if (searchMobile && btnSearchMobile) {
    btnSearchMobile.addEventListener('click', (e) => {
      e.preventDefault();
      btnSearchMobile.classList.toggle('active');
      searchBlockMobile.classList.toggle('active');
      searchMobile.focus();
    } );

    document.addEventListener('click', function(e) {
      if (!e.target.closest('.searchBlock')) {
        btnSearchMobile.classList.remove('active');
        searchBlockMobile.classList.remove('active');
      }
    });
    
  }
});