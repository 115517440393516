import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'
import currentUserId from "./_lib/user/currentUserId.js"
import { addToRecentPages, clearRecentPages, clearRecentPage } from './_lib/recent/recentServicesPages.js'

function supportsIntersectionObserver() {
  return (
    "IntersectionObserver" in window ||
    "IntersectionObserverEntry" in window ||
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  );
}

export default class extends Controller {

  static targets = [ 'spliderItems', 'listRecent', 'frame' ]

  connect() {
    document.addEventListener("turbo:before-fetch-request", function (event) {
      if (event.target.id.startsWith('recently_services_service_') && !currentUserId()) {
        const currentShowPageId = event.target.dataset.serviceId
        const searchParams = event.detail.url.searchParams
        searchParams.append("current_show_page_id", currentShowPageId)
        addToRecentPages(currentShowPageId, searchParams) // якщо guest - додаємо recent у LocalStrorage
      }
      
      if (!event.target.id == 'modal') event.target.closest('.showbox').dataset.requested = 'true'
      event.detail.resume()   
    })
    this.startObservingRecentlyLoaded();
  }

  disconnect() {
    this.stopObservingRecentlyLoaded();
  }

  stopObservingRecentlyLoaded() {
    if (this.intersectionObserver) this.intersectionObserver.disconnect();
  }

  startObservingRecentlyLoaded() {
    if (!supportsIntersectionObserver()) {
      console.warn(`This browser doesn't support IntersectionObserver`)
      return
    }

    this.intersectionObserver = new IntersectionObserver(
      this.updateListRecently.bind(this),
      {
        threshold: 0.75, // otherwise, the right-most column sometimes won't be considered visible in some browsers, rounding errors, etc.
      }
    )
    document.querySelectorAll(".frame_service").forEach((listEl) => {
      this.intersectionObserver.observe(listEl)
    })
  }

  updateListRecently(){
    if (this.element.closest('.showbox').dataset.requested === 'false') {
      this.element.src = this.element.dataset.url
    } 
  }

  async clearAllRecents(event) {
    event.preventDefault()
    event.stopPropagation()
    const url = new URL(event.target.href)
    const searchParams = url.searchParams 
    this.element.innerHTML = '' // clear all cards or this.listRecentTarget.innerHTML = ''

    if (!currentUserId()) {
      clearRecentPages()
      return
    }
    searchParams.append("clear_visit_pages", "server")
    await patch(url, {
      responseKind: 'turbo-stream'
    })
  }

  async clearOneRecent(event) {
    event.preventDefault()
    event.stopPropagation()
    const currentShowPageId = event.target.closest('turbo-frame').dataset.serviceId
    const targetElement     = event.target.closest('a')
    const serviceCard       = targetElement?.closest('.promote-card')
    const deletedId         = targetElement.dataset.clearId
    const url               = new URL (targetElement.href)
    
    serviceCard.remove()

    if(!currentUserId()) { 
      clearRecentPage(deletedId, targetElement)
      return
    }

    const searchParams = url.searchParams
    searchParams.append("current_show_page_id", currentShowPageId)
    searchParams.append("clear_visit_pages", "server")
    searchParams.append("deleted_id", deletedId)
    await patch(url, {
      responseKind: 'turbo-stream'
    })
  }
}
