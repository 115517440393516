import { Controller } from "@hotwired/stimulus";
import { get, patch } from '@rails/request.js';
import { Offcanvas } from "bootstrap";
import currentUserId from '../../_lib/user/currentUserId.js';
export default class extends Controller {

  static targets = [
    'message',
    'chatList',
    'chatForm',
    'preloader',
    'chatMessages',
    'showChatList',
    'showChatIndex',
    'appendedMessage',
    'chatListContent',
    'chatIndexContent',
    'statusChatSelect',
    'btnChatIndexList',
    'chatCurrentContent',
    'chatContainerScroll'
  ]

  static values = {
    url: String,
    urlIndex: String,
    objectId: String,
    objectType: String
  }

  chatListElements = [
    'chatForm',
    'btnChatIndexList',
    'showChatList',
    'chatCurrentContent',
    'chatListContent'
  ];

  chatIndexElements = [
    'chatIndexContent',
    'showChatIndex'
  ];

  connect() {
  }

  handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        const readed = entry.target.getAttribute(`data-readed-by-user-${currentUserId()}`)
        if (readed === 'false') {
          this.markMessageAsRead(entry.target.dataset.readedUrl); // entry.target.classList.contains('my_block') 
          this.changeIconAsRead(entry.target);
        }
      }
    });
  }

  markMessageAsRead(url) {
    patch(url, { responseKind: 'turbo-stream' })
  }

  changeIconAsRead(element) {
    const icon = element.querySelector('#read-icon');
    if (icon && icon.classList.contains('icon-unread')) {
      icon.classList.replace('icon-unread', 'icon-read');
    }
  }

  showPreloader() {
    if (this.hasPreloaderTarget && this.preloaderTarget.classList.contains('d-none')) {
      this.preloaderTarget.classList.remove('d-none');
    }
  }

  hidePreloader() {
    if (this.hasPreloaderTarget && !this.preloaderTarget.classList.contains('d-none')) {
      this.preloaderTarget.classList.add('d-none');
    }
  }

  toggleClassIfHidden(hasTarget, target) {
    if (hasTarget && target.classList.contains('d-none')) {
      target.classList.remove('d-none');
    }
  }

  toggleClassIfVisible(hasTarget, target) {
    if (hasTarget && !target.classList.contains('d-none')) {
      target.classList.add('d-none');
    }
  }

  prepareChatList() {
    this.chatListElements.forEach(targetName => {
      const hasTarget = this.getHasElement(targetName)
      const target = this[`${targetName}Target`];
      this.toggleClassIfHidden(hasTarget, target);
    });
    this.chatIndexElements.forEach(targetName => {
      const hasTarget = this.getHasElement(targetName)
      const target = this[`${targetName}Target`];
      this.toggleClassIfVisible(hasTarget, target);
    });
  }

  prepareChatIndex() {
    this.chatListElements.forEach(targetName => {
      const hasTarget = this.getHasElement(targetName)
      const target = this[`${targetName}Target`];
      this.toggleClassIfVisible(hasTarget, target);
    });
    this.chatIndexElements.forEach(targetName => {
      const hasTarget = this.getHasElement(targetName)
      const target = this[`${targetName}Target`];
      this.toggleClassIfHidden(hasTarget, target);
    });
  }

  getHasElement(targetName) {
    return this[`has${(targetName.charAt(0).toUpperCase() + targetName.slice(1))}Target`];
  }

  // показуємо Offcanvas, формуємо список усіх чатів користувача з власником об'єкту, на якому знаходимся, а також чату по цьому об'єкту
  async showOffcanvas(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.hasChatListTarget) return

    this.chatlist = new Offcanvas(this.chatListTarget);
    this.chatlist.show();
    this.prepareChatList()
    this.showPreloader()
    this.getChatList();
  }

  async getChatList() {
    const response = await get(this.urlValue, {
      query: {
        object_type: this.objectTypeValue,
        object_id: this.objectIdValue
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.hidePreloader();
    }
  }

  // клік на іконці назад для відображення усіх чатів користувача сгрупованих по оппонентам
  async showChatIndex(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.hasChatListTarget) return
    
    this.prepareChatIndex()
    this.showPreloader()
    this.getChatIndexList();
  }

  async getChatIndexList() {
    const response = await get(this.urlIndexValue, {
      query: {
        object_type: this.objectTypeValue,
        object_id: this.objectIdValue
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.hidePreloader();
    }
  }

  // міняємо статус чату, відповідно список чатів буде змінено
  async changeChatListStatus(event) {
    event.preventDefault();
    if (!this.hasStatusChatSelectTarget) return;

    this.showPreloader()
    const selectedValue = this.statusChatSelectTarget.value;
    const response = await get(this.urlIndexValue, {
      query: {
        status: selectedValue,
        object_type: this.objectTypeValue,
        object_id: this.objectIdValue
      },
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.hidePreloader();
    }
  }

  async openUserChatList(event) {
    event.preventDefault();
    event.stopPropagation();
    const url = new URL(event.currentTarget.href);
    const profileUserId = this.element.dataset.chatListOriginalUserId
    if (this.element.dataset.chatListObjectTypeValue === '' && profileUserId) {
      url.searchParams.set('profile_user_id', profileUserId)
    }

    if (!url) return;

    this.prepareChatList()
    this.showPreloader()
    const response = await get(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.hidePreloader();
    }
  }

  scrollToLastMessage() {
    if (!this.hasChatContainerScrollTarget) return

    this.chatContainerScrollTarget.scrollIntoView({
      behavior: 'smooth',
      block: "end"
    })
    this.chatContainerScrollTarget.style.position = 'relative'
  }

  addMessageObserver() {
    if (this.messageTargets.length === 0 ) return
    
    this.observer = new IntersectionObserver(this.handleIntersection, { threshold: 0.5 });

    this.messageTargets.forEach((message) => {
      this.observer.observe(message);
    });
  }

  addUnreadHeader() {
    const unreadedMessages = document.querySelectorAll(`[data-readed-by-user-${currentUserId()} ="false"]`); 
    if (unreadedMessages.length > 0) {
      const firstUnreadMessage = unreadedMessages[0];
      const unreadHeader = document.createElement("h5");
      unreadHeader.classList.add('text-center', 'w-100', 'bg-yellow-gradient', 'mt-3', 'mb-2', 'py-2', 'fs-[16px]', 'text-secondary');
      unreadHeader.textContent = I18n.chats.index.unreaded_messages;
      firstUnreadMessage.insertAdjacentElement("beforebegin", unreadHeader);
    }
  }

  chatMessagesTargetConnected() {
    this.scrollToLastMessage();
    this.addMessageObserver();
    this.addUnreadHeader();
  }

  appendedMessageTargetConnected() {
    this.scrollToLastMessage();
  }

  disconnect() {
    if (this.observer) this.observer.disconnect();
  }
}