import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  
  static targets = [ 'applyButton', 
                     'resetButton', 
                     'minimumPrice',
                     'maximumPrice',
                     'makers',
                     'states'
                   ]
  
  static values = {
    stateParams: String,
    priceMinParams: String,
    priceMaxParams: String,
  }
  
  connect() {
    this.btnActivate()
  }

  checkPriceWithParams() {
    // Рівність початку і кінця діапазону свідчить про те що товар або один, або не знайдений. У такому випадку 
    // буде розбіжність між price params і value price range, проте це не свідчить що змінилися дані в формі  
    const equalityMinMax = this.maximumPriceTarget.value === this.minimumPriceTarget.value
    
    // Перевіряєм чи співпадають значення параметрів params і обраного діапазону. Враховуєм чи є рівність, як виключення  
    if (this.hasPriceMinParamsValue && this.hasPriceMaxParamsValue) {
      const changedMin     = this.priceMinParamsValue !== this.minimumPriceTarget.value && !equalityMinMax
      const changedMax     = this.priceMaxParamsValue !== this.maximumPriceTarget.value && !equalityMinMax
      return changedMin || changedMax
    } else if (this.hasPriceMinParamsValue) {
      return this.priceMinParamsValue !== this.minimumPriceTarget.value && !equalityMinMax
    } else if (this.hasPriceMaxParamsValue) {
      return this.priceMaxParamsValue !== this.maximumPriceTarget.value && !equalityMinMax
    }
  }

  checkPriceStatic() {
    // Рівність початку і кінця діапазону свідчить про те що товар або один, або не знайдений. У такому випадку 
    // хоча і буде розбіжність, проте це не свідчить що є зміни в формі  
    const equalityMinMax = this.maximumPriceTarget.value === this.minimumPriceTarget.value
    
    // Перевіряєм чи співпадають значення параметрів values з крайніми значеннями обраного діапазону. 
    // Одночасно враховуєм чи є рівність, як виключення  
    const changedMin = this.minimumPriceTarget.value !== this.minimumPriceTarget.min && !equalityMinMax
    const changedMax = this.maximumPriceTarget.value !== this.maximumPriceTarget.max && !equalityMinMax
    return changedMin || changedMax
  }

  get makerCheckboxes() {
    return new Array(...this.makersTargets)
  }

  get selectedMakersCheckboxes() {
    return this.makerCheckboxes.filter((c) => c.checked);
  }
  // TODO: double
  get stateCheckboxes() {
    return new Array(...this.statesTargets)
  }

  get selectedStatesCheckboxes() {
    return this.stateCheckboxes.filter((c) => c.checked);
  }


  filetrsValuesPresent() {
    const stateChanged     = this.selectedStatesCheckboxes
    const makersChecked    = this.selectedMakersCheckboxes
    const paramsMinPresent = this.hasPriceMinParamsValue && this.priceMinParamsValue !== ''
    const paramsMaxPresent = this.hasPriceMaxParamsValue && this.priceMaxParamsValue !== ''
    const priceChanged     = (paramsMinPresent || paramsMaxPresent) ? this.checkPriceWithParams() : this.checkPriceStatic()
    
    return stateChanged || priceChanged || makersChecked.length !== 0
  }


  buttonApplyActivate() {
    if (this.filetrsValuesPresent()) {
      this.applyButtonTarget.removeAttribute('disabled')
    } else {
      this.applyButtonTarget.setAttribute('disabled', 'disabled')
    }
  }

  buttonResetActivate() {
    // Поведінка кнопок застосувати і скинути дещо різниться. Тому, в окремих випадках, 
    // "скинути" буде активною навіть якщо "застосувати" неактивна.
    // Для прикладу, товар шукається у діапазоні ціни. Результат пошуку з діапазоном цін. 
    // Користувач нічого не змінив ("застосувати" неактивна), але він повинен мати можливість скинути цей діапазон
    const changedState   = this.selectedStatesCheckboxes
    const makersChecked  = this.selectedMakersCheckboxes
    const changedMin     = this.minimumPriceTarget.value !== this.minimumPriceTarget.min
    const changedMax     = this.maximumPriceTarget.value !== this.maximumPriceTarget.max
    const equalityMinMax = this.maximumPriceTarget.value === this.minimumPriceTarget.value
    
    if (this.filetrsValuesPresent() || changedState || makersChecked || changedMin || changedMax || equalityMinMax) {
      this.resetButtonTarget.removeAttribute('disabled')
    } else {
      this.resetButtonTarget.setAttribute('disabled', 'disabled')
    }
  }

  btnActivate() {
    if (this.hasApplyButtonTarget) this.buttonApplyActivate()
    if (this.hasResetButtonTarget) this.buttonResetActivate()
  }
}
