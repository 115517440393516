import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'button' ]
  
  static values = {
    remainTime: Number,
    title: String,
    lockTitle: String,
  }
  static classes = [ 'inactive' ]

  connect() {
    if (!this.hasRemainTimeValue || !this.remainTimeValue > 0) return 
 
   this.startCountDown(); 
  }

  startCountDown() {
    this.updateCountdown();
    this.intervalId = setInterval(() => this.updateCountdown(), 1000);
  }

  updateCountdown() {
    if (this.remainTimeValue <= 0) {
      this.handleUnlock();
    } else if (this.remainTimeValue <= 60) {
      this.updateButton(`${this.lockTitleValue} ${this.remainTimeValue}${I18n.time.sec} `, true, true);
      this.decrementTime();
    } else {
      this.updateButton(this.lockTitleValue, true, true);
      this.decrementTime();
    }
  }

  decrementTime() {
    this.remainTimeValue -= 1;
  }
  
  handleUnlock() {
    this.updateButton(this.titleValue, false, false);
    clearInterval(this.intervalId);
  }

  updateTitle(text) {
    if (this.buttonTarget.textContent) this.buttonTarget.textContent = text;
    if (this.buttonTarget.value) this.buttonTarget.value = text;
  }

  updateClasses(addClasses) {
    if (addClasses) {
      this.buttonTarget.classList.add(...this.inactiveClasses)
    } else {
      this.buttonTarget.classList.remove(...this.inactiveClasses)
    }
  }

  updateButton(text, disabled, addClasses) {
    this.updateTitle(text)
    this.updateClasses(addClasses);
    this.buttonTarget.disabled = disabled;
  }

  get titleTarget() {
    if (this._buttonTarget) return this._buttonTarget;
    
    if (this.buttonTarget.textContent) {
      this._buttonTarget = this.buttonTarget
    } else if (this.buttonTarget.value) {
      this._buttonTarget = this.buttonTarget
    }

    return this._buttonTarget;
  }

  disconnect() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
