import { Controller } from "@hotwired/stimulus"

const ITEM_HEIGHT = 140

export default class extends Controller {
  pageData      = document.querySelector('#page-notificatons-link')
  parentElement = document.querySelector('#notifications_list')
  connect() {
    this.element[ (str => { return str
                        .split('--')
                        .slice(-1)[0]
                        .split(/[-_]/)
                        .map(w => w.replace(/./, m => m.toUpperCase()))
                        .join('')
                        .replace(/^\w/, c => c.toLowerCase())
                    }
                  )(this.identifier)
                ] = this 
    this.smartScroll()
  }
    
    
  smartScroll() {
    const notifySection = document.querySelector('#notifications')
    if (!notifySection || !notifySection.classList.contains('show')) return

    // для першого інтервалу пагінації примусовий scroll не потрібен 
    if (!this.pageData || +this.pageData.dataset.page <= 1) return 
    // знаходимо перший елемент доданого інтервалу пагінації, обраховуємо відстані між ним і батьком, скролим 
    const targetElement = document.querySelector(`#notification_${this.pageData.dataset.firstIdItem}`) 
    this.parentElement.scrollTop = targetElement.offsetTop - this.parentElement.offsetTop - ITEM_HEIGHT 
    // - ITEM_HEIGHT -> це якщо є бажання бачити останній елемент попереднього інтервалу пагінації, його можно видалити,
    // у такому випадку список буде починатися з першого елементу ново  го інтервалу пагінації  
  }
}