import { FILTER } from './constants.js'
const forDeletion = ['', 'uk', 'ru']

function set_full_path(goResetUrl, seoParams) {
  if (seoParams.length == 0) return goResetUrl

  return goResetUrl + '/' + seoParams.join('/')
}

function createValidPath() {
  let customUrl = window.location.pathname.split('/')
  customUrl = customUrl.filter(item => !forDeletion.includes(item))
  if (customUrl[0] === 'search') return createRootUrlWithLocale('search')

  const url = customUrl.slice(0, 2).join('/')
  return createRootUrlWithLocale(url)
}

function hrefRafctoring(goToUrl) {
  return goToUrl.split('/')
    .filter(item => item != 'sort_news')
    .filter(item => item != 'maker_none')
    .filter(item => !item.startsWith('maker_'))
    .filter(item => !(/category_\d+((_or_\d+)+)?/).test(item))
    .map(item => item.startsWith('category-slug_') ? item.replace(/category-slug/i, 'category') : item)
    .map(item => item.startsWith('maker-slug_') ? item.replace(/maker-slug/i, 'maker') : item)
    .map(item => item === "type_product" ? item.replace('type_product', 'products') : item)
    .map(item => item === "type_service" ? item.replace('type_service', 'services') : item)
    .join('/')
}

function paramsMakerIdsAppend(makers, seoParams) {
  let makerIdsArray = []
  if (makers.length > 1) {
    makers.forEach((_, idx) => {
      makerIdsArray.push(makers[idx])
    })
  } else {
    makerIdsArray.push(makers[0])
  }
  makerIdsArray = makerIdsArray.length > 1 ? makerIdsArray.join('_or_') : makerIdsArray
  seoParams.push(FILTER.makerIds + makerIdsArray)
}

function paramsMakerSlugsAppend(makers, seoParams) {
  let makerSlugsArray = []
  if (makers.length > 1) {
    makers.forEach((_, idx) => {
      makerSlugsArray.push(makers[idx])
    })
  } else {
    makerSlugsArray.push(makers[0])
  }
  makerSlugsArray = makerSlugsArray.length > 1 ? makerSlugsArray.join('_or_') : makerSlugsArray
  seoParams.push(FILTER.makerSlug + makerSlugsArray)
}

export { createValidPath, set_full_path, hrefRafctoring, paramsMakerIdsAppend, paramsMakerSlugsAppend }