import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  
  static targets = ['arrow'];
  toolbar = null

  connect() {
    this.toolbar = this.element.querySelector('trix-toolbar')
    this.toolbar.classList.add('hided')
    // this.toolbar.classList.remove('show')
  }

  toggle() {
    if (!this.toolbar) return 

    if (this.toolbar.classList.contains('hided')) {
      this.toolbar.classList.remove('hided')
      this.toolbar.classList.add('show')
      this.arrowTarget.classList.add('rotate-180')
    } else {
      this.toolbar.classList.add('hided')
      this.toolbar.classList.remove('show')
      this.arrowTarget.classList.remove('rotate-180')
    }
    // this.toolbarTarget.classList.toggle('show');
  }
}