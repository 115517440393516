import { PRODUCTS_VISIT_PAGES_NAME, SERVICES_VISIT_PAGES_NAME } from './constants.js' // import * as constants from './constants'

// get and parse local visit Products pages (recent for guest)
function getLocalRecent(localName) {
  try {
    return JSON.parse(localStorage.getItem(localName)) || []
  } catch (e) {
    console.err(e)
    return []
  }
}

// get local Products visit pages (recent for guest)
function getRecentPages(searchParams) {
  paramsProductsAppend(getLocalRecent(PRODUCTS_VISIT_PAGES_NAME), searchParams)
  paramsServicesAppend(getLocalRecent(SERVICES_VISIT_PAGES_NAME), searchParams)
  return searchParams
}

function paramsProductsAppend(localRecentPages, searchParams) {
  if (localRecentPages.length > 1) {
    localRecentPages.forEach((_, idx) => {
      searchParams.append('guest_visit_products_pages[]', localRecentPages[idx])
    })
  } else {
    searchParams.append('guest_visit_products_pages[]', localRecentPages)
  }
  return searchParams
}

function paramsServicesAppend(localRecentPages, searchParams) {
  if (localRecentPages.length > 1) {
    localRecentPages.forEach((_, idx) => {
      searchParams.append('guest_visit_services_pages[]', localRecentPages[idx])
    })
  } else {
    searchParams.append('guest_visit_services_pages[]', localRecentPages)
  }
  return searchParams
}

function clearRecentPage(value, deleteType) {
  const recentData = getLocalRecent(deleteType)
  const indexValue = recentData.indexOf(value)
  if (indexValue == -1) return

  recentData.splice(indexValue, 1)
  localStorage.setItem(deleteType, JSON.stringify(recentData))
}
  
export { getRecentPages, clearRecentPage }