import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
// Connects to data-controller="search-form"
export default class extends Controller {
  static targets = ['searchInput']

  static values = {
    url: String
  }

  search(event) {
    event.preventDefault()
    event.stopPropagation()
    
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      // this.element.requestSubmit() // not full turbo request
      const goUrl = this.urlValue.trim()
      const searchQuery = this.searchInputTarget.value.trim()
      get(goUrl, { query: { q: searchQuery }, responseKind: 'turbo-stream' })
    }, 300)
  }

  reset(event) {
    event.preventDefault()
    event.stopPropagation()
    
    this.searchInputTarget.value = ''
    const goUrl = this.urlValue.trim()
    get(goUrl, { responseKind: 'turbo-stream' })
  }
}