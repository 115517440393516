import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import fadeIn from "./_lib/animate/fadeIn.js"
import fadeOut from "./_lib/animate/fadeOut.js"
import debounce from 'lodash/debounce'
import currentUserId from "./_lib/user/currentUserId.js"
import { getHistory, addToHistory } from './_lib/history/addToHistory.js'
import { FILTER, SEARCH } from './_lib/constants.js'
import { set_full_path, hrefRafctoring } from './_lib/parseUrl.js'

export default class extends Controller {
  static targets = ['searchInput',
                    'searchChatInput',
                    'clearChatSearchBtn',
                    'inputFilters',
                    'categoryLink',
                    'resetButton',
                    'label',
                    'form'
                   ]

  static values = {
    url: String,
    urlFilters: String,
    history: String,
    searchParams: String,
    typeSearch: String,
    categoryId: String,
    complexParams: Object,
    request: { type: Boolean, default: false }
  }

  loader = document.querySelector("#turbo-loader-container")
  loaderBackdrop = document.querySelector("#turbo-loader-backdrop")
  userListHistory = document.querySelector('#search_advanced_results')
  inputSearchForm = document.querySelector("#search")
  inputCancel = document.querySelector('.input-cancel')

  connect() {
    document.querySelector('#advanced-search-form')?.addEventListener('submit', event => event.preventDefault())
    document.querySelector('#filters')?.addEventListener('submit', event => event.preventDefault())
    this.searchInChats = debounce(this.searchInChats, 400)
  }

  prepareBeforeResponce() {
    this.requestValue = true
    this.loader.classList.remove('d-none')
    this.loaderBackdrop.classList.remove('d-none')
  }

  clearAfterResponce() {
    this.requestValue = false
    this.loader.classList.add('d-none')
    this.loaderBackdrop.classList.add('d-none')
  }

  addSearchParams(complexFilters) {
    let seoParams = []
    let goUrl = new URL(createRootUrlWithLocale('search'))
    const searchQuery = complexFilters.search || document.querySelector('#search').value.trim()
    if (complexFilters.type) seoParams.push(FILTER.type + complexFilters.type)
    if (searchQuery) seoParams.push(FILTER.search + searchQuery)
    if (complexFilters.category_id) seoParams.push(FILTER.category + complexFilters.category_id)
    if (complexFilters.category_slug) seoParams.push(FILTER.categorySlug + complexFilters.category_slug)
    if (complexFilters.state) seoParams.push(FILTER.state + complexFilters.state)
    if (complexFilters.minimum_price) seoParams.push(FILTER.minPrice + complexFilters.minimum_price)
    if (complexFilters.maximum_price) seoParams.push(FILTER.maxPrice + complexFilters.maximum_price)
    if (complexFilters.sort_name) seoParams.push(FILTER.sort + complexFilters.sort_name)
    goUrl = set_full_path(goUrl, seoParams)
    return goUrl
  }
  
  // parse type from selected tabs
  getTypeFromTabs(class_name) {
    const [link] = Array.from(document.querySelectorAll(class_name)).filter(el => el.classList.contains('active'))
    return link.dataset.tabType
  }

  // main search for advanced search
  async searchWithEnter(event) {
    if (event.key !== 'Enter' || this.element.id !== 'advanced-search-form' ) return

    event.stopPropagation()
    event.preventDefault()
    const searchesValue = this.hasSearchInputTarget && this.searchInputTarget.value || ''
    if (searchesValue.length <= 0 || searchesValue.length > 50) return
  
    if (this.requestValue) return
    if (!currentUserId()) addToHistory(searchesValue)

    this.prepareBeforeResponce()
    const url = new URL(createRootUrlWithLocale('search'))
    const searchParams = url.searchParams
    searchParams.delete(SEARCH.search)
    searchParams.append(SEARCH.search, searchesValue)
    searchParams.delete(SEARCH.type)
    searchParams.append(SEARCH.type, this.getTypeFromTabs('.tabs-link'))

    const response = await get(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.inputSearchForm.value = searchesValue
      this.inputCancel.classList.add('active')
      this.userListHistory.innerHTML = ''
      this.clearAfterResponce()
      history.replaceState(history.state, "", url.href)
    }
  }

  resetChatSearch(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.hasSearchChatInputTarget) {
      this.searchChatInputTarget.value = ''
      // this.handleFilter() // -> save current tab (not need)
      this.formTarget.requestSubmit();
    }
  }

  searchInChats(event) {
    event.preventDefault()
    event.stopPropagation()

    const searchQuery = event.target.value.trim()
    if (!searchQuery || searchQuery.length === 0 || searchQuery.length > 50) return // invalid search query

    this.handleFilter() // add filter to form
    this.formTarget.requestSubmit();
  }

  handleFilter() {
    const form = this.formTarget;
    const newFilter = this.getTypeFromTabs('.btn-chat-type');
    if (newFilter && newFilter !== 'all') {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "filter";
      input.value = newFilter;
      form.appendChild(input);
    }
  }

  handlerCloseBtn() {
    if (!this.hasClearChatSearchBtnTarget) return;

    if (this.hasSearchChatInputTarget && !this.searchChatInputTarget.value.length == 0) {
      this.clearChatSearchBtnTarget.classList.remove('d-none')
    } else if (!this.clearChatSearchBtnTarget.classList.contains('d-none')) {
      this.clearChatSearchBtnTarget.classList.add('d-none')
    }
  }

  // Перехід в обрану категорію (Categories list in advanced search)
  async jumpToCategory(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return
    
    this.prepareBeforeResponce()
    const complexFilters = JSON.parse(event.target.dataset.searchComplexParamsValue)
    if (complexFilters) delete(complexFilters.page)
    if (complexFilters.filters?.state) delete(complexFilters.filters.state)
    if (complexFilters.filters?.minimum_price) delete(complexFilters.filters.minimum_price)
    if (complexFilters.filters?.maximum_price) delete(complexFilters.filters.maximum_price)
    let goUrl = this.addSearchParams(complexFilters)
    const response = await get(goUrl, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      goUrl = hrefRafctoring(goUrl)
      history.replaceState(history.state, "", goUrl)
    }
  }
  
  // Show more categories in categories list (hided if count > 4)
  showMoreCategories(event) {
    event.preventDefault()
    event.stopPropagation()
    const btnShowMore = event.target
    const fragment = document.createDocumentFragment()
    fragment.appendChild(btnShowMore.parentElement.parentElement)
    const listCategories = document.querySelectorAll(`.root_${btnShowMore.dataset.rootid}_hided`)
    const lastHidedListEl = listCategories[listCategories.length - 1]
    const firstHidedListEl = listCategories[0]
    const moreText = btnShowMore.dataset.moretext
    const lessText = btnShowMore.dataset.lesstext
    listCategories.forEach(el => {
      if (el.classList.contains('hided')) {
        fadeIn(el, 500, 'hided')
      } else {
        fadeOut(el, 100, 'hided')
      }
    })

    if (btnShowMore && btnShowMore.innerText === lessText) {
      btnShowMore.innerText = moreText
      firstHidedListEl.parentElement.insertBefore(fragment, firstHidedListEl)
    } else {
      btnShowMore.innerText = lessText
      if (+btnShowMore.dataset.deeptree > 2) {
        lastHidedListEl.appendChild(fragment)
      } else {
        lastHidedListEl.parentElement.appendChild(fragment)
      }
    }
  }


  searchChatInputTargetConnected(element) {
    this.handlerCloseBtn()
  }

  // TODO: move this
  userHistory = async (event) => {
    const inputValue = event.target.value.trim()
    if (!inputValue) {
      const urlUserHistory = event.target.dataset.searchHistoryValue
      await this.setHistory(currentUserId(), urlUserHistory)
    }
  }
  // TODO: move this
  setHistory = async (currentUserId, urlUserHistory) => {
    if (currentUserId) {
      await this.getUserHistory(urlUserHistory)  // for authenticate user
    } else {
      const localHistory = getHistory()     // for guest
      await this.getUserLocalHistory(urlUserHistory, localHistory)
    }
  }
  // TODO: move this
  getUserHistory = async (url) => {
    await get(url.toString(), {
      query: {
        advanced_search: true
      },
      responseKind: 'turbo-stream'
    })
  }
  // TODO: move this
  getUserLocalHistory = async (url, localHistory) => {
    await get(url.toString(), {
      query: {
        advanced_search: true,
        local_history: localHistory
      },
      responseKind: 'turbo-stream'
    })
  }

  // Autosearch from User history (after click on recent item)
  async searchWithHistoryParams(event) { 
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const searchValue = event.target.innerText.trim()

    const url = new URL(createRootUrlWithLocale('search'))
    const searchParams = url.searchParams
    searchParams.delete(SEARCH.search)
    searchParams.append(SEARCH.search, searchValue)
    searchParams.delete(SEARCH.type)
    searchParams.append(SEARCH.type, this.getTypeFromTabs('.tabs-link'))
    const response = await get(url.toString(), {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.inputSearchForm.value = searchValue
      this.inputCancel.classList.add('active')
      this.userListHistory.innerHTML = ''
      this.clearAfterResponce()
      history.replaceState(history.state, "", url.href)
    }
  }
  
  // Пошук у разі "Показати усі результати"  
  async searchAllresults(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.requestValue) return

    this.prepareBeforeResponce()
    const searchValue = this.inputSearchForm.value.trim()

    const url = new URL(createRootUrlWithLocale('search'))
    const searchParams = url.searchParams
    searchParams.delete(SEARCH.search)
    searchParams.append(SEARCH.search, searchValue)
    searchParams.delete(SEARCH.type)
    searchParams.append(SEARCH.type, this.getTypeFromTabs('.tabs-link'))
    const response = await get(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.inputSearchForm.value = searchValue
      this.inputCancel.classList.add('active')
      this.userListHistory.innerHTML = ''
      this.clearAfterResponce()
      history.replaceState(history.state, "", url.href)
    }
  }
}
