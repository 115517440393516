import { Controller } from '@hotwired/stimulus'
import Splide  from "@splidejs/splide/dist/js/splide.min"

export default class extends Controller {

  connect() {
    new Splide(`#${this.element.id}`, {
      autoWidth: true,
      perPage: 1,
    }).mount();
  }
}
