import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  
  static targets = ['btnCollapse']
  
  static values = {
    paramsGenderPresent: String,
  }

  connect() {
    if (!this.hasBtnCollapseTarget) return 

    if (this.hasParamsGenderPresentValue && this.paramsGenderPresentValue.trim() !== '') {
      bootstrap.Collapse.getOrCreateInstance(this.btnCollapseTarget, { toggle: true })
    } else {
      bootstrap.Collapse.getOrCreateInstance(this.btnCollapseTarget, { toggle: false })
    }
  }
}
