import { Controller } from '@hotwired/stimulus'
import { Modal } from "bootstrap"
import TomSelect from "tom-select"

export default class extends Controller {

  static targets = ['channelSelect']

  connect() {
    this.modal = new Modal(this.element)
    this.modal.show()
    
    this.element.addEventListener('hidden.bs.modal', (event) => {
      const modal_frame = document.querySelector('#post_modal')
      if (modal_frame) modal_frame.src = '' // інакше фрейм фактично залишається активним!
      
      // goto posts tab in Profile url
      if (location.pathname.endsWith('profile')) {
        Turbo.visit(window.location.pathname.toString().concat('#posts'), { action: 'replace' })
      } 
    })
  }

  channelSelectTargetConnected() {
    new TomSelect(this.channelSelectTarget, {})
  }

  disconnect() {
    this.modal.hide()
  }
}