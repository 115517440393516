import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap"
import formatFileSize from '../../_lib/helpers/formatFileSize';

export default class extends Controller {

  static targets = [
    'fileInput',
    'submitBtn',
    'fileCountInfo',
    'imagePreviewModal',
    'previewModalContainer'
  ]

  static values = {
    maxFileSize: { type: Number, default: 10 * 1024 * 1024 } // 20 * 1024 * 1024 -> 20 MB
  }

  connect() {}

  preview(event) {
    event.preventDefault()
    if (!this.hasFileInputTarget) return;
    
    this.modal = Modal.getOrCreateInstance(this.imagePreviewModalTarget);
    const dt = new DataTransfer();
    const { files } = this.fileInputTarget;
    const previewModalContainer = this.previewModalContainerTarget;

    previewModalContainer.innerHTML = '';
    if (this.hasFileCountInfoTarget) this.fileCountInfoTarget.innerText = '';

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const parentImageElement = document.createElement('div');
      parentImageElement.classList.add('file-container', 'position-relative', 'col-lg-6', 'col-md-12', 'mb-4', 'mb-lg-0', 'cursor-pointer');
      const fileType = file.type.split('/')[0];
      const preview = this.generateDomElement(fileType);
      const thumbnail = preview.cloneNode(true)
      preview.classList.add('preview-chat-file');
      parentImageElement.appendChild(preview);
      
      const reader = new FileReader();

      reader.onload = function (event) {
        preview.src = event.target.result;
        if (fileType == 'image') thumbnail.src = event.target.result;
      };

      reader.readAsDataURL(file);
      this.addFileNameElement(parentImageElement, file.name);
      previewModalContainer.appendChild(parentImageElement);

      if (file.size > this.maxFileSizeValue) {
        this.bluredElement(preview, parentImageElement)
      } else {
        this.appendThumbnail(thumbnail, file.name, fileType);
        dt.items.add(file);
      }      
    }
    
    this.updateFilePreviwClass(files.length)
    this.fileInputTarget.files = dt.files;
    this.modal.show();
    this.updateFileCountInfo(dt.files);
    this.performSubmitBtn(dt.files);
  }

  addFileNameElement(parentImageElement, fileName) {
    const fileNameElement = document.createElement('div');
    fileNameElement.classList.add('preview-file-name', 'position-absolute', 'start-0', 'top-0', 'ps-4', 'pe-2', 'text-truncate', 'fw-light', 'text-kidium');
    fileNameElement.textContent = fileName;
    parentImageElement.appendChild(fileNameElement);
  }     

  updateFilePreviwClass(length) {
    if (length % 2 === 0) return;

    const lastFilePreview = this.previewModalContainerTarget.lastElementChild;
    lastFilePreview.classList.remove('col-lg-6');
    lastFilePreview.classList.add('col-lg-12');
    
  }

  bluredElement(preview, parentImageElement) {
    preview.classList.add('blured');
    const overlay = document.createElement('div');
    overlay.classList.add('overlay', 'position-absolute', 'start-0', 'translate-middle-y');
    overlay.textContent = `Розмір файлу занадто великий. Максимум ${formatFileSize(this.maxFileSizeValue)}`;
    parentImageElement.appendChild(overlay);
  }

  appendThumbnail(t, fileName, fileType) {
    if (!this.hasFileCountInfoTarget) return;

    if (fileType == 'audio') t.controls = false
    t.classList.add('preview-thumbnail');
    const thumbnailWrapper = document.createElement('div');
    const fileNameElement = document.createElement('div');
    const progressBar = document.createElement("div");
    fileNameElement.classList.add('thumbnail-filename', 'text-truncate', 'fw-light', 'text-secondary', 'fs-[12px]');
    thumbnailWrapper.classList.add('preview-thumbnail-wrapper', 'd-flex', 'gap-2', 'align-items-center', 'px-3', 'rounded-md', 'shadow', 'ps-2', 'position-relative');
    thumbnailWrapper.setAttribute('data-name', fileName);
    progressBar.classList.add('chat-progress-bar', 'progress-bar-striped', 'progress-bar-animated', 'bg-kidium', 'mw-100', 'h-100', 'rounded-md', 'text-truncate');
    progressBar.setAttribute('role', 'progressbar');
    progressBar.setAttribute('data-name', fileName);
    progressBar.textContent = fileName;
    fileNameElement.textContent = fileName;
    thumbnailWrapper.appendChild(fileNameElement);
    thumbnailWrapper.appendChild(t);
    thumbnailWrapper.appendChild(progressBar);
    this.fileCountInfoTarget.appendChild(thumbnailWrapper);    
  }

  updateFileCountInfo(files) {
    if (this.hasFileCountInfoTarget && files.length > 0) {
      this.fileCountInfoTarget.classList.remove('d-none');
      const fileCount = document.createElement('span');
      fileCount.classList.add('bg-kidium', 'text-white', 'rounded-pill', 'px-3', 'py-1', 'me-2', 'select-files-count');
      fileCount.textContent = 'Обрано файлів:  ' + files.length;
      this.fileCountInfoTarget.insertBefore(fileCount, this.fileCountInfoTarget.firstChild);
    } else {
      this.fileCountInfoTarget.classList.add('d-none');
      this.fileCountInfoTarget.innerText = '';
    }
  }

  performSubmitBtn(files) {
    if (this.hasSubmitBtnTarget && this.submitBtnTarget.classList.contains('d-none') && files.length > 0) {
      this.submitBtnTarget.classList.remove('d-none');
    } 
  }

  generateDomElement(fileType) {
    switch (fileType) {
      case 'image':
        const imagePreview = document.createElement('img');
        imagePreview.classList.add('img-fluid');
        return imagePreview;
      case 'video':
        const videoPreview = document.createElement('video');
        videoPreview.controls = true;
        return videoPreview;
      case 'audio':
        const audioPreview = document.createElement('audio');
        audioPreview.controls = true;
        return audioPreview;
      default:
        const filePreview = document.createElement('div');
        return filePreview;
    }
  }

  continue(event) {
    event.preventDefault();
    this.modal.hide();
    const customEvent = new CustomEvent('attachments:added');
    this.fileInputTarget.dispatchEvent(customEvent);
  }

  closeModal(event) {
    event.preventDefault();
    this.modal.hide();
    this.fileInputTarget.files = null;
    this.updateFileCountInfo([]);
  }
}
