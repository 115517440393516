import { Controller } from '@hotwired/stimulus'
import Splide  from "@splidejs/splide/dist/js/splide.min"

export default class extends Controller {

  connect() {
    const spliderCartRecents = new Splide(`#${this.element.id}`, {
      autoWidth: true,
      perPage: 4,
      breakpoints: {
        420: {
          perPage: 1,
        },
        820: {
          perPage: 2,
        },
        1024: {
          perPage: 3,
        }  
      },
    }).mount();
    window.spliderCartRecents = spliderCartRecents
  }
}