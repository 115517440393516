import { Controller } from '@hotwired/stimulus'
import { Modal } from "bootstrap"

export default class extends Controller {
  
  connect() {
    this.modal = new Modal(this.element)
    this.modal.show()
    
    this.element.addEventListener('hidden.bs.modal', (event) => {
      const modal_frame = document.querySelector('#modal')
      if (modal_frame) modal_frame.src = '' // інакше фрейм фактично залишається активним!  
    })
  }

  disconnect() {
    this.modal.hide()
  }
}