import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'onlineTypeSelect',
    'offerAgreement',
    'citySelect',
    'submitBtn'
  ]
  
  static values = {
    url: String,
    turboFrame: { type: String, default: 'modal' }
  }

  connect() {
    const checked = this.offerAgreementTarget.checked
    this.performSubmitBtn(checked)
  }

  disconnect() {
    clearTimeout(this.flashTimeout);
  }

  onlineTypeSelect() {
    const onlineSelected = this.onlineTypeSelectTarget.value === "false"
    const citySelectWrapper = this.citySelectTarget.closest('.form-group.service_request_city_id')
    citySelectWrapper.classList.toggle('d-none', onlineSelected)

    if (onlineSelected) {
      this.citySelectTarget.removeAttribute("required")
    } else {
      this.citySelectTarget.setAttribute("required", "")
    }
  }

  performSubmitBtn(checked) {
    this.submitBtnTarget.classList.toggle('disabled-with-click', !checked)
  }

  toggleSubmitBtn(event) {
    const checked = event.target.checked
    this.performSubmitBtn(checked)
  }

  flashCheckbox() {
    this.offerAgreementTarget.classList.add('blinking-checkbox', 'non-backdrop')
    if (this.formContent) this.formContent.classList.add('form-backdrop')

    this.flashTimeout = setTimeout(() => {
      this.offerAgreementTarget.classList.remove('blinking-checkbox', 'non-backdrop')
      if (this.formContent) this.formContent.classList.remove('form-backdrop')
    }, 3000); 
  }

  submitOrAnimate(event) {
    if (this.submitBtnTarget.classList.contains('disabled-with-click')) {
      event.preventDefault();
      this.flashCheckbox();
    }
  }

  get formContent() {
    if (!this._formContent) {
      this._formContent = this.element.closest('.modal-content')
    }
    
    return this._formContent
  }
}